<template>
  <div class="emr-edukasipasienkeluarga mb-2">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-edukasipasienkeluarga">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Edukasi Pasien & Keluarga</span>
          </div>
          <div class="custom-scroller p-3" :style="{ height: heightPercent }">
            <b-row class="mr-0 align-items-center">
              <b-col md="5">
                <label class="edukasipasienkeluarga-fc-black">Kesediaan pasien / keluarga menerima informasi :</label>
              </b-col>
              <b-col md="7">
                <b-form-group class="bg-input-edukasi">
                  <div class="d-flex flex-row align-items-center">
                    <div><b-form-radio-group
                      :options="options.informasi"
                      v-model="selected.informasi"
                    ></b-form-radio-group></div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 align-items-center">
              <b-col md="5">
                <label class="edukasipasienkeluarga-fc-black">Terdapat hambatan dalam edukasi :</label>
              </b-col>
              <b-col md="7">
                <b-form-group class="bg-input-edukasi">
                  <div class="d-flex flex-row align-items-center">
                    <div><b-form-radio-group
                      :options="options.hambatan"
                      @change="onChangeHambatan"
                      v-model="selected.hambatan"
                    ></b-form-radio-group></div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="selected.hambatan === 1">
              <b-col class="mt-2" md="12">
                <label class="edukasipasienkeluarga-fc-black">Pilihan Hambatan :</label>
              </b-col>
              <b-col md="12">
                <b-form-group class="bg-input-edukasi">
                  <div class="d-flex flex-row align-items-center">
                    <b-row>
                      <b-col md="12">
                        <div><b-form-checkbox-group
                          :options="options.pilihanhambatan" class="bg-input-hambatan"
                        ></b-form-checkbox-group></div>
                      </b-col>
                      <b-col class="mt-2" md="12">
                        <label class="edukasipasienkeluarga-fc-black">Lain-lain :</label>
                      </b-col>
                      <b-col md="12">
                        <b-form-textarea
                          rows="3"
                          no-resize
                          size="sm"
                          class="bg-white"
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 align-items-center">
              <b-col md="5">
                <label class="edukasipasienkeluarga-fc-black">Dibutuhkan penerjemah :</label>
              </b-col>
              <b-col md="7">
                <b-form-group class="bg-input-edukasi">
                  <div class="d-flex flex-row align-items-center">
                    <div><b-form-radio-group
                      :options="options.penerjemah"
                      v-model="selected.penerjemah"
                      @change="onChangePenerjemah"
                    ></b-form-radio-group></div>
                    <div><b-form-input :class="['w-15 edukasipasienkeluarga-form-input', penerjemah.inputColor]"
                      placeholder="Jika ya, sebutkan ..."
                      :readonly="penerjemah.input"
                      v-model="penerjemah.inputVal"></b-form-input>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 align-items-center">
              <b-col md="12">
                <label class="edukasipasienkeluarga-fc-black">Kebutuhan Edukasi :</label>
              </b-col>
              <b-col md="12">
                <b-form-group class="bg-input-edukasi">
                  <div class="d-flex flex-row align-items-center">
                    <div><b-form-checkbox-group
                      :options="options.edukasi"
                    ></b-form-checkbox-group></div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 align-items-center">
              <b-col md="12">
                <label class="edukasipasienkeluarga-fc-black">Lain-lain :</label>
              </b-col>
              <b-col cols="12">
                <b-form-textarea
                  cols="3"
                  no-resize
                ></b-form-textarea>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i>
              &nbsp; Catatan Medis</span>
            </span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover"><i class="fas fa-arrow-left" v-b-tooltip.hover.topright="'Buka Riwayat'"></i></span> &nbsp; Lihat
            </span>
          </div>
          <div class="custom-scroller p-2" :style="{ height: heightPercent }">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat />
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'EdukasiPasienKeluarga',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      penerjemah: {
        input: true,
        inputColor: 'bg-secondary',
        inputVal: ''
      },
      selected: {
        informasi: 0,
        hambatan: 0,
        penerjemah: 0
      },
      options: {
        informasi: [
          { text: 'Tidak', value: 0 },
          { text: 'Ya', value: 1 }
        ],
        hambatan: [
          { text: 'Tidak', value: 0 },
          { text: 'Ya', value: 1 }
        ],
        penerjemah: [
          { text: 'Tidak', value: 0 },
          { text: 'Ya', value: 1 }
        ],
        kirimsatusehat: [
          { text: 'Tidak', value: 0 },
          { text: 'Ya', value: 1 }
        ],
        pilihanhambatan: [
          { text: 'Pendengaran', value: 1 },
          { text: 'Pengelihatan', value: 2 },
          { text: 'Kognitif', value: 3 },
          { text: 'Fisik', value: 4 },
          { text: 'Budaya', value: 5 },
          { text: 'Emosi', value: 6 },
          { text: 'Bahasa', value: 7 }
        ],
        edukasi: [
          { text: 'Diagnosa Penyakit', value: 1 },
          { text: 'Obat-obatan', value: 2 },
          { text: 'Diet & Nutrisi', value: 3 },
          { text: 'Rehabilitas Medik', value: 4 },
          { text: 'Manajemen Nyeri', value: 5 },
          { text: 'Penggunaan Alat Medis', value: 6 },
          { text: 'Hak & Kewajiban Pasien', value: 7 },
          { text: 'Fisiotertapi', value: 8 },
          { text: 'Okupasi Terapi', value: 9 },
          { text: 'Terapi Wicara', value: 10 },
          { text: 'Psikologi', value: 11 },
          { text: 'Refraksionis Optisen', value: 12 },
          { text: 'Gigi & Mulut', value: 13 },
          { text: 'Ortotik Prostetik', value: 14 }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    },
    onChangeHambatan (values) {
      if (values === 1) {
        this.selected.hambatan = 1
      } else {
        this.selected.hambatan = 0
      }
    },
    onChangePenerjemah (values) {
      if (values === 1) {
        this.penerjemah.input = false
        this.penerjemah.inputColor = 'bg-white'
      } else {
        this.penerjemah.input = true
        this.penerjemah.inputColor = 'bg-secondary'
      }
    }
  }
}
</script>
<style>
.emr-edukasipasienkeluarga .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-edukasipasienkeluarga .radius-custom-emr {
  border-radius: .5rem;
}
.emr-edukasipasienkeluarga .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-edukasipasienkeluarga .radius-custom-form-head-edukasipasienkeluarga {
  border-top-right-radius: .5rem;
}
.emr-edukasipasienkeluarga .edukasipasienkeluarga-fc-black {
  color: black;
  font-weight: bold;
}
.emr-edukasipasienkeluarga .edukasipasienkeluarga-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-edukasipasienkeluarga .bg-input-edukasi {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
}
.emr-edukasipasienkeluarga .bg-input-hambatan {
  background-color: rgb(240, 255, 155);
  padding: 5px;
  border-radius: 5px;
}
.emr-edukasipasienkeluarga .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-edukasipasienkeluarga .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
