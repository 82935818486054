<template>
  <div>
    <b-card end>
      <b-tabs pills end>
        <b-tab title="Edukasi Pasien & Keluarga"><EdukasiPasienKeluargaForm /></b-tab>
        <b-tab title="Edukasi Emergency"><EdukasiEmergencyForm /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { xray } from '../../../../../../../config/pluginInit'
import EdukasiEmergencyForm from '../EdukasiEmergency/EdukasiEmergencyForm.vue'
import EdukasiPasienKeluargaForm from '../EdukasiPasienKeluarga/EdukasiPasienKeluargaForm.vue'

export default {
  name: 'Tab',
  components: {
    EdukasiEmergencyForm,
    EdukasiPasienKeluargaForm
  },
  mounted () {
    xray.index()
  }
}
</script>
