<template>
  <div>
    <b-card end>
      <b-tabs pills end>
        <b-tab title="Tanda Vital"><TandaVital class="mb-2" /></b-tab>
        <b-tab title="Nutrisi"><Nutrisi class="mb-2" /></b-tab>
        <b-tab title="Fungsional"><Fungsional class="mb-2" /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { xray } from '../../../../../../../config/pluginInit'
import TandaVital from '../../../../RekamMedis/Pemeriksaan/Umum/TandaVital/TandaVitalForm.vue'
import Nutrisi from '../../../../RekamMedis/Pemeriksaan/Umum/Nutrisi/NutrisiForm.vue'
import Fungsional from '../../../../RekamMedis/Pemeriksaan/Umum/Fungsional/FungsionalForm.vue'

export default {
  name: 'Tab',
  components: {
    TandaVital,
    Nutrisi,
    Fungsional
  },
  mounted () {
    xray.index()
  }
}
</script>
