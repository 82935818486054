<template>
  <div class="emr-ekg">
    <b-row class="justify-content-between m-2">
      <b-col cols="12" :md="formLeft" class="pl-1 pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-ekg">
            <b-row>
              <b-col md="11"><span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Catatan EKG Pasien</span></b-col>
              <b-col md="1" class="text-end"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buat EKG Baru'"><i class="fas fa-plus"></i></span></b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <b-row class="mt-2 align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Dokter :</label>
              </b-col>
              <b-col md="10">
                <b-form-group class="ekg-form-input">
                  <multiselect :options="options.dokter" v-model="selected.dokter" label="nama"></multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2 align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Irama Dasar :</label>
              </b-col>
              <b-col md="10">
                <b-form-checkbox-group>
                  <div class="d-flex flex-row align-items-center">
                    <b-form-checkbox value=1>Sinus</b-form-checkbox>
                    <b-form-checkbox value=2>Atrial Fibrilasi</b-form-checkbox>
                    <b-form-checkbox value=3 @change="onHeartRate">Heart Rate</b-form-checkbox>
                    <div><b-form-input class="w-15 ekg-form-input" :readonly=iramadasar.openHeartRate v-model="iramadasar.heartRateInput"></b-form-input></div>
                    <div class="ml-3"><b-form-checkbox value=4 @change="onLainIramaDasar">Lain-lain</b-form-checkbox></div>
                    <div><b-form-input class="w-15 ekg-form-input" :readonly="iramadasar.lain" v-model="iramadasar.lainInput"></b-form-input></div>
                  </div>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
            <b-row class="mt-3 align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Aksis :</label>
              </b-col>
              <b-col md="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center">
                      <div><b-form-checkbox-group
                        :options="options.aksis"
                        @change="onChangeAksis"
                        ></b-form-checkbox-group>
                      </div>
                      <div><b-form-input class="w-15 ekg-form-input" :readonly="aksis.lain" v-model="aksis.lainInput"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Gelombang P :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center">
                      <div><b-form-checkbox-group
                        :options="options.gelP"
                        @change="onChangeGelP"
                        ></b-form-checkbox-group>
                      </div>
                      <div><b-form-input class="w-15 ekg-form-input" :readonly="gelP.lain" v-model="gelP.lainInput"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Interval PR :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center">
                      <div class="w-15 mr-3"><b-form-input class="w-15 ekg-form-input" placeholder="mesc"></b-form-input></div>
                      <div><b-form-checkbox-group
                        :options="options.intervalPR"
                        ></b-form-checkbox-group>
                      </div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Konduksi A-V :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center">
                      <div><b-form-checkbox-group
                        :options="options.konduksiAV"
                        @change="onChangeKonduksiAV"
                        ></b-form-checkbox-group>
                      </div>
                      <div><b-form-input class="w-15 ekg-form-input" :readonly="konduksiAV.lain" v-model="konduksiAV.lainInput"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Gelombang QRS :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center">
                      <div><b-form-checkbox-group
                        :options="options.gelQRS"
                        @change="onChangeGelQRS"
                        ></b-form-checkbox-group>
                      </div>
                      <div><b-form-input class="w-15 ekg-form-input" :readonly="gelQRS.lain" v-model="gelQRS.lainInput"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mr-0">
              <b-col md="12" lg="12">
                <div>
                  <b-form-group class="bg-bigeminy">
                    <b-form-checkbox-group>
                      <div class="d-flex flex-row align-items-center">
                        <div><h5><b-badge variant="primary">Apakah PVC ? </b-badge></h5></div>
                        <div><b-form-checkbox class="ml-3" value=1 @change="onChangePVC">Ya</b-form-checkbox></div>
                        <div><h5><b-badge :variant="pvc.bdcolor"><i class="fas fa-exclamation-triangle"></i> isi salah satu</b-badge></h5></div>
                        <b-form-radio-group class="ml-3" :options="options.pvc" v-model="selected.pvc" :disabled="pvc.disabled" @change="onLainPVC"></b-form-radio-group>
                        <div><b-form-input :class="['w-15 ekg-form-input', pvc.lainInputColor]" :readonly="pvc.lain" v-model="pvc.lainInput"></b-form-input></div>
                      </div>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row class="mr-0">
              <b-col md="12" lg="12">
                <div>
                  <b-form-group class="bg-bigeminy">
                    <b-form-checkbox-group>
                      <div class="d-flex flex-row align-items-center">
                        <div> <h5><b-badge variant="primary">Apakah PAC ? </b-badge></h5></div>
                        <div><b-form-checkbox class="ml-3" value=1 @change="onChangePAC">Ya</b-form-checkbox></div>
                        <div><h5><b-badge :variant="pac.bdcolor"><i class="fas fa-exclamation-triangle"></i> isi salah satu</b-badge></h5></div>
                        <b-form-radio-group class="ml-3" :options="options.pac" v-model="selected.pac" :disabled="pac.disabled" @change="onLainPAC"></b-form-radio-group>
                        <div><b-form-input :class="['w-15 ekg-form-input', pac.lainInputColor]" :readonly="pac.lain" v-model="pac.lainInput"></b-form-input></div>
                      </div>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row class="align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Gelombang Q :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center">
                      <div><b-form-checkbox-group
                          :options="options.gelQ"
                          @change="onChangeGelQ"
                        ></b-form-checkbox-group></div>
                      <div><b-form-input class="w-15 ekg-form-input" :readonly="gelQ.lain" v-model="gelQ.lainInput"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Interval QT :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center mb-2">
                      <div><b-form-checkbox-group
                        :options="options.qt"
                        @change="onChangeQT"
                      ></b-form-checkbox-group></div>
                      <div><b-form-input class="w-15 ekg-form-input" :readonly="qt.lain" v-model="qt.lainInput"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center">
                      <div><b-form-checkbox-group
                        :options="options.qtc"
                        @change="onChangeQTC"
                      ></b-form-checkbox-group></div>
                      <div><b-form-input class="w-15 ekg-form-input" :readonly="qtc.lain" v-model="qtc.lainInput"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Segmen ST :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center">
                      <div><b-form-checkbox-group
                        :options="options.segmenST"
                        @change="onChangeSegmenST"
                        ></b-form-checkbox-group>
                      </div>
                      <div><b-form-input class="w-15 ekg-form-input" :readonly="segmenST.lain" v-model="segmenST.lainInput"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Gelombang T :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center">
                      <div><b-form-checkbox-group
                        :options="options.gelT"
                        @change="onChangeGelT"
                        ></b-form-checkbox-group>
                      </div>
                      <div><b-form-input class="w-15 ekg-form-input" :readonly="gelT.lain" v-model="gelT.lainInput"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Gelombang U :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-group>
                  <b-form-checkbox-group>
                    <div class="d-flex flex-row align-items-center mb-2">
                      <div><b-form-input class="w-15 ekg-form-input"></b-form-input></div>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-2 mr-0">
              <b-col md="2">
                <label class="ekg-fc-black">Kesimpulan :</label>
              </b-col>
              <b-col md="10" lg="10">
                <b-form-textarea
                max-rows="3"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formMiddle" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span></span> &nbsp; <span class="h6"><i class="far fa-edit"></i> &nbsp;  Catatan Medis Kunjungan Terkini</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '3'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> &nbsp; <span class="h6"><i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '3'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'
import Multiselect from 'vue-multiselect'
import Services from '../../../../../services/fatmahost/master/index'

export default {
  name: 'EKG',
  components: {
    Riwayat,
    Multiselect
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '7',
      formMiddle: '4',
      formRight: '1',
      true: true,
      iramadasar: {
        heartRateInput: '',
        openHeartRate: true,
        lain: true,
        lainInput: ''
      },
      aksis: {
        lain: true,
        lainInput: ''
      },
      gelP: {
        lain: true,
        lainInput: ''
      },
      konduksiAV: {
        lain: true,
        lainInput: ''
      },
      gelQRS: {
        lain: true,
        lainInput: ''
      },
      selected: {
        pvc: [],
        pac: [],
        dokter: []
      },
      gelT: {
        lain: true,
        lainInput: ''
      },
      options: {
        dokter: [],
        aksis: [
          { text: 'Normal', value: 1 },
          { text: 'LAD', value: 2 },
          { text: 'RAD', value: 3 },
          { text: 'Lain-lain', value: 4 }
        ],
        gelP: [
          { text: 'Normal', value: 1 },
          { text: 'LAE', value: 2 },
          { text: 'RAE', value: 3 },
          { text: 'Lain-lain', value: 4 }
        ],
        intervalPR: [
          { text: 'Normal', value: 1 },
          { text: 'PR Depresi', value: 2 },
          { text: 'Undermined', value: 3 }
        ],
        konduksiAV: [
          { text: 'Normal', value: 1 },
          { text: 'AV Disosiasi', value: 2 },
          { text: 'AV Block', value: 3 }
        ],
        gelQRS: [
          { text: 'Normal', value: 1 },
          { text: 'LBBB', value: 2 },
          { text: 'RBBB', value: 3 },
          { text: 'LVH', value: 4 },
          { text: 'RVH', value: 5 },
          { text: 'Lain-lain', value: 6 }
        ],
        pvc: [
          { text: 'Bigeminy PVC', value: 1 },
          { text: 'Lain-lain', value: 2 }
        ],
        pac: [
          { text: 'Bigeminy PAC', value: 1 },
          { text: 'Lain-lain', value: 2 }
        ],
        gelQ: [
          { text: 'Normal', value: 1 },
          { text: 'Q Patologis', value: 2 },
          { text: 'Lead', value: 3 }
        ],
        qt: [{ text: 'QT', value: 1 }],
        qtc: [{ text: 'QTc', value: 1 }],
        segmenST: [
          { text: 'Normal', value: 1 },
          { text: 'ST Elevasi', value: 2 },
          { text: 'ST Depresi', value: 3 },
          { text: 'Lead', value: 4 }
        ],
        gelT: [
          { text: 'Normal', value: 1 },
          { text: 'T Inverted', value: 2 },
          { text: 'T Biphasic', value: 3 },
          { text: 'T Flat', value: 4 },
          { text: 'Lead', value: 5 }
        ]
      },
      pvc: {
        bdcolor: 'secondary',
        disabled: true,
        lain: true,
        lainInput: '',
        lainInputColor: 'bg-secondary'
      },
      pac: {
        bdcolor: 'secondary',
        disabled: true,
        lain: true,
        lainInput: '',
        lainInputColor: 'bg-secondary'
      },
      gelQ: {
        lain: true,
        lainInput: ''
      },
      qt: {
        lain: true,
        lainInput: ''
      },
      qtc: {
        lain: true,
        lainInput: ''
      },
      segmenST: {
        lain: true,
        lainInput: ''
      },
      getT: {
        lain: true,
        lainInput: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
    this.getDokterJantung()
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    async getDokterJantung () {
      const response = await Services.dokterIrj(101010148)
      this.options.dokter = response.data.data
    },
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    },
    onHeartRate (values) {
      if (values.includes('3')) {
        this.iramadasar.openHeartRate = false
      } else {
        this.iramadasar.heartRateInput = ''
        this.iramadasar.openHeartRate = true
      }
    },
    onLainIramaDasar (values) {
      if (values.includes('4')) {
        this.iramadasar.lain = false
      } else {
        this.iramadasar.lainInput = ''
        this.iramadasar.lain = true
      }
    },
    onChangeAksis (values) {
      if (values.includes(4)) {
        this.aksis.lain = false
      } else {
        this.aksis.lain = true
        this.aksis.lainInput = ''
      }
    },
    onChangeKonduksiAV (values) {
      if (values.includes(3)) {
        this.konduksiAV.lain = false
      } else {
        this.konduksiAV.lain = true
        this.konduksiAV.lainInput = ''
      }
    },
    onChangeGelP (values) {
      if (values.includes(4)) {
        this.gelP.lain = false
      } else {
        this.gelP.lain = true
        this.gelP.lainInput = ''
      }
    },
    onChangeGelQRS (values) {
      if (values.includes(6)) {
        this.gelQRS.lain = false
      } else {
        this.gelQRS.lain = true
        this.gelQRS.lainInput = ''
      }
    },
    onChangePVC (values) {
      if (values[0] === '1') {
        this.pvc.bdcolor = 'danger'
        this.pvc.disabled = false
      } else {
        this.pvc.bdcolor = 'secondary'
        this.pvc.disabled = true
        this.pvc.lainInput = ''
        this.selected.pvc = []
        this.pvc.lainInputColor = 'bg-secondary'
      }
    },
    onLainPVC (values) {
      if (values === 2) {
        this.pvc.lain = false
        this.pvc.lainInputColor = 'bg-white'
      } else {
        this.pvc.lain = true
        this.pvc.lainInput = ''
        this.pvc.lainInputColor = 'bg-secondary'
      }
    },
    onChangePAC (values) {
      if (values[0] === '1') {
        this.pac.bdcolor = 'danger'
        this.pac.disabled = false
      } else {
        this.pac.bdcolor = 'secondary'
        this.pac.disabled = true
        this.pac.lainInput = ''
        this.selected.pac = []
        this.pac.lainInputColor = 'bg-secondary'
      }
    },
    onLainPAC (values) {
      if (values === 2) {
        this.pac.lain = false
        this.pac.lainInputColor = 'bg-white'
      } else {
        this.pac.lain = true
        this.pac.lainInput = ''
        this.pac.lainInputColor = 'bg-secondary'
      }
    },
    onChangeGelQ (values) {
      if (values.includes(3)) {
        this.gelQ.lain = false
      } else {
        this.gelQ.lain = true
        this.gelQ.lainInput = ''
      }
    },
    onChangeQT (values) {
      if (values[0] === 1) {
        this.qt.lain = false
      } else {
        this.qt.lain = true
        this.qt.lainInput = ''
      }
    },
    onChangeQTC (values) {
      if (values[0] === 1) {
        this.qtc.lain = false
      } else {
        this.qtc.lain = true
        this.qtc.lainInput = ''
      }
    },
    onChangeSegmenST (values) {
      if (values.includes(4)) {
        this.segmenST.lain = false
      } else {
        this.segmenST.lain = true
        this.segmenST.lainInput = ''
      }
    },
    onChangeGelT (values) {
      if (values.includes(5)) {
        this.gelT.lain = false
      } else {
        this.gelT.lain = true
        this.gelT.lainInput = ''
      }
    }
  }
}
</script>
<style>
.emr-ekg {
  background-color: white;
}
.emr-ekg .my-bg-white {
  background: #eff7f9;
}
.emr-ekg .radius-custom-emr {
  border-radius: .5rem;
}
.emr-ekg .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-ekg .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-ekg .radius-custom-form-head-ekg {
  border-top-right-radius: .5rem;
}
.emr-ekg .radius-custom-form-border-ekg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.emr-ekg .my-color-white {
  background-color: white;
}
.emr-ekg .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-ekg .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
.emr-ekg .plus-button {
  transition: all 1s;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.emr-ekg .plus-button:hover .hover {
  background-color: rgba(0, 0, 0, 0.671);
  color:white;
}
.emr-ekg .form-control {
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background: white;
}
.emr-ekg .bg-abu {
  background: #0da1b125;
}
.emr-ekg .custom-control-label {
  margin-top: 0;
}
.emr-ekg .multiselect__single {
  font-size: 13px !important;
}
.emr-ekg .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-ekg .multiselect__option {
  font-size: 13px;
}
.emr-ekg .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-ekg .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-ekg .body {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
}
.emr-ekg .bg-bigeminy {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
}
.emr-ekg .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
}
.emr-ekg .ekg-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-ekg .ekg-fc-black {
  color: black;
  font-weight: bold;
}
.emr-ekg .table th {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-ekg .cstm-cntrl {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-ekg .custom-min-height {
  min-height: 100%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-ekg .custom-min-height1 {
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-ekg .custom-flatpicker {
  border: 1px solid rgb(233, 233, 233);
  height: 38px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  border-radius: 4px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}
.emr-ekg .my-cursor {
  cursor: pointer;
}
.emr-ekg .rounded-custom {
  border-radius: 5px;
  border: 1px solid rgb(209, 209, 209);
}
.emr-ekg .excel-color {
  color: green;
}
.emr-ekg .ekg-color-bg {
  background-color: #089bab;
}
@-webkit-keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
@keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
</style>
