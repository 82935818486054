<template>
  <div class="utd-list">
    <b-container fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h5 class="card-title">Reservasi Pendonor Unit Transfusi Darah</h5>
        </template>
        <template v-slot:body>
          <div>
            <b-table class="mb-0" ref="table" :busy="isBusy" :items="items" :fields="fields" :filter="filter"
              @filtered="onFiltered" :current-page="currentPage" :per-page="perPage" stacked="md" bordered hover
              show-empty small striped>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                  <strong class="text-dark">Memuat Data...</strong>
                </div>
              </template>
              <template #cell(index)="data">
                {{ (currentPage*perPage)-perPage + data.index + 1 }}
              </template>
              <template v-slot:cell(tanggal_lahir)="data">
                <span>{{ new Date(data.item.tanggal_lahir) | formatDate }}</span>
              </template>
              <template v-slot:cell(jenis_kelamin)="data">
                <span>{{ data.item.jenis_kelamin == 1 ? 'Laki - laki' : 'Perempuan' }}</span>
              </template>
              <template v-slot:cell(tanggal)="data">
                <span>{{ new Date(data.item.tanggal) | formatDate }}</span>
              </template>
              <template v-slot:cell(jenis_layanan)="data">
                <span class="text-dark" v-if="data.item.jenis_layanan == 1">Gedung</span>
                <span class="text-dark" v-else-if="data.item.jenis_layanan == 2">Mobil Unit</span>
              </template>
              <template v-slot:cell(status)="data">
                <span class="text-secondary"
                v-if="data.item.status == 0 && data.item.status_pendaftaran == 0 && data.item.status_pemeriksaan == 0">
                Menunggu Pendaftaran <i class="fas fa-book-medical"></i>
                </span>
                <span class="custom-yellow"
                v-if="data.item.status == 0 && data.item.status_pendaftaran == 1 && data.item.status_pemeriksaan == 0">
                Menunggu Pemeriksaan <i class="fas fa-notes-medical"></i>
                </span>
                <span class="text-primary"
                v-if="data.item.status == 0 && data.item.status_pendaftaran == 1 && data.item.status_pemeriksaan == 1">
                Menunggu Dokter <i class="fas fa-user-md"></i>
                </span>
                <span class="text-success" v-else-if="data.item.status == 1">Lolos Tes <i
                    class="fas fa-check-circle"></i></span>
                <span class="text-danger" v-else-if="data.item.status == 2">Tidak Lolos <i
                    class="fas fa-times-circle"></i></span>
              </template>
              <template v-slot:cell(alasan)="data">
                <span class="text-dark" v-if="data.item.alasan == null || data.item.alasan == 0">---</span>
                <span class="text-danger" v-else-if="data.item.alasan == 1">Kurang Berat Badan</span>
                <span class="text-danger" v-else-if="data.item.alasan == 2">HB</span>
                <span class="text-danger" v-else-if="data.item.alasan == 3">Tensi</span>
                <span class="text-danger" v-else-if="data.item.alasan == 4">Lain - Lain</span>
              </template>
              <template v-slot:cell(action)="data">
                <div v-if="access.bitcontrol2 === '1' || access.isAdmin === 'true'">
                  <b-button variant="white" size="md" @click="data.toggleDetails">
                    <i class="fas fa-eye text-dark"></i>
                  </b-button>
                  <b-button
                    variant="white" size="md" @click="pendaftaranUtd(data.item.id_dft_trx_reservasi_utd_detail, data.item.nama, data.item.status, data.item.tanggal_lahir, data.item.jenis_kelamin, data.item.nomor_reservasi, data.item.jenis_layanan)">
                    <i class="fas fa-book-medical text-secondary"></i>
                  </b-button>
                  <b-button
                    variant="white" size="md" @click="pemeriksaanUtd(data.item.id_dft_trx_reservasi_utd_detail, data.item.nama, data.item.status, data.item.tanggal_lahir, data.item.jenis_kelamin, data.item.nomor_reservasi)">
                    <i class="fas fa-notes-medical custom-yellow"></i>
                  </b-button>
                  <b-button variant="white" size="md"
                    @click="DokterUtd(data.item.id_dft_trx_reservasi_utd_detail, data.item.nama, data.item.status, data.item.tanggal_lahir, data.item.jenis_kelamin, data.item.nomor_reservasi)">
                    <i class="fas fa-user-md text-primary"></i>
                  </b-button>
                  <b-button variant="white" size="md" @click="dataCetakKirim(data.item.nama, data.item.nomor, data.item.tanggal_lahir, data.item.jenis_kelamin)">
                    <i class="fas fa-print text-danger"></i>
                  </b-button>
                </div>
                <div v-if="access.bitcontrol2 === '2'">
                  <b-button variant="white" size="md" @click="data.toggleDetails">
                    <i class="fas fa-eye text-dark"></i>
                  </b-button>
                  <b-button
                    variant="white" size="md" @click="pendaftaranUtd(data.item.id_dft_trx_reservasi_utd_detail, data.item.nama, data.item.status, data.item.tanggal_lahir, data.item.jenis_kelamin, data.item.nomor_reservasi, data.item.jenis_layanan)">
                    <i class="fas fa-book-medical text-secondary"></i>
                  </b-button>
                </div>
                <div v-if="access.bitcontrol2 === '3'">
                  <b-button variant="white" size="md" @click="data.toggleDetails">
                    <i class="fas fa-eye text-dark"></i>
                  </b-button>
                  <b-button
                    variant="white" size="md" @click="pendaftaranUtd(data.item.id_dft_trx_reservasi_utd_detail, data.item.nama, data.item.status, data.item.tanggal_lahir, data.item.jenis_kelamin, data.item.nomor_reservasi, data.item.jenis_layanan)">
                    <i class="fas fa-book-medical text-secondary"></i>
                  </b-button>
                  <b-button
                    variant="white" size="md" @click="pemeriksaanUtd(data.item.id_dft_trx_reservasi_utd_detail, data.item.nama, data.item.status, data.item.tanggal_lahir, data.item.jenis_kelamin, data.item.nomor_reservasi)">
                    <i class="fas fa-notes-medical custom-yellow"></i>
                  </b-button>
                </div>
              </template>
              <template #row-details="data">
                <div class="bg-white border p-3">
                  <b-row>
                    <b-col>
                      <div>
                        <b-row class="mb-1">
                          <b-col cols="4">
                            <p class="mb-0">Nomor Identitas</p>
                          </b-col>
                          <b-col cols="1">
                            <p class="mb-0">:</p>
                          </b-col>
                          <b-col>
                            <p class="font-size-responsive mb-0">{{ data.item.nomor }}</p>
                          </b-col>
                        </b-row>
                        <b-row class="mb-1">
                          <b-col cols="4">
                            <p class="mb-0">Nomor Handphone</p>
                          </b-col>
                          <b-col cols="1">
                            <p class="mb-0">:</p>
                          </b-col>
                          <b-col>
                            <p class="font-size-responsive mb-0">{{ data.item.no_handphone }}</p>
                          </b-col>
                        </b-row>
                        <b-row class="mb-1">
                          <b-col cols="4">
                            <p class="mb-0">Email</p>
                          </b-col>
                          <b-col cols="1">
                            <p class="mb-0">:</p>
                          </b-col>
                          <b-col>
                            <p class="font-size-responsive mb-0" v-if="data.item.email">{{ data.item.email }}</p>
                            <p class="font-size-responsive mb-0" v-else>-</p>
                          </b-col>
                        </b-row>
                        <b-row class="mb-1">
                          <b-col cols="4">
                            <p class="mb-0">Fax</p>
                          </b-col>
                          <b-col cols="1">
                            <p class="mb-0">:</p>
                          </b-col>
                          <b-col>
                            <p class="font-size-responsive mb-0" v-if="data.item.fax">{{ data.item.fax }}</p>
                            <p class="font-size-responsive mb-0" v-else>-</p>
                          </b-col>
                        </b-row>
                        <b-row class="mb-1">
                          <b-col cols="4">
                            <p class="mb-0">Alamat</p>
                          </b-col>
                          <b-col cols="1">
                            <p class="mb-0">:</p>
                          </b-col>
                          <b-col>
                            <p class="font-size-responsive mb-0">{{ data.item.alamat }}</p>
                          </b-col>
                        </b-row>
                        <b-row class="mb-0" v-if="data.item.keterangan_lain_lain">
                          <b-col cols="4">
                            <p class="mb-0">Keterangan Lain - Lain</p>
                          </b-col>
                          <b-col cols="1">
                            <p class="mb-0">:</p>
                          </b-col>
                          <b-col>
                            <p class="font-size-responsive text-danger mb-0">{{ data.item.keterangan_lain_lain }}</p>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                    <b-col></b-col>
                  </b-row>
                </div>
              </template>
            </b-table>
          </div>
          <div class="bg-white shadow-sm pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
            <b-row class="align-items-center justify-content-between">
              <b-col cols="12" lg="3" class="mr-auto">
                <div class="d-flex align-items-center">
                  <div>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" size="sm" class="my-0">
                    </b-pagination>
                  </div>
                  <div class="border-left border-right px-3">
                    <span class="cursor-point" @click="tableRefresh">
                      <i class="fas fa-sync"></i>
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="5">
                <div class="d-flex flex-row w-100">
                  <div class="w-75 mr-2">
                    <b-form-input class="cstm-slct-irj-Admin rounded-0" id="filter-input" v-model="filter" type="search" placeholder="[ Nama / No. Reservasi ]"></b-form-input>
                    </div>
                  <div class="w-25">
                    <b-form-select class="cstm-slct-pegawai" v-model="selected.filterType" :options="combo.filterType"></b-form-select>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="3">
                <span class="text-right">
                  <p class="font-weight-normal mb-0">Menampilkan 1 - {{ perPage }} dari {{ totalRows }}</p>
                </span>
              </b-col>
            </b-row>
          </div>
        </template>
      </iq-card>
    </b-container>
    <div id="printCoba" style="display: none;">
      <div class="page" style="border: none; width: 104mm; height: 50mm; margin-top: 00px; margin-left: -5px; margin-right: -100px; margin-bottom: -100px;">
        <div class="page-area" style="font-family: Arial, Helvetica, sans-serif;">
          <table class="header font-arial" style="width: 100%; padding: 0; border-bottom: 1px solid black;">
            <tbody>
              <tr>
                <td width="100%" style="text-align: center;">
                  <strong><span style="color: black; font-size: 1rem;">RSUP FATMAWATI</span></strong>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="padding-left: 4mm; padding-right: 4mm; font-size: 3mm; width: 100%; margin-top: 10px;">
            <tbody style="padding-top: 10mm;">
              <tr >
                <td style="padding-left: 100px;" width="100%"><span style="font-size: 0.8rem; color: black;">{{ dataPrint.nama }} <span style="font-weight: bold;">({{ dataPrint.jenis_kelamin }})</span></span></td>
              </tr>
              <tr>
                <td style="padding-left: 100px;" width="100%"><span style="font-size: 0.8rem; color: black;">{{ dataPrint.tgl_lahir }}</span></td>
              </tr>
              <tr>
                <td style="padding-left: 100px;" width="100%"><span style="font-size: 0.8rem; color: black;">{{ dataPrint.nik }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import UTD from '../../../services/utd.js'
// import jwtdecode from 'jwt-decode'
export default {
  name: 'List',
  data () {
    return {
      access: {
        isAdmin: false,
        bitcontrol2: 0
      },
      utilityClass: 0,
      arrUtility: [],
      isBusy: false,
      error: [],
      items: this.listUtd,
      filterOn: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      fields: [
        { label: 'NO.', key: 'index', class: 'text-center' },
        { label: 'NAMA', key: 'nama', class: 'text-left' },
        { label: 'NO. RESERVASI', key: 'nomor_reservasi', class: 'text-left' },
        // { label: 'NO. IDENTITAS', key: 'nomor', class: 'text-left' },
        { label: 'TANGGAL LAHIR', key: 'tanggal_lahir', class: 'text-left' },
        { label: 'JENIS KELAMIN', key: 'jenis_kelamin', class: 'text-left' },
        // { label: 'NO. HANDPHONE', key: 'no_handphone', class: 'text-left' },
        { label: 'TANGGAL RESERVASI', key: 'tanggal', class: 'text-left' },
        { label: 'JENIS LAYANAN', key: 'jenis_layanan', class: 'text-left' },
        { label: 'STATUS', key: 'status', class: 'text-left' },
        { label: 'KETERANGAN', key: 'alasan', class: 'text-left' },
        { label: 'AKSES', key: 'action', class: 'text-center' }
      ],
      selected: {
        filterType: '1'
      },
      combo: {
        filterType: [
          { value: '1', text: 'No. Reservasi' }
        ]
      },
      dataPrint: {
        nama: '',
        tgl_lahir: '',
        nik: '',
        jenis_kelamin: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.permissions()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async listUtd (ctx) {
      if (ctx.filter === '') {
        ctx.filter = null
      }
      this.isBusy = true
      try {
        const res = await UTD.listPasien(ctx.currentPage, ctx.filter)
        this.totalRows = res.data.data.total
        this.isBusy = false
        return res.data.data.data
      } catch (err) {
        this.isBusy = false
        return []
      }
    },
    permissions () {
      this.access.isAdmin = xray.isAdministrator()
      this.access.bitcontrol2 = xray.bitcontrol(this.$route.name).bitcontrol2.slice(0, 1)
    },
    pendaftaranUtd (id, nama, status, umur, jk, noresrv, jl) {
      if (jk === 1) {
        jk = 'Laki - Laki'
      } else {
        jk = 'Perempuan'
      }
      this.$router.push({
        name: 'utd.pendaftaran', params: { id }, query: { nama, status, umur, jk, noresrv, jl }
      })
    },
    pemeriksaanUtd (id, nama, status, umur, jk, noresrv) {
      if (jk === 1) {
        jk = 'Laki - Laki'
      } else {
        jk = 'Perempuan'
      }
      this.$router.push({
        name: 'utd.pemeriksaan', params: { id }, query: { nama, status, umur, jk, noresrv }
      })
    },
    DokterUtd (id, nama, status, umur, jk, noresrv) {
      if (jk === 1) {
        jk = 'Laki - Laki'
      } else {
        jk = 'Perempuan'
      }
      this.$router.push({
        name: 'utd.dokter', params: { id }, query: { nama, status, umur, jk, noresrv }
      })
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    dataCetakKirim (nama, nomor, tglLahir, jk) {
      this.dataPrint.nama = nama
      this.dataPrint.tgl_lahir = tglLahir.split('-').reverse().join('-')
      this.dataPrint.nik = nomor
      if (jk === 1) {
        this.dataPrint.jenis_kelamin = 'L'
      } else if (jk === 2) {
        this.dataPrint.jenis_kelamin = 'P'
      }
      setTimeout(() => {
        this.cetak()
      }, 1000)
    },
    cetak () {
      const prtHtml = document.getElementById('printCoba')
      const Winprint = window.open('', '', 'height=1000,width=1000')
      Winprint.document.write(`<!DOCTYPE html>
      <html lang="en">
      <head>
      </head>
      <body>
      ${prtHtml.innerHTML}
      </body>
      </html>`)
      Winprint.document.close()
      Winprint.focus()
      Winprint.print()
      Winprint.close()
      setTimeout(() => {
        this.clearPrintArea()
      }, 1000)
    },
    clearPrintArea () {
      this.dataPrint.nama = ''
      this.dataPrint.tgl_lahir = ''
      this.dataPrint.nik = ''
      this.dataPrint.jenis_kelamin = ''
    }
  }
}
</script>
<style>
.utd-cursor {
  cursor: pointer;
}
.utd-list .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
.utd-list .table th {
    font-size: 13px;
    font-weight: 400;
}
.utd-list .page-link {
  background: transparent!important;
    border: none!important;
    color: gray!important;
    margin-right: 15px;
}
.utd-list .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
.utd-list .cursor-point {
    cursor: pointer;
}
.cstm-slct-irj-Admin {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-irj-Admin {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 4px!important;
}
.custom-fontsize {
  font-size: 12px;
}
.utd-list .custom-yellow {
  color: #eea703;
}
.utd-list .custom-yellow-bg {
  background-color: #fbc647;
}
</style>
