<template>
  <div class="emr-instrumental-adl">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-instrumental-adl">
            <b-row>
              <b-col md="11"><span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Instrumental ADL</span></b-col>
              <b-col md="1" class="text-end"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buat Instrumental ADL Baru'"><i class="fas fa-plus"></i></span></b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <div class="bg-custom-1">
              <b-row>
                <b-col md="4"><h4><b-badge variant="dark">Kemampuan Menggunakan Telepon</b-badge></h4></b-col>
                <b-col md="8"><b-form-select :options="options.telepon" v-model="defVal.telepon" size="sm"></b-form-select></b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="4"><h4><b-badge variant="dark">Berbelanja</b-badge></h4></b-col>
                <b-col md="8"><b-form-select :options="options.berbelanja" v-model="defVal.berbelanja" size="sm"></b-form-select></b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="4"><h4><b-badge variant="dark">Menyiapkan Makanan</b-badge></h4></b-col>
                <b-col md="8"><b-form-select :options="options.makanan" v-model="defVal.makanan" size="sm"></b-form-select></b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="4"><h4><b-badge variant="dark">Urusan Rumah Tangga</b-badge></h4></b-col>
                <b-col md="8"><b-form-select :options="options.rumahtangga" v-model="defVal.rumahtangga" size="sm"></b-form-select></b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="4"><h4><b-badge variant="dark">Mencuci Pakaian</b-badge></h4></b-col>
                <b-col md="8"><b-form-select :options="options.mencuci" v-model="defVal.mencuci" size="sm"></b-form-select></b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="4"><h4><b-badge variant="dark">Penggunaan Transportasi</b-badge></h4></b-col>
                <b-col md="8"><b-form-select :options="options.transportasi" v-model="defVal.transportasi" size="sm"></b-form-select></b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="4"><h4><b-badge variant="dark">Tanggungjawab Obat Sendiri</b-badge></h4></b-col>
                <b-col md="8"><b-form-select :options="options.obat" v-model="defVal.obat" size="sm"></b-form-select></b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="4"><h4><b-badge variant="dark">Mengatur Keuangan</b-badge></h4></b-col>
                <b-col md="8"><b-form-select :options="options.keuangan" v-model="defVal.keuangan" size="sm"></b-form-select></b-col>
              </b-row>
            </div>
            <div class="mr-0 bg-custom-2 mt-2">
              <b-row class="align-items-center">
                <b-col offset-md="10">
                  <b-card class="border">
                    <b-row no-gutters>
                      <b-col md="2" class="m-3">
                        <h2><i class="fas fa-calculator"></i></h2>
                      </b-col>
                      <b-col md="6" class="text-center">
                        <b-card-body title="Total">
                          <b-card-text>
                            <h3> {{ this.defVal.showTotal }} </h3>
                          </b-card-text>
                        </b-card-body>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </div>
            <div class="mt-2">
              <b-row class="mr-0">
                <b-col>
                  <div class="text-right">
                    <b-button variant="success" size="sm" squared><i class="fas fa-save"></i>Simpan</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formMiddle" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span></span> &nbsp; <span class="h6"><i class="far fa-edit"></i> &nbsp;  Catatan Medis Kunjungan Terkini</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '3'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> &nbsp; <span class="h6"><i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '3'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'InstrumentalADL',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '7',
      formMiddle: '4',
      formRight: '1',
      defVal: {
        telepon: 0,
        berbelanja: 0,
        makanan: 0,
        rumahtangga: 0,
        mencuci: 0,
        transportasi: 0,
        obat: 0,
        keuangan: 0,
        showTotal: 0
      },
      options: {
        telepon: [
          { value: 1, text: 'Menggunakan telepon atas inisiatif sendiri, mampu mencari dan memencet nomor telepon' },
          { value: 2, text: 'Mampu memencet beberapa nomor telepon yang diketahui' },
          { value: 3, text: 'Hanya bisa menjawab telepon tapi tidak mampu memencet nomor telepon' },
          { value: 4, text: 'Sama sekali tidak bisa menggunakan telepon' }
        ],
        berbelanja: [
          { value: 1, text: 'Mampu menangani semua kebutuhan belanja secara mandiri' },
          { value: 2, text: 'Mampu berbelanja sendiri untuk barang-barang kecil' },
          { value: 3, text: 'Perlu ditemani setiap berbelanja' },
          { value: 4, text: 'Sama sekali tidak bisa berbelanja' }
        ],
        makanan: [
          { value: 1, text: 'Merencanakan, menyiapkan/memasak, dan menyajikan makanan secara mandiri' },
          { value: 2, text: 'Menyiapkan/memasak makanan jika bahannya sudah disediakan' },
          { value: 3, text: 'Memanaskan, menyajikan, dan menyiapkan makanan namun tidak memenuhi kebutuhan yang cukup' },
          { value: 4, text: 'Perlu bantuan menyiapkan/memasak, dan menyajikan makanan' }
        ],
        rumahtangga: [
          { value: 1, text: 'Memelihara rumah sendiri atau sesekali perlu bantuan untuk pekerjaan yang berat' },
          { value: 2, text: 'Melakukan pekerjaan rumah tangga yang ringan seperti mencuci piring' },
          { value: 3, text: 'Melakukan pekerjaan rumah tangga yang ringan kurang baik/tidak bersih' },
          { value: 4, text: 'Bantuan untuk semua pekerjaan rumah tangga' },
          { value: 5, text: 'Sama sekali tidak mampu melakukan pekerjaan rumah tangga' }
        ],
        mencuci: [
          { value: 1, text: 'Mampu mencuci pakaian sendiri' },
          { value: 2, text: 'Mampu mencuci pakaian-pakaian yang ringin, seperti kaos kaku, dll' },
          { value: 3, text: 'Mencuci dilakukan orang lain' }
        ],
        transportasi: [
          { value: 1, text: 'Dapat berpergian dengan menggunakan kendaraan umum atau menyetir sendiri' },
          { value: 2, text: 'Dapat berpergian dengan Taxi, Bajaj, Ojek namun tidak dengan kendaraan umum' },
          { value: 3, text: 'Dapat berpergian dengan kendaraan umum jika ditemani' },
          { value: 4, text: 'Hanya bisa dengan Taxi atau mobil sendiri dengan ditemani' },
          { value: 5, text: 'Sama sekali tidak mampu berpergian' }
        ],
        obat: [
          { value: 1, text: 'Mampu bertanggungjawab terhadap minum obat dengan dosis dan waktu yang tepat' },
          { value: 2, text: 'Mampu bertanggungjawab terhadap obat jika telah disiapkan' },
          { value: 3, text: 'Tidak mampu minum obat sendiri' }
        ],
        keuangan: [
          { value: 1, text: 'Mampu mengatur masalah keuangan sendiri (merencanakan, membuat, mencatatat, membayar, tagihan, dll)' },
          { value: 2, text: 'Mampu mengatur belanja sehari-hari, namun perlu bantuan dalam hal perbankan, dll' },
          { value: 3, text: 'Tidak mampu mengatur keuangan sendiri' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  computed: {
    total () {
      return this.defVal.telepon + this.defVal.berbelanja + this.defVal.makanan + this.defVal.rumahtangga + this.defVal.mencuci + this.defVal.transportasi + this.defVal.obat + this.defVal.keuangan
    }
  },
  watch: {
    total (values) {
      this.defVal.showTotal = values
    }
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    }
  }
}
</script>
<style>
.emr-instrumental-adl .radius-custom-emr {
  border-radius: .5rem;
}
.emr-instrumental-adl .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-instrumental-adl .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-instrumental-adl .radius-custom-form-head-instrumental-adl {
  border-top-right-radius: .5rem;
}
.emr-instrumental-adl .radius-custom-form-border-instrumental-adl {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.emr-instrumental-adl .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-instrumental-adl .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
.emr-instrumental-adl .plus-button {
  transition: all 1s;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.emr-instrumental-adl .plus-button:hover .hover {
  background-color: rgba(0, 0, 0, 0.671);
  color:white;
}
.emr-instrumental-adl .form-control {
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background: white;
}
.emr-instrumental-adl .bg-white {
  background: #ffffff;
}
.emr-instrumental-adl .my-bg-white {
  background: #ffffff;
}
.emr-instrumental-adl .custom-control-label {
  margin-top: 0;
}
.emr-instrumental-adl .multiselect__single {
  font-size: 13px !important;
}
.emr-instrumental-adl .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-instrumental-adl .multiselect__option {
  font-size: 13px;
}
.emr-instrumental-adl .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-instrumental-adl .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-instrumental-adl .body {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
}
.emr-instrumental-adl .bg-custom-1 {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-instrumental-adl .bg-custom-2 {
  background-color: #d2ff0848;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-instrumental-adl .bg-custom-2 {
  background-color: #faffb8;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-instrumental-adl .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
}
.emr-instrumental-adl .instrumental-adl-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-instrumental-adl .instrumental-adl-fc-black {
  color: black;
  font-weight: bold;
}
.emr-instrumental-adl .table th {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-instrumental-adl .cstm-cntrl {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-instrumental-adl .custom-min-height {
  min-height: 100%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-instrumental-adl .custom-min-height1 {
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-instrumental-adl .laporan-donwload-pdf {
  min-height: 100%;
}
.emr-instrumental-adl .custom-flatpicker {
  border: 1px solid rgb(233, 233, 233);
  height: 38px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  border-radius: 4px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}
.emr-instrumental-adl .my-cursor {
  cursor: pointer;
}
.emr-instrumental-adl .rounded-custom {
  border-radius: 5px;
  border: 1px solid rgb(209, 209, 209);
}
.emr-instrumental-adl .pdf-color {
  color: rgb(214, 0, 0);
}
.emr-instrumental-adl .pdf-color-bg {
  background-color: rgb(214, 0, 0);
}
.emr-instrumental-adl .excel-color {
  color: green;
}
.emr-instrumental-adl .instrumental-adl-color-bg {
  background-color: #089bab;
}
@-webkit-keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
@keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
</style>
