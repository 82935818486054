<template>
  <div class="emr-resiko-dekubiktus">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="bg-primary p-2 radius-custom-form-head-resiko-dekubiktus">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Resiko Dekubiktus</span>
          </div>
          <div class="custom-scroller p-3" :style="{ height: heightPercent }">
            <b-row class="mr-0">
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col md="3">
                    <label class="resiko-dekubiktus-fc-black">Kondisi Fisik :</label>
                  </b-col>
                  <b-col md="9">
                    <b-form-select v-model="defVal.fisik" :options="options.fisik"></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col md="3">
                    <label class="resiko-dekubiktus-fc-black">Mobilitas :</label>
                  </b-col>
                  <b-col md="9">
                    <b-form-select v-model="defVal.mobilitas" :options="options.mobilitas"></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-1">
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col md="3">
                    <label class="resiko-dekubiktus-fc-black">Status Mental :</label>
                  </b-col>
                  <b-col md="9">
                    <b-form-select v-model="defVal.mental" :options="options.mental"></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col md="3">
                    <label class="resiko-dekubiktus-fc-black">Inkontinensia :</label>
                  </b-col>
                  <b-col md="9">
                    <b-form-select v-model="defVal.inkontinensia" :options="options.inkontinensia"></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-1">
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col md="3">
                    <label class="resiko-dekubiktus-fc-black">Aktifitas :</label>
                  </b-col>
                  <b-col md="9">
                    <b-form-select v-model="defVal.aktifitas" :value="defVal.aktifitas" :options="options.aktifitas"></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col md="4">
                    <label class="resiko-dekubiktus-fc-black">Waktu Pemeriksaan :</label>
                  </b-col>
                  <b-col md="8">
                    <flat-pickr placeholder="Waktu Pemeriksaan" class="w-100 calender-small custom-flat-pickr m-0"
                    :config="config" v-model="defVal.waktu">
                    </flat-pickr>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-3">
              <b-col md="12">
                <div class="text-right">
                  <b-row>
                    <b-col><h5>Kriteria Penilaian : <b-badge :variant="colorVar"> {{this.penilaian}} </b-badge></h5></b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-3">
              <b-col>
                <div class="table mb-0">
                  <b-table class="mb-0"
                  ref="table"
                  bordered
                  hover
                  striped
                  small
                  :items="items.table1"
                  :fields="fields.table1">
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'ResikoDekubiktus',
  components: {
    Riwayat,
    flatPickr
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      config: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        time_24hr: true
      },
      showTotal: 0,
      penilaian: 'Tidak Ada Resiko',
      defVal: {
        fisik: 0,
        mobilitas: 0,
        mental: 0,
        inkontinensia: 0,
        aktifitas: 0,
        waktu: ''
      },
      colorVar: 'success',
      options: {
        fisik: [
          { text: 'Baik', value: 1 },
          { text: 'Sedang', value: 2 },
          { text: 'Buruk', value: 3 },
          { text: 'Sangat Buruk', value: 4 }
        ],
        mobilitas: [
          { text: 'Bebas Bergerak', value: 1 },
          { text: 'Gerak Terbatas', value: 2 },
          { text: 'Sangat Terbatas', value: 3 },
          { text: 'Tidak Bergerak', value: 4 }
        ],
        mental: [
          { text: 'Sadar', value: 1 },
          { text: 'Apatis', value: 2 },
          { text: 'Bingung', value: 3 },
          { text: 'Stupor', value: 4 }
        ],
        inkontinensia: [
          { text: 'Kontinen', value: 1 },
          { text: 'Kadang Inkontinen', value: 2 },
          { text: 'Selalu Kontinen', value: 3 },
          { text: 'Inkontinen Urin & Alvi', value: 4 }
        ],
        aktifitas: [
          { text: 'Jalan Sendiri', value: 1 },
          { text: 'Jalan dengan Bantuan', value: 2 },
          { text: 'Kursi Roda', value: 3 },
          { text: 'Ditempat Tidur', value: 4 }
        ]
      },
      fields: {
        table1: [
          { key: 'ku', label: 'Keadaan Umum' },
          { key: 'sadar', label: 'Kesadaran' },
          { key: 'sis', label: 'Sistolik' },
          { key: 'dis', label: 'Distolik' },
          { key: 'nadi', label: 'Frekuensi Nadi' },
          { key: 'nafas', label: 'Frekuensi Nafas' },
          { key: 'suhu', label: 'Suhu' },
          { key: 'nyeri', label: 'Skor Nyeri' },
          { key: 'o2', label: 'Saturasi O2' },
          { key: 'wkt', label: 'Waktu Pemeriksaan' }
        ]
      },
      items: {
        table1: [
          { ku: 'Sadar sedikit', sadar: 'Dickerson', sis: 'Macdonald', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Larsen', sis: 'Shaw', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Jami', sis: 'Carney', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  computed: {
    total () {
      return this.defVal.fisik + this.defVal.mobilitas + this.defVal.mental + this.defVal.inkontinensia + this.defVal.aktifitas
    }
  },
  watch: {
    total (values) {
      if (values <= 5) {
        this.penilaian = 'Resiko Rendah'
        this.colorVar = 'success'
      } else if (values <= 8) {
        this.penilaian = 'Rentan Resiko'
        this.colorVar = 'warning'
      } else {
        this.penilaian = 'Resiko Tinggi'
        this.colorVar = 'danger'
      }
    }
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    }
  }
}
</script>
<style>
.emr-resiko-dekubiktus .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-resiko-dekubiktus .radius-custom-emr {
  border-radius: .5rem;
}
.emr-resiko-dekubiktus .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-resiko-dekubiktus .bg-input-tekanan-darah {
  background-color: #b8f4ff;
  padding: 3px;
  border-radius: 5px;
}
.emr-resiko-dekubiktus .resiko-dekubiktus-fc-black {
  color: black;
  font-weight: bold;
}
.emr-resiko-dekubiktus .resiko-dekubiktus-form-input {
  font-size: 9;
  height: 25px!important;
  border-radius: 5px;
}
.emr-resiko-dekubiktus .radius-custom-form-head-resiko-dekubiktus {
  border-top-right-radius: .5rem;
}
.emr-resiko-dekubiktus .table td {
    font-size: 9px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.emr-resiko-dekubiktus .table th {
    font-size: 10px;
    font-weight: bold;
}
.emr-resiko-dekubiktus .custom-flat-pickr{
    border: 1px solid rgb(233, 233, 233);
    height: 35px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 10px;
    font-size: 12px;
    color: black;
  }
.emr-resiko-dekubiktus .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-resiko-dekubiktus .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
