<template>
  <div class="emr-permasalahan-gizi">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="bg-primary p-2 radius-custom-form-head-permasalahan-gizi">
              <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Permasalahan Gizi</span>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <b-row class="mr-0 align-items-center">
              <b-col cols="6" class="permasalahan-gizi-fc-black">Adakah perubahan berat badan signifikan dalam 3 bulan terakhir : </b-col>
              <b-col>
                <b-form-radio-group
                  :options="options.beratbadan"
                  v-model="defVal.beratbadan"
                  @change="onChangeBeratBadan"
                ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row v-if="isSignifikan === 1" class="mr-0 align-items-center">
              <b-col cols="6" class="permasalahan-gizi-fc-black">Jumlah perubahan berat badan : </b-col>
              <b-col>
                <b-form-radio-group
                  :options="options.perubahanbb"
                  v-model="defVal.perubahanbb"
                ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 align-items-center">
              <b-col cols="6" class="permasalahan-gizi-fc-black">Intake makanan kurang karena tidak ada nafsu makan : </b-col>
              <b-col>
                <b-form-radio-group
                  :options="options.intake"
                  v-model="defVal.intake"
                ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 align-items-center">
              <b-col cols="6" class="permasalahan-gizi-fc-black">Kondisi khusus : </b-col>
              <b-col cols="5" class="permasalahan-gizi-fc-black">
                <div><b-form-input class="w-15 permasalahan-gizi-form-input"></b-form-input></div>
              </b-col>
            </b-row>
            <b-row class="mt-3 mr-0 align-items-center">
              <b-col offset-md="10">
                <b-card class="border">
                  <b-row no-gutters>
                    <b-col md="2" class="m-3">
                      <h2><i class="fas fa-calculator"></i></h2>
                    </b-col>
                    <b-col md="5" class="text-center">
                      <b-card-body title="Total">
                        <b-card-text>
                          <h3> {{ showTotal }} </h3>
                        </b-card-text>
                      </b-card-body>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0 align-items-center">
              <b-col offset-md="7"><h4><b-badge :variant="skorBgColor">Skor diatas 2 ?</b-badge></h4></b-col>
              <b-col>
                <b-form-radio-group
                  :options="options.isSkor"
                  v-model="isSkor"
                  disabled
                ></b-form-radio-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'PermasalahanGizi',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      isSignifikan: 0,
      isSkor: 0,
      showTotal: 0,
      cardVariant: 'success',
      skorBgColor: 'success',
      options: {
        beratbadan: [
          { text: 'Tidak (0)', value: 0 },
          { text: 'Ya (1)', value: 1 }
        ],
        intake: [
          { text: 'Tidak (0)', value: 0 },
          { text: 'Ya (1)', value: 1 }
        ],
        perubahanbb: [
          { text: '0.5 - 5 kg (1)', value: 1 },
          { text: '> 0.5 - 10 kg (2)', value: 2 },
          { text: '> 10 - 15 kg (3)', value: 3 },
          { text: '> 15 kg (4)', value: 4 }
        ],
        isSkor: [
          { text: 'Tidak (0)', value: 0 },
          { text: 'Ya (1)', value: 1 }
        ]
      },
      defVal: {
        beratbadan: 0,
        intake: 0,
        perubahanbb: 0
      },
      lainInput: {
        cacattubuh: true,
        textCt: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  computed: {
    total () {
      return this.defVal.beratbadan + this.defVal.perubahanbb + this.defVal.intake
    }
  },
  watch: {
    total (values) {
      this.showTotal = values
      if (values > 2) {
        this.isSkor = 1
        this.skorBgColor = 'warning'
      } else {
        this.isSkor = 0
        this.skorBgColor = 'success'
      }
    }
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    },
    onChangeBeratBadan (values) {
      if (values === 1) {
        this.isSignifikan = 1
      } else {
        this.isSignifikan = 0
        this.defVal.perubahanbb = 0
      }
    }
  }
}
</script>
<style>
.emr-permasalahan-gizi .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-permasalahan-gizi .radius-custom-emr {
  border-radius: .5rem;
}
.emr-permasalahan-gizi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-permasalahan-gizi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-permasalahan-gizi .bg-skor {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
}
.emr-permasalahan-gizi .radius-custom-form-head-permasalahan-gizi {
  border-top-right-radius: .5rem;
}
.emr-permasalahan-gizi .permasalahan-gizi-fc-black {
  color: black;
  font-weight: bold;
}
.emr-permasalahan-gizi .permasalahan-gizi-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-permasalahan-gizi .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-permasalahan-gizi .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
