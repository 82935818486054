<template>
  <div class="emr-status-pediatric">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="bg-primary p-2 radius-custom-form-head-status-pediatric">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Status Pediatric</span>
          </div>
          <div class="custom-scroller p-3" :style="{ height: heightPercent }">
            <b-row class="mr-0">
              <b-col md="12">
                <b-row class="ml-1 d-flex flex-row align-items-center my-bg">
                  <b-col md="3">
                    <label class="status-pediatric-fc-black m-0">Status Pediatric :</label>
                  </b-col>
                  <b-col md="9">
                    <b-form-checkbox-group
                      v-model="defVal.penyakitdasar"
                      :options="options.pediatric"
                    ></b-form-checkbox-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'StatusPediatric',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      defVal: {
        pediatric: 0
      },
      options: {
        pediatric: [
          { value: 1, text: 'Gizi Cukup' },
          { value: 2, text: 'Gizi Lebih' },
          { value: 3, text: 'Gizi Kurang' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    }
  }
}
</script>
<style>
.emr-status-pediatric .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-status-pediatric .radius-custom-emr {
  border-radius: .5rem;
}
.emr-status-pediatric .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-status-pediatric .my-bg {
  background-color: #b8f4ff;
  padding: 3px;
  border-radius: 5px;
}
.emr-status-pediatric .status-pediatric-fc-black {
  color: black;
  font-weight: bold;
}
.emr-status-pediatric .status-pediatric-form-input {
  font-size: 9;
  height: 25px!important;
  border-radius: 5px;
}
.emr-status-pediatric .radius-custom-form-head-status-pediatric {
  border-top-right-radius: .5rem;
}
.emr-status-pediatric .table td {
  font-size: 9px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.emr-status-pediatric .table th {
  font-size: 10px;
  font-weight: bold;
}
.emr-status-pediatric .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-status-pediatric .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
