<template>
  <div class="emr-rawat-inap">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="bg-primary p-2 radius-custom-form-head-rawat-inap">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Rawat Inap</span>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <b-row class="m-0">
              <b-col md="4">
                <b-calendar v-model="value" @context="onContext" locale="en-US"></b-calendar>
              </b-col>
              <b-col md="8">
                <b-row class="mb-2">
                  <b-col md="6">
                    <b-row>
                      <b-col md="12" class="rawat-inap-fc-black">Nomor :</b-col>
                      <b-col md="12"><b-form-input class="status-fungsional-form-input" placeholder="Generate by system"></b-form-input></b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row>
                      <b-col md="12" class="rawat-inap-fc-black">No. Referensi :</b-col>
                      <b-col md="12"><b-form-input class="status-fungsional-form-input" placeholder="Generate by system"></b-form-input></b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mr-0 mb-2 align-items-center">
                  <b-col md="12" class="rawat-inap-fc-black">Tanggal :</b-col>
                  <b-col md="12">
                    <flat-pickr placeholder="Pilih Tanggal" class=" w-100 calender-small custom-flat-pickr m-0"
                    :config="config" v-model="selected.waktu">
                    </flat-pickr>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col md="12" class="rawat-inap-fc-black">Keterangan :</b-col>
                  <b-col md="12" class="rawat-inap-fc-black"><b-form-textarea placeholder="Keterangan" no-resize></b-form-textarea></b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="text-right">
                      <b-button variant="success" size="sm" squared><i class="fas fa-save"></i>Simpan</b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'RawatInap',
  components: {
    Riwayat,
    flatPickr
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      value: '',
      context: null,
      formLeft: '8',
      formRight: '4',
      lainInput: {
        cacattubuh: true,
        textCt: ''
      },
      selecteddokter: [],
      selectedspesialistik: [],
      combodokter: [
        { name: 'dr. tumpal', id: 1 },
        { name: 'dr. danik', id: 2 }
      ],
      combospesialistik: [
        { name: 'Iterna', id: 1 },
        { name: 'Jiwa', id: 2 },
        { name: 'Mata', id: 3 },
        { name: 'Obygen', id: 4 },
        { name: 'Gigi', id: 5 },
        { name: 'Anastesi', id: 6 },
        { name: 'Anak', id: 7 },
        { name: 'Radiologi', id: 8 }
      ],
      config: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        time_24hr: true
      },
      selected: {
        waktu: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    onContext (ctx) {
      this.context = ctx.selectedYMD.split('-').reverse().join('-')
    },
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    },
    onChangeCacatTubuh (values) {
      if (values === 1) {
        this.lainInput.cacattubuh = false
        this.lainInput.textCt = 'Jika ya, Sebutkan ...'
      } else {
        this.lainInput.cacattubuh = true
        this.lainInput.textCt = ''
      }
    }
  }
}
</script>
<style>
.emr-rawat-inap .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.bgcolor {
  background-color : white;
}
.emr-rawat-inap .radius-custom-emr {
  border-radius: .5rem;
}
.emr-rawat-inap .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-rawat-inap.radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-rawat-inap .radius-custom-form-head-rencana-terapi {
  border-top-right-radius: .5rem;
}
fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}
legend.scheduler-border {
  font-size: 1.2em !important;
  width: auto !important;
  border: none;
  font-size: 14px;
}
.emr-rawat-inap .rawat-inap-fc-black {
  color: black;
  font-weight: bold;
}
.emr-rawat-inap .status-fungsional-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-rawat-inap .custom-flat-pickr{
  border: 1px solid rgb(233, 233, 233);
  height: 35px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208)!important;
  border-radius: 10px;
  font-size: 12px;
  color: black;
}
.emr-rawat-inap .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-rawat-inap .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
