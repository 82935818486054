<template>
  <div class="emr-tanda-vital">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="bg-primary p-2 radius-custom-form-head-tanda-vital">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Tanda Vital</span>
          </div>
          <div class="custom-scroller p-3" :style="{ height: heightPercent }">
            <b-row class="mr-0">
              <b-col md="6">
                <b-row class="mr-0 align-items-center">
                  <b-col class="tanda-vital-fc-black" md="4">Keadaan Umum : </b-col>
                  <b-col class="tanda-vital-fc-black" md="8">
                    <b-form-input class="w-15 tanda-vital-form-input"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col class="tanda-vital-fc-black" md="4">Saturasi O2 : </b-col>
                  <b-col class="tanda-vital-fc-black" md="8">
                    <b-form-input class="w-15 tanda-vital-form-input" type="number" v-model="defVal.saturasi"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-1">
              <b-col md="6">
                <b-row class="mr-0 align-items-center">
                  <b-col class="tanda-vital-fc-black" md="4">Kesadaran : </b-col>
                  <b-col class="tanda-vital-fc-black" md="8">
                    <b-form-input class="w-15 tanda-vital-form-input"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col class="tanda-vital-fc-black" md="4">Frekuensi Nadi (X/menit) : </b-col>
                  <b-col class="tanda-vital-fc-black" md="8">
                    <b-form-input class="w-15 tanda-vital-form-input" type="number" v-model="defVal.nadi"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-1">
              <b-col md="6">
                <b-row class="mr-0 align-items-center">
                  <b-col class="tanda-vital-fc-black" md="12">Tekanan Darah (mmHg) : </b-col>
                </b-row>
                <b-row class="mr-0 align-items-center">
                  <b-col class="tanda-vital-fc-black" md="2"> Sistolik </b-col>
                  <b-col class="tanda-vital-fc-black" md="3">
                    <b-form-input class="w-15 tanda-vital-form-input" type="number" v-model="defVal.sistolik"></b-form-input>
                  </b-col>
                  <b-col class="tanda-vital-fc-black" md="2"> &nbsp; / </b-col>
                  <b-col class="tanda-vital-fc-black" md="2"> Distolik </b-col>
                  <b-col class="tanda-vital-fc-black" md="3">
                    <b-form-input class="w-15 tanda-vital-form-input" type="number" v-model="defVal.distolik"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col class="tanda-vital-fc-black" md="4">Frekuensi Nafas (X/menit) : </b-col>
                  <b-col class="tanda-vital-fc-black" md="8">
                    <b-form-input class="w-15 tanda-vital-form-input" type="number" v-model="defVal.nafas"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-1">
              <b-col md="6">
                <b-row class="mr-0 align-items-center">
                  <b-col class="tanda-vital-fc-black" md="4">Suhu (OC) : </b-col>
                  <b-col class="tanda-vital-fc-black" md="8">
                    <b-form-input class="w-15 tanda-vital-form-input" v-model="defVal.suhu"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row class="align-items-center">
                  <b-col class="tanda-vital-fc-black" md="4">Skor Nyeri : </b-col>
                  <b-col md="6">
                    <b-input-group prepend="0" append="10">
                      <b-form-input type="range" min="0" max="10" v-model="defVal.nyeri"></b-form-input>
                      </b-input-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-input class="tanda-vital-form-input text-center" v-model="defVal.nyeri" readonly></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-1">
              <b-col md="12">
                <b-row class="mr-0 align-items-center">
                  <b-col class="tanda-vital-fc-black" md="2">Waktu Pemeriksaan : </b-col>
                  <b-col class="tanda-vital-fc-black" md="4">
                    <flat-pickr placeholder="Pilih Tanggal" class=" w-100 calender-small custom-flat-pickr m-0"
                    :config="config" v-model="defVal.waktu">
                    </flat-pickr>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-1">
              <b-col>
                <div class="text-right">
                  <b-button variant="success" size="sm" squared>Simpan</b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mr-0 mt-3 align-items-center">
              <b-col>
                <div class="table mb-0">
                  <b-table class="mb-0"
                  ref="table"
                  bordered
                  hover
                  striped
                  small
                  :items="items.table1"
                  :fields="fields.table1">
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'TandaVital',
  components: {
    flatPickr,
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      defVal: {
        saturasi: 0,
        nadi: 0,
        nafas: 0,
        sistolik: 0,
        distolik: 0,
        suhu: 0,
        nyeri: 0,
        waktu: ''
      },
      config: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        time_24hr: true
      },
      fields: {
        table1: [
          { key: 'ku', label: 'Keadaan Umum' },
          { key: 'sadar', label: 'Kesadaran' },
          { key: 'sis', label: 'Sistolik' },
          { key: 'dis', label: 'Distolik' },
          { key: 'nadi', label: 'Frekuensi Nadi' },
          { key: 'nafas', label: 'Frekuensi Nafas' },
          { key: 'suhu', label: 'Suhu ' },
          { key: 'nyeri', label: 'Skor Nyeri ' },
          { key: 'o2', label: 'Saturasi O2 ' },
          { key: 'wkt', label: 'Waktu Pemeriksaan' }
        ]
      },
      items: {
        table1: [
          { ku: 'Sadar sedikit', sadar: 'Dickerson', sis: 'Macdonald', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Larsen', sis: 'Shaw', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Geneva', sis: 'Wilson', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' },
          { ku: 'Sadar sedikit', sadar: 'Jami', sis: 'Carney', dis: '5 hari', nadi: 1, nafas: '5 hari', suhu: '5 hari', nyeri: '5 hari', o2: '5 hari', wkt: '5 hari' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    }
  }
}
</script>
<style>
.emr-tanda-vital .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-tanda-vital .radius-custom-emr {
  border-radius: .5rem;
}
.emr-tanda-vital .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-tanda-vital .bg-input-tekanan-darah {
  background-color: #b8f4ff;
  padding: 3px;
  border-radius: 5px;
}
.emr-tanda-vital .tanda-vital-fc-black {
  color: black;
  font-weight: bold;
}
.emr-tanda-vital .tanda-vital-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-tanda-vital .radius-custom-form-head-tanda-vital {
  border-top-right-radius: .5rem;
}
.emr-tanda-vital .custom-flat-pickr{
    border: 1px solid rgb(233, 233, 233);
    height: 35px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 10px;
    font-size: 12px;
    color: black;
}
.emr-tanda-vital .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-tanda-vital .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
