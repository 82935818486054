<template>
  <div class="emr-kondisisosial">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-kondisisosial">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Kondisi Sosial & Psikologi</span>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <b-row class="align-items-center mr-0">
              <b-col md="2">
                <label class="kondisisosial-fc-black">Kondisi Sosial :</label>
              </b-col>
              <b-col md="10">
                <b-form-checkbox-group
                  v-model="selected"
                  :options="options.kondisisosial"
                ></b-form-checkbox-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 align-items-center">
              <b-col cols="12" class="kondisisosial-fc-black">Lain-lain : </b-col>
              <b-col>
                <b-form-textarea
                  cols="2"
                  no-resize
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0 align-items-center">
              <b-col cols="12" class="kondisisosial-fc-black">Masalah Perilaku : </b-col>
              <b-col>
                <b-form-textarea
                  cols="2"
                  no-resize
                ></b-form-textarea>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i>
              &nbsp; Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span> &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{ height: heightPercent }">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat />
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'KondisiSosial',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      selected: [],
      options: {
        kondisisosial: [
          { text: 'Marah', value: 1 },
          { text: 'Cemas', value: 2 },
          { text: 'Takut', value: 3 },
          { text: 'Kecenderungan Bunuh Diri', value: 4 }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    }
  }
}
</script>
<style>
.emr-kondisisosial .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-kondisisosial .radius-custom-emr {
  border-radius: .5rem;
}
.emr-kondisisosial .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-kondisisosial .radius-custom-form-head-kondisisosial {
  border-top-right-radius: .5rem;
}
.emr-kondisisosial .kondisisosial-fc-black {
  color: black;
  font-weight: bold;
}
.emr-kondisisosial .kondisisosial-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-kondisisosial .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-kondisisosial .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
