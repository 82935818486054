<template>
  <div>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab active>
          <template #title>
            <span>Umum</span>
          </template>
          <div class="p-2">
            <TabUmum />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Fisik
          </template>
          <div class="p-2">
            <Fisik />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Resiko Dekubiktus
          </template>
          <div class="p-2">
            <ResikoDekubiktus />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Kajian Geriatri
          </template>
          <div class="p-2">
            <TabKajianGeriatri />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Verifikator
          </template>
          <div class="p-2">
            <TabVerifikator />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import TabUmum from '../../ComponentSub/RekamMedis/Pemeriksaan/Umum/Tab/Tab.vue'
import TabKajianGeriatri from '../../ComponentSub/RekamMedis/Pemeriksaan/KajianGeriatri/Tab/Tab.vue'
import Fisik from '../../ComponentSub/RekamMedis/Pemeriksaan/Fisik/FisikForm.vue'
import ResikoDekubiktus from '../../ComponentSub/RekamMedis/Pemeriksaan/ResikoDekubitus/ResikoDekubiktusForm.vue'
import TabVerifikator from '../../ComponentSub/RekamMedis/Pemeriksaan/Verifikator/Tab/Tab.vue'

export default {
  name: 'Anamnesis',
  components: {
    TabUmum,
    TabKajianGeriatri,
    Fisik,
    ResikoDekubiktus,
    TabVerifikator
  },
  mounted () {
    xray.index()
  }
}
</script>
