<template>
    <div class="emr-tindakan-icd9-v6">
      <b-row class="justify-content-between m-2">
        <b-col cols="2" class="pl-1 pr-1">
          <div class="border radius-custom-emr w-100 h-100">
            <div class="arrow bg-primary p-2 radius-custom-form-border-tindakan-icd9-v6">
              <b-row>
                <b-col md="6"><span class="h6"><i class="fas fa-notes-medical"></i> &nbsp; Template</span></b-col>
              </b-row>
            </div>
            <div class="custom-scroller p-3">
              <b-row class="mt-2 mr-0 align-items-center">
                <b-col>
                    <div v-for="(a, index) in image" :key="index">
                        <div :class="['mb-3 p-2 text-center', a.active === true ? 'bg-primary' : '']" @click="gambar(index)">
                          <b-img v-if="image[index].name !== ''" :src="image[index].name"
                            alt="profile-img" width="50rem" class="img-fluid" />
                        </div>
                    </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="5" class="pl-1">
          <div class="border radius-custom-emr w-100 h-100">
            <div class="custom-scroller p-3">
              <div class="h-100 w-100">
                <b-img v-if="indexImage !== 0" :src="image[indexImage].name" alt="profile-img" class="img-fluid" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="5" class="pl-1">
          <div class="border radius-custom-emr w-100 h-100">
            <div class="custom-scroller p-3">
              <div class="h-100 w-100">
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </template>
<script>
import { xray } from '../../../../../config/pluginInit'

export default {
  name: 'Penandaan',
  components: {
  },
  data () {
    return {
      image: [
        { name: '', active: false },
        { name: require('../../../../../assets/images/cewek.png'), active: false },
        { name: require('../../../../../assets/images/cowok.png'), active: false }
      ],
      indexImage: 0
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    },
    gambar (index) {
      for (let i = 0; i < this.image.length; i++) {
        this.image[i].active = false
      }
      this.indexImage = index
      this.image[index].active = true
    }
  }
}
</script>
<style>
.begron {
  background-color: green;
}
.emr-tindakan-icd9-v6 .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-tindakan-icd9-v6 .tindakan-icd9-v6-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-tindakan-icd9-v6 .tindakan-icd9-v6-fc-black {
  color: black;
  font-weight: bold;
}
.emr-tindakan-icd9-v6 .radius-custom-emr {
  border-radius: .5rem;
}
.emr-tindakan-icd9-v6 .custom-scroller {
  position:relative;
  overflow-y:auto;
  height:300px;
}
.emr-tindakan-icd9-v6 .radius-custom-form-border-tindakan-icd9-v6 {
  border-top-right-radius: .5rem;
}
.emr-tindakan-icd9-v6 .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-tindakan-icd9-v6 .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-tindakan-icd9-v6 .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
.emr-tindakan-icd9-v6 .table td {
  font-size: 9px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.emr-tindakan-icd9-v6 .table th {
  font-size: 10px;
  font-weight: bold;
}.emr-tindakan-icd9-v6 .multiselect__option {
  border-radius: 2px!important;
  font-size: 12px;
}
.textarea-size-ukuran {
  background: white!important;
  line-height: 20px!important;
}
.hoverable {
  width: 100px;
  height: 100px;
  color: rgb(243, 27, 27);
  cursor: pointer;
}
</style>
