<template>
    <div>
      <b-card no-body>
        <b-tabs pills card>
          <b-tab active>
            <template #title>
              <span>Diagnosa(ICD 10)</span>
            </template>
            <div class="">
              <DiagnosaICD10 />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              Tindakan(ICD 9CM)
            </template>
            <div class="p-2">
              <TindakanICD9 />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
               Diagnosa(ICD 10)V6
            </template>
            <div class="p-2">
              <DiagnosaICD10V6 />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
               Tindakan(ICD 9 CM)V6
            </template>
            <div class="p-2">
              <TindakanICD9CMV6 />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              Penyebab Kematian
            </template>
            <div class="p-2">
              <PenyebabKematian />
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </template>
<script>
import { xray } from '../../../../config/pluginInit'
import DiagnosaICD10 from '../../ComponentSub/RekamMedis/ICD/Diagnosaicd10/Diagnosaicd10Form.vue'
import TindakanICD9 from '../../ComponentSub/RekamMedis/ICD/TindakanICD9/TindakanICD9Form.vue'
import DiagnosaICD10V6 from '../../ComponentSub/RekamMedis/ICD/DiagnosaICD10V6/DiagnosaICD10V6Form.vue'
import TindakanICD9CMV6 from '../../ComponentSub/RekamMedis/ICD/TindakanICD9CMV6/TindakanICD9CMV6Form.vue'
import PenyebabKematian from '../../ComponentSub/RekamMedis/ICD/PenyebabKematian/PenyebabKematianForm.vue'

export default {
  name: 'ICD',
  components: {
    DiagnosaICD10,
    TindakanICD9,
    DiagnosaICD10V6,
    TindakanICD9CMV6,
    PenyebabKematian
  },
  mounted () {
    xray.index()
  }
}
</script>
