<template>
  <div class="emr-riwayat-imunisasi mb-2">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-riwayat-imunisasi">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Riwayat Imunisasi</span>
          </div>
          <div class="custom-scroller p-3" :style="{ height: heightPercent }">
            <b-row class="mr-0 align-items-center">
              <b-col md="12">
                <label class="riwayat-imunisasi-fc-black">Pilih Imunisasi :</label>
              </b-col>
              <b-col md="12">
                <b-form-group class="bg-input-imunisasi">
                  <div class="d-flex flex-row align-items-center">
                    <div><b-form-checkbox-group class="p-4"
                      :options="options.imunisasi"
                    ></b-form-checkbox-group></div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i>
              &nbsp; Catatan Medis</span>
            </span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover"><i class="fas fa-arrow-left" v-b-tooltip.hover.topright="'Buka Riwayat'"></i></span> &nbsp; Lihat
            </span>
          </div>
          <div class="custom-scroller p-2" :style="{ height: heightPercent }">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat />
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'RiwayatImunisasi',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      options: {
        imunisasi: [
          { text: 'BCG', value: 1 },
          { text: 'BPT', value: 2 },
          { text: 'Polio', value: 3 },
          { text: 'Campak', value: 4 },
          { text: 'Hepatitis B', value: 5 },
          { text: 'Pcv', value: 6 },
          { text: 'Varicela', value: 7 },
          { text: 'Typoid', value: 8 },
          { text: 'Meningitis', value: 9 },
          { text: 'Hepatitis A', value: 10 },
          { text: 'Rotavirus', value: 11 },
          { text: 'HIB', value: 12 },
          { text: 'MMR', value: 13 },
          { text: 'Influenza', value: 14 },
          { text: 'Pneumokokus', value: 15 },
          { text: 'HPV', value: 16 },
          { text: 'Tetanus', value: 17 },
          { text: 'Zooster', value: 18 },
          { text: 'Yellow Fever', value: 19 }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    }
  }
}
</script>
<style>
.emr-riwayat-imunisasi .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-riwayat-imunisasi .radius-custom-emr {
  border-radius: .5rem;
}
.emr-riwayat-imunisasi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-riwayat-imunisasi .radius-custom-form-head-riwayat-imunisasi {
  border-top-right-radius: .5rem;
}
.emr-riwayat-imunisasi .riwayat-imunisasi-fc-black {
  color: black;
  font-weight: bold;
}
.emr-riwayat-imunisasi .riwayat-imunisasi-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-riwayat-imunisasi .bg-input-imunisasi {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
}
.emr-riwayat-imunisasi .bg-input-hambatan {
  background-color: rgb(240, 255, 155);
  padding: 5px;
  border-radius: 5px;
}
.emr-riwayat-imunisasi .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-riwayat-imunisasi .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
