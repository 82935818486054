<template>
    <div class="emr-rjd mb-2">
      <b-row class="justify-content-between">
        <b-col cols="12" :md="formLeft" class="pr-1">
          <div class="border radius-custom-emr w-100 h-100">
            <div class="arrow bg-primary p-2 radius-custom-form-head-rjd">
              <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Resiko Jatuh Dewasa</span>
            </div>
            <div class="custom-scroller p-3 rjd-form-white" :style="{ height: heightPercent }">
              <b-row class="mr-0 ml-1 my-bg">
                <b-col md="12"><label class="rja-fc-black">Apakah Ada Riwayat Jatuh Dalam 3 Bulan Tanpa Sebab Apapun :</label></b-col>
                <b-col md="12">
                  <b-form-radio-group
                    @change="onChangeHitung"
                    v-model="form.PARAMETER1"
                    :options="options.parameter1"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mr-0 ml-1 my-bg mt-2">
                <b-col md="12"><label class="rja-fc-black">Apakah Mempunyai Penyakit Penyerta (Diagnosis Sekunder) :</label></b-col>
                <b-col md="12">
                  <b-form-radio-group
                    @change="onChangeHitung"
                    v-model="form.PARAMETER2"
                    :options="options.parameter2"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mr-0 ml-1 my-bg mt-2">
                <b-col md="12"><label class="rja-fc-black">Alat Bantu Berjalan :</label></b-col>
                <b-col md="12">
                  <b-form-radio-group
                    @change="onChangeHitung"
                    v-model="form.PARAMETER3"
                    :options="options.parameter3"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mr-0 ml-1 my-bg mt-2">
                <b-col md="12"><label class="rja-fc-black">Apakah Terpasang Infus / Pemberian Antikoagulan(Heparin) / Obat Lain Yang Mempunyai Efek Samping Resiko Jatuh :</label></b-col>
                <b-col md="12">
                  <b-form-radio-group
                    @change="onChangeHitung"
                    v-model="form.PARAMETER4"
                    :options="options.parameter4"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mr-0 ml-1 my-bg mt-2">
                <b-col md="12"><label class="rja-fc-black">Kondisi Untuk Melakukan Gerakan Berpindah / Mobilisasi :</label></b-col>
                <b-col md="12">
                  <b-form-radio-group
                    @change="onChangeHitung"
                    v-model="form.PARAMETER5"
                    :options="options.parameter5"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mr-0 ml-1 my-bg mt-2">
                <b-col md="12"><label class="rja-fc-black">Bagaimana Status Mental :</label></b-col>
                <b-col md="12">
                  <b-form-radio-group
                    @change="onChangeHitung"
                    v-model="form.PARAMETER6"
                    :options="options.parameter6"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mt-3 mr-0 align-items-center">
                <b-col offset-md="1">
                  <b-card class="border">
                    <b-row no-gutters>
                      <b-col md="1" class="m-3">
                        <h2><i class="fas fa-calculator"></i></h2>
                      </b-col>
                      <b-col md="8" class="text-center">
                        <b-card-body title="Total" class="p-1">
                          <b-card-text>
                            <b-col md="12"><h3>{{ this.form.SKOR }}</h3></b-col>
                          </b-card-text>
                        </b-card-body>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col offset-md="1"><h4><b-badge :variant="badge.bg">{{ badge.msg }}</b-badge></h4></b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" :md="formRight" class="pl-1">
          <div class="border radius-custom-emr w-100 h-100">
            <div class="arrow bg-primary p-2 radius-custom-emr-head">
              <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i>
                &nbsp; Catatan Medis</span>
              </span>
              <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover"><i class="fas fa-arrow-left" v-b-tooltip.hover.topright="'Buka Riwayat'"></i></span> &nbsp; Lihat
              </span>
            </div>
            <div class="custom-scroller p-2" :style="{ height: heightPercent }">
              <div class="d-flex flex-row" v-if="formRight === '4'">
                <div class="h-100">
                  <Riwayat />
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
                <div class="h-100">
                  <p class="h6 text-secondary">Riwayat</p>
                  <i class="fas fa-history text-secondary fa-2x"></i>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'ResikoJatuhDewasa',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      showTotal: 0,
      badge: {
        bg: 'success',
        msg: 'Tidak Beresiko'
      },
      form: {
        PARAMETER1: 0,
        PARAMETER2: 0,
        PARAMETER3: 0,
        PARAMETER4: 0,
        PARAMETER5: 0,
        PARAMETER6: 0,
        SKOR: 0
      },
      options: {
        parameter1: [
          { text: 'Ya', value: 25 },
          { text: 'Tidak', value: 0 }
        ],
        parameter2: [
          { text: 'Ya', value: 15 },
          { text: 'Tidak', value: 0 }
        ],
        parameter3: [
          { text: 'Dibantu Suster / Tidak Menggunakan Alat bantu', value: 0 },
          { text: 'Menggunakan Alat Bantu: Kruk / Tongkat, Kursi Roda', value: 15 },
          { text: 'Merambat Dengan Berpegangan Pada Meja, Kursi(furniture)', value: 30 }
        ],
        parameter4: [
          { text: 'Ya', value: 20 },
          { text: 'Tidak', value: 0 }
        ],
        parameter5: [
          { text: 'Normal / Bed Rest / Mobilisasi', value: 0 },
          { text: 'Lemah', value: 10 },
          { text: 'Ada Keterbatasan Berjalan', value: 20 }
        ],
        parameter6: [
          { text: 'Menyadari Kelemahannya', value: 0 },
          { text: 'Tidak Menyadari Kelemahannya', value: 15 }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    },
    onChangeHitung () {
      let parm1 = this.form.PARAMETER1
      let parm2 = this.form.PARAMETER2
      let parm3 = this.form.PARAMETER3
      let parm4 = this.form.PARAMETER4
      let parm5 = this.form.PARAMETER5
      let parm6 = this.form.PARAMETER6
      let index = parm1 + parm2 + parm3 + parm4 + parm5 + parm6
      this.form.SKOR = index
    }
  },
  computed: {
    total () {
      return this.form.SKOR
    }
  },
  watch: {
    total (values) {
      if (values >= 0 && values <= 24) {
        this.badge.bg = 'success'
        this.badge.msg = 'Tidak Beresiko'
      } else if (values <= 50) {
        this.badge.bg = 'warning'
        this.badge.msg = 'Resiko Rendah'
      } else {
        this.badge.bg = 'danger'
        this.badge.msg = 'Resiko Tinggi'
      }
    }
  }
}
</script>
<style>
.emr-rjd {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
}
.emr-rjd .rjd-form-white {
  background-color: #eff7f9;
  padding: 5px;
  border-radius: 5px;
}
.emr-rjd .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-rjd .my-bg {
  background-color: #ffffff;
  padding: 3px;
  border-radius: 16px;
}
.emr-rjd .radius-custom-emr {
  border-radius: .5rem;
}
.emr-rjd .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-rjd .radius-custom-form-head-rjd {
  border-top-right-radius: .5rem;
}
.emr-rjd .rjd-fc-black {
  color: black;
  font-weight: bold;
}
.emr-rjd .rjd-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-rjd .bg-input-hambatan {
  background-color: rgb(240, 255, 155);
  padding: 5px;
  border-radius: 5px;
}
.emr-rjd .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-rjd .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
