<template>
  <div class="emr-rtka mb-2">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-rtka">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Riwayat Psikososial Anak</span>
          </div>
          <div class="custom-scroller p-3" :style="{ height: heightPercent }">
            <b-row class="mr-0 ml-1 align-items-center my-bg">
              <b-col md="12">
                <label class="rtka-fc-black">Yang Mengasuh :</label>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <div class="d-flex flex-row align-items-center">
                    <div><b-form-radio-group
                      :options="options.pengasuh"
                    ></b-form-radio-group></div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 ml-1 align-items-center my-bg mt-2">
              <b-col md="12">
                <label class="rtka-fc-black">Status Mental & Tingkah Laku :</label>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <div class="d-flex flex-row align-items-center">
                    <div><b-form-radio-group
                      :options="options.statusMental"
                    ></b-form-radio-group></div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i>
              &nbsp; Catatan Medis</span>
            </span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover"><i class="fas fa-arrow-left" v-b-tooltip.hover.topright="'Buka Riwayat'"></i></span> &nbsp; Lihat
            </span>
          </div>
          <div class="custom-scroller p-2" :style="{ height: heightPercent }">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat />
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'RiwayatPsikologiAnak',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      options: {
        pengasuh: [
          { text: 'Orang Tua', value: 1 },
          { text: 'Nenek / Kakek', value: 2 },
          { text: 'Pembantu', value: 3 },
          { text: 'Keluarga Lain', value: 4 },
          { text: 'Panti Asuhan', value: 5 }
        ],
        statusMental: [
          { text: 'Gembira, Tenang, Koperatif', value: 1 },
          { text: 'Ketakutan, Agresif,Hiperaktif', value: 2 },
          { text: 'Gelisah, Murung, Cengeng', value: 3 }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    }
  }
}
</script>
<style>
.emr-rtka {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
}
.emr-rtka .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-rtka .radius-custom-emr {
  border-radius: .5rem;
}
.emr-rtka .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-rtka .radius-custom-form-head-rtka {
  border-top-right-radius: .5rem;
}
.emr-rtka .rtka-fc-black {
  color: black;
  font-weight: bold;
}
.emr-rtka .rtka-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-rtka .bg-input-hambatan {
  background-color: rgb(240, 255, 155);
  padding: 5px;
  border-radius: 5px;
}
.emr-rtka .my-bg {
  background-color: #e4e4e4;
  padding: 3px;
  border-radius: 16px;
}
.emr-rtka .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-rtka .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
