<template>
  <div id="bpjs-integrasi-vclaim">
    <b-card no-body class="p-0 m-0">
      <b-tabs pills card vertical>
        <b-tab active>
          <template #title>
            <i class="fas fa-notes-medical py-2"></i> &nbsp; Rujukan
          </template>
          <div class="p-0">
            <Anamnesis />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <i class="fas fa-stethoscope py-2"></i> &nbsp; SEP
          </template>
          <div class="p-0">
            <Pemeriksaan />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'

export default {
  name: 'BPJS',
  mounted () {
    xray.index()
  }
}
</script>

<style>
.card-body {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0;
}
</style>
