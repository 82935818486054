<template>
  <div class="emr-alergi">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-alergi">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Alergi</span>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <b-row class="align-items-center mr-0">
              <b-col md="2">
                <label class="alergi-fc-black">Alergi :</label>
              </b-col>
              <b-col md="10">
                <multiselect v-model="value.alergi"
                placeholder="Cari alergi ..." label="name" track-by="code"
                :options="options.alergi" :multiple="true" :taggable="true" @tag="addTag"
                ></multiselect>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0">
              <b-col md="12">
                <b-form-textarea
                  rows="4"
                  no-resize
                  size="sm"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i>
              &nbsp; Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span> &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{ height: heightPercent }">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat />
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Alergi',
  components: {
    Riwayat,
    Multiselect
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      selected: [],
      value: {
        alergi: []
      },
      options: {
        alergi: [
          { name: 'Marah', code: 1 },
          { name: 'Cemas', code: 2 },
          { name: 'Takut', code: 3 },
          { name: 'Kecenderungan Bunuh Diri', code: 4 },
          { name: 'Marah', code: 11 },
          { name: 'Cemas', code: 12 },
          { name: 'Takut', code: 13 },
          { name: 'Kecenderungan Bunuh Diri', code: 14 }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.alergi.push(tag)
      this.value.alergi.push(tag)
    }
  }
}
</script>
<style>
.emr-alergi .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-alergi .radius-custom-emr {
  border-radius: .5rem;
}
.emr-alergi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-alergi .radius-custom-form-head-alergi {
  border-top-right-radius: .5rem;
}
.emr-alergi .alergi-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-alergi .alergi-fc-black {
  color: black;
  font-weight: bold;
}
.emr-alergi .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-alergi .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
