import Vue from 'vue'
import moment from 'moment'
import converter from './converter'

Vue.filter('convertToRupiah', (value) => {
  return converter.convertToRupiah(value)
})

Vue.filter('umur', function (value) {
  if (value) {
    return moment(String(value)).fromNow().slice(0, 2) + ' Tahun'
  }
})

Vue.filter('formatDate', function (value) {
  return [
    padTo2Digits(value.getDate()),
    padTo2Digits(value.getMonth() + 1),
    value.getFullYear()
  ].join('-')
})

Vue.filter('norm', function (value) {
  const norm = value?.toString() || ''
  return norm.padStart(7, '0')
})

Vue.filter('newnorm', function (value) {
  const norm = value?.toString() || ''
  return norm.padStart(8, '0')
})

Vue.filter('reverse', function (value) {
  return value.slice().reverse()
})

Vue.filter('umurymd', function (value) {
  if (value) {
    const today = moment(new Date())
    const dob = moment(new Date(value))
    var duration = moment.duration(today.diff(dob))
    return duration.years().toString().padStart(2, '0') + ' thn ' + duration.months().toString().padStart(2, '0') + ' bln ' + duration.days().toString().padStart(2, '0') + ' hr'
  }
})

Vue.filter('money', function (value) {
  if (value) {
    let numberString = value.replace(/[^,\d]/g, '').toString()
    let split = numberString.split(',')
    let sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
    if (ribuan) {
      let separator = sisa ? '.' : ''
      rupiah += separator + ribuan.join('.')
    }
    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
    const final = rupiah
    return final
  }
})

Vue.filter('formatTgl', function (value) {
  let date, month, year
  var tgl = new Date(value)
  date = tgl.getDate()
  month = tgl.getMonth() + 1
  year = tgl.getFullYear()

  date = date
    .toString()
    .padStart(2, '0')

  month = month
    .toString()
    .padStart(2, '0')

  return `${date}-${month}-${year}`
})

function padTo2Digits (num) {
  return num.toString().padStart(2, '0')
}
