<template>
  <div class="emr-kajian-geriatri">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-kajian-geriatri">
            <b-row>
              <b-col md="11"><span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Fungsional</span></b-col>
              <b-col md="1" class="text-end"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buat Fungsional Baru'"><i class="fas fa-plus"></i></span></b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <div class="bg-custom-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Mengendalikan Rangsang Pembuangan Tinja</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.rangsangbuang" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.rangsangbuang" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Mengendalikan Rangsang Berkemih</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.rangsangkemih" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.rangsangkemih" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Penggunaan Jamban, masuk, dan keluar (Lepas, Pakai Celana, Membersihkan, Menyiram)</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.membersihkandiri" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.membersihkandiri" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Membersihkan Diri (Seka Muka, Sisir Rambut, Sikat Gigi)</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.jamban" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.jamban" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Makan</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.makan" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.makan" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Berubah Sikap dari Berbaring ke Duduk</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.sikapberbaring" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.sikapberbaring" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Berpindah / Berjalanan</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.berpindah" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.berpindah" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Memakai Baju</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.baju" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.baju" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Naik Turun Tangga</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.tangga" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.tangga" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Mandi</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.mandi" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"><b-form-select :options="options.mandi" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-2 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Skor</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Sebelum Sakit :</label></b-col>
                    <b-col md="8"> <b-form-input class="kajian-geriatri-form-input bg-white"></b-form-input></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Saat Masuk RS :</label></b-col>
                    <b-col md="8"> <b-form-input class="kajian-geriatri-form-input bg-white"></b-form-input></b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Keterangan :</label></b-col>
                    <b-col md="8"> <b-form-input class="kajian-geriatri-form-input bg-white"></b-form-input></b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row class="align-items-center">
                    <b-col md="4"><label class="kajian-geriatri-fc-black m-0">Keterangan :</label></b-col>
                    <b-col md="8"> <b-form-input class="kajian-geriatri-form-input bg-white"></b-form-input></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="mt-2">
              <b-row class="mr-0">
                <b-col>
                  <div class="text-right">
                    <b-button variant="success" size="sm" squared><i class="fas fa-save"></i>Simpan</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formMiddle" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span></span> &nbsp; <span class="h6"><i class="far fa-edit"></i> &nbsp;  Catatan Medis Kunjungan Terkini</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '3'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> &nbsp; <span class="h6"><i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '3'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'Fungsional',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '7',
      formMiddle: '4',
      formRight: '1',
      options: {
        rangsangbuang: [
          { value: 1, text: 'Tak Terkendali / Tak Teratur (Perlu Pencahan)' },
          { value: 2, text: 'Kadang Tak Terkendali (1x Seminggu)' },
          { value: 3, text: 'Terkendali Teratur' }
        ],
        rangsangkemih: [
          { value: 1, text: 'Tak Terkendali / Pakai Kateter' },
          { value: 2, text: 'Kadang Tak Terkendali (Hanya 1 x 24 jam)' },
          { value: 3, text: 'Mandiri' }
        ],
        membersihkandiri: [
          { value: 1, text: 'Butuh Pertolongan Orang Lain' },
          { value: 2, text: 'Mandiri' }
        ],
        jamban: [
          { value: 1, text: 'Tergantung Pertolongan Orang Lain' },
          { value: 2, text: 'Perlu Pertolongan Pada Beberapa Kegiatan Tetapi Dapat Mengerjakan Sendiri Beberapa Kegiatan Yang Lain' },
          { value: 3, text: 'Mandiri' }
        ],
        makan: [
          { value: 1, text: 'Tidak Mampu' },
          { value: 2, text: 'Perlu Ditolong Memotong Makanan' },
          { value: 3, text: 'Mandiri' }
        ],
        sikapberbaring: [
          { value: 1, text: 'Tidak Mampu' },
          { value: 2, text: 'Perlu Banyak Bantuan Untuk Duduk (2 Orang)' },
          { value: 3, text: 'Bantuan Minimal 1 Orang' },
          { value: 4, text: 'Mandiri' }
        ],
        berpindah: [
          { value: 1, text: 'Tidak Mampu' },
          { value: 2, text: 'Bisa (pindah) dengan Kursi Roda' },
          { value: 3, text: 'Berjalan dengan Bantuan 1 Orang' },
          { value: 4, text: 'Mandiri' }
        ],
        baju: [
          { value: 1, text: 'Tidak Mampu' },
          { value: 2, text: 'Sebagian Dibantu (Seperti Mengancing Baju)' },
          { value: 3, text: 'Mandiri' }
        ],
        tangga: [
          { value: 1, text: 'Tidak Mampu' },
          { value: 2, text: 'Butuh Pertolongan' },
          { value: 3, text: 'Mandiri' }
        ],
        mandi: [
          { value: 1, text: 'Tergantung Orang Lain' },
          { value: 2, text: 'Mandiri' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    }
  }
}
</script>
<style>

.emr-kajian-geriatri .radius-custom-emr {
  border-radius: .5rem;
}
.emr-kajian-geriatri .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-kajian-geriatri .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-kajian-geriatri .radius-custom-form-head-kajian-geriatri {
  border-top-right-radius: .5rem;
}
.emr-kajian-geriatri .radius-custom-form-border-kajian-geriatri {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.emr-kajian-geriatri .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-kajian-geriatri .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
.emr-kajian-geriatri .plus-button {
  transition: all 1s;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.emr-kajian-geriatri .plus-button:hover .hover {
  background-color: rgba(0, 0, 0, 0.671);
  color:white;
}
.emr-kajian-geriatri .form-control {
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background: white;
}
.emr-kajian-geriatri .bg-white {
  background: #ffffff;
}
.emr-kajian-geriatri .my-bg-white {
  background: #ffffff;
}
.emr-kajian-geriatri .custom-control-label {
  margin-top: 0;
}
.emr-kajian-geriatri .multiselect__single {
  font-size: 13px !important;
}
.emr-kajian-geriatri .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-kajian-geriatri .multiselect__option {
  font-size: 13px;
}
.emr-kajian-geriatri .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-kajian-geriatri .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-kajian-geriatri .body {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
}
.emr-kajian-geriatri .bg-custom-1 {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-kajian-geriatri .bg-custom-2 {
  background-color: #faffb8;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-kajian-geriatri .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
}
.emr-kajian-geriatri .kajian-geriatri-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-kajian-geriatri .kajian-geriatri-fc-black {
  color: black;
  font-weight: bold;
}
.emr-kajian-geriatri .table th {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-kajian-geriatri .cstm-cntrl {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-kajian-geriatri .custom-min-height {
  min-height: 100%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-kajian-geriatri .custom-min-height1 {
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-kajian-geriatri .laporan-donwload-pdf {
  min-height: 100%;
}
.emr-kajian-geriatri .custom-flatpicker {
  border: 1px solid rgb(233, 233, 233);
  height: 38px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  border-radius: 4px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}
.emr-kajian-geriatri .my-cursor {
  cursor: pointer;
}
.emr-kajian-geriatri .rounded-custom {
  border-radius: 5px;
  border: 1px solid rgb(209, 209, 209);
}
.emr-kajian-geriatri .pdf-color {
  color: rgb(214, 0, 0);
}
.emr-kajian-geriatri .pdf-color-bg {
  background-color: rgb(214, 0, 0);
}
.emr-kajian-geriatri .excel-color {
  color: green;
}
.emr-kajian-geriatri .kajian-geriatri-color-bg {
  background-color: #089bab;
}
@-webkit-keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
@keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
</style>
