<template>
  <div class="emr-verif-lab">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="bg-primary p-2 radius-custom-form-head-lab">
              <span class="h6"><i class="fas fa-user-check"></i> &nbsp; Form Verifikator - Pemeriksaan Laboratorium</span>
          </div>
          <div class="p-3">
            <b-row>
              <b-col class="lab-fc-black" md="2">Verifikator</b-col>
              <b-col>
                <b-form-group>
                  <b-form-radio-group
                    v-model="selected"
                    :options="options"
                    @change="onChangeVerif"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="isVerif === 1">
              <b-col md="12">
                <b-form-textarea
                  id="textarea"
                  placeholder="Ketik Deskripsi ..."
                  rows="6"
                  no-resize
                  size="sm"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'VerifikatorLab',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      selected: 0,
      options: [
        { text: 'Tidak', value: 0 },
        { text: 'Ya', value: 1 }
      ],
      isVerif: 0
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    },
    onChangeVerif (values) {
      if (values === 1) {
        this.isVerif = 1
      } else {
        this.isVerif = 0
      }
    }
  }
}
</script>
<style>
.emr-verif-lab .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-verif-lab .radius-custom-emr {
  border-radius: .5rem;
}
.emr-verif-lab .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-verif-lab .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-verif-lab .radius-custom-form-head-lab {
  border-top-right-radius: .5rem;
}
.emr-verif-lab .lab-fc-black {
  color: black;
  font-weight: bold;
}
.emr-verif-lab .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-verif-lab .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
