<template>
  <div class="emr-nutrisi">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="bg-primary p-2 radius-custom-form-head-nutrisi">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Nutrisi</span>
          </div>
          <div class="custom-scroller p-3" :style="{ height: heightPercent }">
            <b-row class="mr-0 mb-2">
              <b-col class="nutrisi-fc-black" md="2">Berat Badan (Kg) : </b-col>
              <b-col class="nutrisi-fc-black" md="3">
                <b-form-input class="w-15 nutrisi-form-input" type="number" v-model="defVal.bb"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mr-0 mb-2">
              <b-col class="nutrisi-fc-black" md="2">Tinggi Badan (cm) : </b-col>
              <b-col class="nutrisi-fc-black" md="3">
                <b-form-input class="w-15 nutrisi-form-input" type="number" v-model="defVal.tb"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mr-0 mb-2">
              <b-col class="nutrisi-fc-black" md="2">Index Masa Tubuh : </b-col>
              <b-col class="nutrisi-fc-black" md="3">
                <b-form-input class="w-15 nutrisi-form-input" type="number" v-model="defVal.imt"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mr-0">
              <b-col class="nutrisi-fc-black" md="2">Lingkar Kepala : </b-col>
              <b-col class="nutrisi-fc-black" md="3">
                <b-form-input class="w-15 nutrisi-form-input" type="number" v-model="defVal.lk"></b-form-input>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'Nutrisi',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      defVal: {
        bb: 0,
        tb: 0,
        imt: 0,
        lk: 0
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    }
  }
}
</script>
<style>
.emr-nutrisi .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-nutrisi .radius-custom-emr {
  border-radius: .5rem;
}
.emr-nutrisi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-nutrisi .bg-input-tekanan-darah {
  background-color: #b8f4ff;
  padding: 3px;
  border-radius: 5px;
}
.emr-nutrisi .nutrisi-fc-black {
  color: black;
  font-weight: bold;
}
.emr-nutrisi .nutrisi-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-nutrisi .radius-custom-form-head-nutrisi {
  border-top-right-radius: .5rem;
}
.emr-nutrisi .custom-flat-pickr{
    border: 1px solid rgb(233, 233, 233);
    height: 35px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 10px;
    font-size: 12px;
    color: black;
  }
.emr-nutrisi .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-nutrisi .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
