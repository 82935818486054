<template>
  <div class="emr-rja mb-2">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-rja">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Resiko Jatuh Anak</span>
          </div>
          <div class="custom-scroller p-3 rja-form-white" :style="{ height: heightPercent }">
            <b-row class="mr-0 ml-1 my-bg">
              <b-col md="12"><label class="rja-fc-black">Umur :</label></b-col>
              <b-col md="12">
                <b-form-radio-group
                  @change="onChangeHitung"
                  v-model="form.UMUR"
                  :options="options.umur"
                ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 ml-1 my-bg mt-2">
              <b-col md="12"><label class="rja-fc-black">Jenis Kelamin :</label></b-col>
              <b-col md="12">
                <b-form-radio-group
                  @change="onChangeHitung"
                  v-model="form.JENIS_KELAMIN"
                  :options="options.jenisKelamin"
                ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 ml-1 my-bg mt-2">
              <b-col md="12"><label class="rja-fc-black">Diagnosis :</label></b-col>
              <b-col md="12">
                <b-form-radio-group
                  @change="onChangeHitung"
                  v-model="form.DIAGNOSIS"
                  :options="options.diagnosis"
                  stacked
                ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 ml-1 my-bg mt-2">
              <b-col md="12"><label class="rja-fc-black">Gangguan Kognitif :</label></b-col>
              <b-col md="12">
                <b-form-radio-group
                  @change="onChangeHitung"
                  v-model="form.KOGNITIF"
                  :options="options.gangguanKognitif"
                  stacked
                ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 ml-1 my-bg mt-2">
              <b-col md="12"><label class="rja-fc-black">Lingkungan :</label></b-col>
              <b-col md="12">
                <b-form-radio-group
                    @change="onChangeHitung"
                    v-model="form.LINGKUNGAN"
                    :options="options.lingkungan"
                    stacked
                  ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 ml-1 my-bg mt-2">
              <b-col md="12"><label class="rja-fc-black">Respon OP :</label></b-col>
              <b-col md="12">
                <b-form-radio-group
                  @change="onChangeHitung"
                  v-model="form.RESPONOP"
                  :options="options.responOP"
                ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mr-0 ml-1 my-bg mt-2">
              <b-col md="12"><label class="rja-fc-black">Penggunaan Obat :</label></b-col>
              <b-col md="12">
                <b-form-radio-group
                  @change="onChangeHitung"
                  v-model="form.PENGGUNAAN_OBAT"
                  :options="options.penggunaanObat"
                  stacked
                ></b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mt-3 mr-0 align-items-center">
              <b-col offset-md="1">
                <b-card class="border">
                  <b-row no-gutters>
                    <b-col md="1" class="m-3">
                      <h2><i class="fas fa-calculator"></i></h2>
                    </b-col>
                    <b-col md="8" class="text-center">
                      <b-card-body title="Total" class="p-1">
                        <b-card-text>
                          <b-col md="12"><h3>{{ this.form.SKOR }}</h3></b-col>
                        </b-card-text>
                      </b-card-body>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col offset-md="1"><h4><b-badge :variant="badge.bg">{{ badge.msg }}</b-badge></h4></b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i>
              &nbsp; Catatan Medis</span>
            </span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover"><i class="fas fa-arrow-left" v-b-tooltip.hover.topright="'Buka Riwayat'"></i></span> &nbsp; Lihat
            </span>
          </div>
          <div class="custom-scroller p-2" :style="{ height: heightPercent }">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat />
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'ResikoJatuhAnak',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      showTotal: 0,
      badge: {
        bg: 'success',
        msg: 'Tidak Ada Resiko'
      },
      form: {
        UMUR: 0,
        JENIS_KELAMIN: 0,
        DIAGNOSIS: 0,
        KOGNITIF: 0,
        LINGKUNGAN: 0,
        RESPONOP: 0,
        PENGGUNAAN_OBAT: 0,
        SKOR: 0
      },
      penerjemah: {
        input: true,
        inputColor: 'bg-secondary',
        inputVal: ''
      },
      selected: {
        informasi: 0,
        hambatan: 0,
        penerjemah: 0
      },
      options: {
        umur: [
          { text: '< 3 Tahum', value: 4 },
          { text: '3-7 Tahun', value: 3 },
          { text: '8-13 Tahun', value: 2 },
          { text: '14-15 Tahun', value: 1 }
        ],
        jenisKelamin: [
          { text: 'Laki-Laki', value: 2 },
          { text: 'Perempuan', value: 1 }
        ],
        diagnosis: [
          { text: 'Kelainan Neurologi', value: 4 },
          { text: 'Gangguan Oksigenasi (Pernafasan, Anemia, Dehidrasi, Anoroksia, Sinkop, Sakit Kepala)', value: 3 },
          { text: 'Kelainan Fisik / Kelainan Psikis', value: 2 },
          { text: 'Diagnosis Lain', value: 1 }
        ],
        gangguanKognitif: [
          { text: 'Tidak Memahami Keterbatasan', value: 3 },
          { text: 'Lupa Keterbatasan', value: 2 },
          { text: 'Orientasi Terhadap Kelemahan', value: 1 }
        ],
        lingkungan: [
          { text: 'Riwayat jatuh dari tempat tidur saat bayi - anak', value: 4 },
          { text: 'Menggunakan Alat Bantu (Box / Mabel)', value: 3 },
          { text: 'Pasien Berada di Tempat Tidur', value: 2 },
          { text: 'Pasien Berada Di Luar Area Ruang Perawatan', value: 1 }
        ],
        responOP: [
          { text: '< 24 Jam', value: 3 },
          { text: '< 48 Jam', value: 2 },
          { text: '> 48 Jam', value: 1 }
        ],
        penggunaanObat: [
          { text: 'Obat Sedaktif, Hipnotik, Barbiturat, Phenotiazin', value: 3 },
          { text: 'Salah Satu Obat Di Atas', value: 2 },
          { text: 'Tidak Menyadari Kelamahan', value: 1 }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    },
    onChangeHitung () {
      let parm1 = this.form.UMUR
      let parm2 = this.form.JENIS_KELAMIN
      let parm3 = this.form.DIAGNOSIS
      let parm4 = this.form.KOGNITIF
      let parm5 = this.form.LINGKUNGAN
      let parm6 = this.form.RESPONOP
      let parm7 = this.form.PENGGUNAAN_OBAT
      let index = parm1 + parm2 + parm3 + parm4 + parm5 + parm6 + parm7
      this.form.SKOR = index
    }
  },
  computed: {
    total () {
      return this.form.SKOR
    }
  },
  watch: {
    total (values) {
      if (values >= 7 && values <= 11) {
        this.badge.bg = 'warning'
        this.badge.msg = 'Resiko Jatuh Rendah'
      } else if (values >= 12) {
        this.badge.bg = 'danger'
        this.badge.msg = 'Resiko Jatuh Tinggi'
      } else {
        this.badge.bg = 'success'
        this.badge.msg = 'Tidak Ada Resiko'
      }
    }
  }
}
</script>
<style>
.emr-rja {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
}
.emr-rja .rja-form-white {
  background-color: #eff7f9;
  padding: 5px;
  border-radius: 5px;
}
.emr-rja .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-rja .radius-custom-emr {
  border-radius: .5rem;
}
.emr-rja .my-bg {
  background-color: #ffffff;
  padding: 3px;
  border-radius: 16px;
}
.emr-rja .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-rja .radius-custom-form-head-rja {
  border-top-right-radius: .5rem;
}
.emr-rja .rja-fc-black {
  color: black;
  font-weight: bold;
}
.emr-rja .rja-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-rja .bg-input-imut {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
}
.emr-rja .bg-input-hambatan {
  background-color: rgb(240, 255, 155);
  padding: 5px;
  border-radius: 5px;
}
.emr-rja .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-rja .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
