<template>
  <div class="emr-frality">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-frality">
            <b-row>
              <b-col md="11"><span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Frality (Penghitungan nya masih kurang paham rumusnya)</span></b-col>
              <b-col md="1" class="text-end"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buat Frality Baru'"><i class="fas fa-plus"></i></span></b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <div class="bg-custom-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Fatigue</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0 align-items-center">
                <b-col md="12"><label class="frality-fc-black m-0">Seberapa sering dalam empat minggu anda merasa kelelahan ?</label></b-col>
                <b-col md="8"><b-form-select @change="onChangeFatigue" v-model="defVal.fatigue" :options="options.fatigue" size="sm"></b-form-select></b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Resistance</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-col md="12"><label class="frality-fc-black m-0">Dengan diri sendiri/tanpa bantuan alat, pakah anda mengalami kesulitan untuk naik 10 anak tangga dan tanpa istirahat diantaranya ?</label></b-col>
                </b-col>
                <b-col md="12">
                  <b-form-radio-group
                    class="ml-3"
                    v-model="defVal.resistance"
                    :options="options.pilihan"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Ambulasi (Usaha Berjalan)</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-col md="12"><label class="frality-fc-black m-0">Dengan diri sendiri/tanpa bantuan alat, apakah anda mengalami kesulitan berjalan kira-kira sejauh 100 - 200 meter ?</label></b-col>
                </b-col>
                <b-col md="12">
                  <b-form-radio-group
                    class="ml-3"
                    v-model="defVal.ambulasi"
                    :options="options.pilihan"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Illness (Penyakit lebih dari 4)</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-col md="12"><label class="frality-fc-black m-0">Partisipan ditanya, apakah dokter pernah mengatakan kepada anda tentang penyakit anda ?</label></b-col>
                </b-col>
                <b-col md="12"><label class="frality-fc-black m-0 ml-3">Penyakit yang disebut :</label></b-col>
                <b-col md="12">
                  <b-form-checkbox-group
                    class="ml-3"
                    @change="onChangeIllness"
                    :options="options.illness"
                  ></b-form-checkbox-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Loss of Weight (Hilngnya berat badan) -belum tau rumusnya-</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0 align-items-center">
                <b-col md="4"><label class="frality-fc-black m-0">Berat badan 1 tahun lalu (kg) :</label></b-col>
                <b-col md="3"><b-form-input type="number" class="frality-form-input bg-white"></b-form-input></b-col>
              </b-row>
              <b-row class="mt-2 mr-0 align-items-center">
                <b-col md="4"><label class="frality-fc-black m-0">Berat badan sekarang (kg) :</label></b-col>
                <b-col md="3"><b-form-input type="number" class="frality-form-input bg-white"></b-form-input></b-col>
              </b-row>
              <b-row class="mt-2 mr-0 align-items-center">
                <b-col md="4"><label class="frality-fc-black m-0">Keadaan Umum :</label></b-col>
                <b-col md="3"><b-form-input type="number" class="frality-form-input" placeholder="%" readonly></b-form-input></b-col>
              </b-row>
              <b-row class="mt-2 mr-0 align-items-center">
                <b-col md="4"><label class="frality-fc-black m-0">Keadaan Umum :</label></b-col>
                <b-col md="3"><b-form-input type="number" class="frality-form-input" readonly></b-form-input></b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row class="align-items-center">
                <b-col md="12"><h4><label class="frality-fc-black">HASIL</label></h4></b-col>
                <b-col md="3"><b-card class="border">
                    <b-row no-gutters>
                      <b-col md="2" class="m-3">
                        <h2><i class="fas fa-poll-h"></i></h2>
                      </b-col>
                      <b-col md="4" class="text-center">
                        <b-card-body title="Total">
                          <b-card-text>
                            <h3> {{ defVal.totalFrality }} </h3>
                          </b-card-text>
                        </b-card-body>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col md="8"><h3><b-badge :variant="badge.color">{{ badge.keterangan }}</b-badge></h3></b-col>
              </b-row>
            </div>
            <div class="mt-1">
              <b-row class="mr-0">
                <b-col>
                  <div class="text-right">
                    <b-button variant="success" size="sm" squared><i class="fas fa-save"></i>Simpan</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formMiddle" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span></span> &nbsp; <span class="h6"><i class="far fa-edit"></i> &nbsp;  Catatan Medis Kunjungan Terkini</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '3'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> &nbsp; <span class="h6"><i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '3'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'Frality',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '7',
      formMiddle: '4',
      formRight: '1',
      badge: {
        color: 'success',
        keterangan: 'Fit / Robust'
      },
      defVal: {
        fatigue: 0,
        fatigueCalc: 0,
        illnessCalc: 0,
        resistance: 0,
        ambulasi: 0,
        totalFrality: 0
      },
      options: {
        fatigue: [
          { value: 1, text: 'Sepanjang Waktu' },
          { value: 2, text: 'Sebagian besar waktu' },
          { value: 3, text: 'Kadang-kadang' },
          { value: 4, text: 'Jarang' }
        ],
        pilihan: [
          { value: 1, text: 'Ya' },
          { value: 0, text: 'Tidak' }
        ],
        illness: [
          { value: 1, text: 'Hipertensi' },
          { value: 2, text: 'Diabetes' },
          { value: 3, text: 'Kanker (selain kulit kecil)' },
          { value: 4, text: 'Penyakit paru kronis' },
          { value: 5, text: 'Serangan jantung' },
          { value: 6, text: 'Gagal jantung kongstif' },
          { value: 7, text: 'Nyeri dada' },
          { value: 8, text: 'Asma' },
          { value: 9, text: 'Nyeri sendi' },
          { value: 10, text: 'Stroke' },
          { value: 11, text: 'Penyakit ginjal' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    },
    onChangeFatigue (values) {
      if (values === 1 || values === 2) {
        this.defVal.fatigueCalc = 1
      } else {
        this.defVal.fatigueCalc = 0
      }
    },
    onChangeIllness (values) {
      if (values.length >= 4) {
        this.defVal.illnessCalc = 1
      } else {
        this.defVal.illnessCalc = 0
      }
    }
  },
  computed: {
    totalFrality () {
      return this.defVal.fatigueCalc + this.defVal.illnessCalc + this.defVal.resistance + this.defVal.ambulasi
    }
  },
  watch: {
    totalFrality (values) {
      this.defVal.totalFrality = values
      if (values === 0) {
        this.badge.color = 'success'
        this.badge.keterangan = 'Fit / Robust'
      } else if (values <= 2) {
        this.badge.color = 'warning'
        this.badge.keterangan = 'Pre Frail'
      } else {
        this.badge.color = 'danger'
        this.badge.keterangan = 'Frail'
      }
    }
  }
}
</script>
<style>
.emr-frality .radius-custom-emr {
  border-radius: .5rem;
}
.emr-frality .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-frality .label-input {
  font-weight: bold;
}
.emr-frality .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-frality .radius-custom-form-head-frality {
  border-top-right-radius: .5rem;
}
.emr-frality .radius-custom-form-border-frality {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.emr-frality .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-frality .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
.emr-frality .plus-button {
  transition: all 1s;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.emr-frality .plus-button:hover .hover {
  background-color: rgba(0, 0, 0, 0.671);
  color:white;
}
.emr-frality .form-control {
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background: white;
}
.emr-frality .bg-white {
  background: #ffffff;
}
.emr-frality .custom-control-label {
  margin-top: 0;
}
.emr-frality .multiselect__single {
  font-size: 13px !important;
}
.emr-frality .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-frality .multiselect__option {
  font-size: 13px;
}
.emr-frality .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-frality .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-frality .body {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
}
.emr-frality .bg-custom-1 {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-frality .bg-custom-2 {
  background-color: #faffb8;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-frality .bg-custom-3 {
  background-color: #f4ff5a;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-frality .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
}
.emr-frality .frality-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-frality .frality-fc-black {
  color: black;
  font-weight: bold;
}
.emr-frality .table th {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-frality .cstm-cntrl {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-frality .custom-min-height {
  min-height: 100%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-frality .custom-min-height1 {
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-frality .custom-flatpicker {
  border: 1px solid rgb(233, 233, 233);
  height: 38px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  border-radius: 4px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}
.emr-frality .my-cursor {
  cursor: pointer;
}
.emr-frality .rounded-custom {
  border-radius: 5px;
  border: 1px solid rgb(209, 209, 209);
}
@-webkit-keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
@keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
</style>
