<template>
  <div class="emr-nutrisi">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-nutrisi">
            <b-row>
              <b-col md="11"><span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Instrumental ADL</span></b-col>
              <b-col md="1" class="text-end"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buat Nutrisi Baru'"><i class="fas fa-plus"></i></span></b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <div class="bg-custom-1 mb-2">
              <b-row>
                <b-col md="12"><h5><label class="nutrisi-fc-black">PENAPISAN (SCREENING)</label></h5></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>A.</b> Apakah ada penurunan asupan makanan dalam jangka waktu 3 bulan oleh karena kehilangan nafsu makan, masalah pencernaan, kesulitan menelan, atau mengunah ?</h4></b-col>
                <b-col md="12"><b-form-select :options="options.screening.makanan" v-model="defVal.screening.makanan" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>B.</b> Penurunan berat badan dalam waktu 3 bulan terakhir : </h4></b-col>
                <b-col md="12"><b-form-select :options="options.screening.beratbadan" v-model="defVal.screening.beratbadan" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>C.</b> Mobilitas : </h4></b-col>
                <b-col md="12"><b-form-select :options="options.screening.mobilitas" v-model="defVal.screening.mobilitas" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>D.</b> Menderita stress psikologis / penyakit akut dalam 3 bulan terakhir ?  : </h4></b-col>
                <b-col md="4">
                  <b-form-radio-group class="ml-4"
                    v-model="defVal.screening.psikologis"
                    :options="options.screening.psikologis"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>E.</b> Masalah Neuropsikologis : </h4></b-col>
                <b-col md="12"><b-form-select :options="options.screening.neuro" v-model="defVal.screening.neuro" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>F.</b> Masalah Neuropsikologis : </h4></b-col>
                <b-col md="12"><b-form-select :options="options.screening.imt" v-model="defVal.screening.imt" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="align-items-center">
                <b-col offset-md="10">
                  <b-card class="border">
                    <b-row no-gutters>
                      <b-col md="2" class="m-3">
                        <h2><i class="fas fa-calculator"></i></h2>
                      </b-col>
                      <b-col md="6" class="text-center">
                        <b-card-body title="Total">
                          <b-card-text>
                            <h3> {{ this.defVal.totalScreening }} </h3>
                          </b-card-text>
                        </b-card-body>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mb-2">
              <b-row>
                <b-col md="12"><h5><label class="nutrisi-fc-black">PENGKAJIAN (ASSESSMENT)</label></h5></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>G. </b>Hidup mandiri, tidak tergantung orang lain (bukan di rumah sakit/panti werdha) : </h4></b-col>
                <b-col md="12">
                  <b-form-radio-group class="ml-4"
                    v-model="defVal.assessment.hidup"
                    :options="options.assessment.pilihan"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>H. </b>Minum obat lebih dari 3 macam dalam 1 hari : </h4></b-col>
                <b-col md="12">
                  <b-form-radio-group class="ml-4"
                    v-model="defVal.assessment.minum"
                    :options="options.assessment.pilihan"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>I. </b>Terdapat ulkus dekubiktus/luka tekan atau luka di kulit : </h4></b-col>
                <b-col md="12">
                  <b-form-radio-group class="ml-4"
                    v-model="defVal.assessment.ulkus"
                    :options="options.assessment.pilihan"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>J.</b> Berapa kali pasien makan lengkap dalam 1 hari : </h4></b-col>
                <b-col md="12"><b-form-select :options="options.assessment.makan" v-model="defVal.assessment.makan" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4><b>K. </b>Komsumsi BM tertentu yang diketahui sebagai BM sumber protein (asupan protein) :</h4></b-col>
                <b-col md="12">
                  <b-form-checkbox-group
                  v-model="defVal.assessment.konsumsi"
                  :options="options.assessment.konsumsi"
                  @change="onChangeKonsumsi"
                  stacked
                  ></b-form-checkbox-group>
                </b-col>
                <b-col md="2" class="mt-2"><b-form-input class="nutrisi-form-input bg-white" v-model="defVal.konsumsiVal"
                  readonly></b-form-input></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>L. </b>Adakah mengkonsumsi 2 penukar atau lebih buah atau sayuran perhari ?</h4></b-col>
                <b-col md="12">
                  <b-form-radio-group class="ml-4"
                    v-model="defVal.assessment.penukar"
                    :options="options.assessment.pilihan"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>M.</b> Berapa banyak cairan (air, jus, kopi, teh, susu, ...) yang diminum setaip hari ?</h4></b-col>
                <b-col md="12"><b-form-select :options="options.assessment.cairan" v-model="defVal.assessment.cairan" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>N.</b> Cara makan :</h4></b-col>
                <b-col md="12"><b-form-select :options="options.assessment.caramakan" v-model="defVal.assessment.caramakan" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>O.</b> Pandangan pasien terhadap status gizinya :</h4></b-col>
                <b-col md="12"><b-form-select :options="options.assessment.pandangan" v-model="defVal.assessment.pandangan" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>P.</b> Dibandingkan dengan orang lain yang seumuran, bagaimana pasien melihat status kesehatannya ?</h4></b-col>
                <b-col md="12"><b-form-select :options="options.assessment.banding" v-model="defVal.assessment.banding" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>Q.</b> Lingkar lengan atas (LLA) dalam cm :</h4></b-col>
                <b-col md="12"><b-form-select :options="options.assessment.lengan" v-model="defVal.assessment.lengan" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="12"><h4 class="mb-1"><b>R.</b> Lingkar betis (LB) dalam cm :</h4></b-col>
                <b-col md="12"><b-form-select :options="options.assessment.betis" v-model="defVal.assessment.betis" size="sm"></b-form-select></b-col>
              </b-row>
              <b-row class="align-items-center">
                <b-col offset-md="10">
                  <b-card class="border">
                    <b-row no-gutters>
                      <b-col md="2" class="m-3">
                        <h2><i class="fas fa-calculator"></i></h2>
                      </b-col>
                      <b-col md="6" class="text-center">
                        <b-card-body title="Total">
                          <b-card-text>
                            <h3> {{ this.defVal.totalAssessment }} </h3>
                          </b-card-text>
                        </b-card-body>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1">
              <b-row class="align-items-center">
                <b-col md="12"><h4><label class="nutrisi-fc-black">HASIL</label></h4></b-col>
                <b-col md="3"><b-card class="border">
                    <b-row no-gutters>
                      <b-col md="2" class="m-3">
                        <h2><i class="fas fa-poll-h"></i></h2>
                      </b-col>
                      <b-col md="6" class="text-center">
                        <b-card-body title="Total">
                          <b-card-text>
                            <h3> {{ this.defVal.hasilVal }} </h3>
                          </b-card-text>
                        </b-card-body>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col md="8"><h3><b-badge :variant="badge.color">{{ badge.keterangan }}</b-badge></h3></b-col>
              </b-row>
            </div>
            <div class="mt-2">
              <b-row class="mr-0">
                <b-col>
                  <div class="text-right">
                    <b-button variant="success" size="sm" squared><i class="fas fa-save"></i>Simpan</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formMiddle" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span></span> &nbsp; <span class="h6"><i class="far fa-edit"></i> &nbsp;  Catatan Medis Kunjungan Terkini</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '3'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> &nbsp; <span class="h6"><i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '3'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'Nutrisi',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '7',
      formMiddle: '4',
      formRight: '1',
      badge: {
        color: 'success',
        keterangan: 'Tidak Malnutrisi'
      },
      defVal: {
        totalScreening: 0,
        totalAssessment: 0,
        hasilVal: 0,
        konsumsiVal: 0,
        screening: {
          makanan: 0,
          beratbadan: 0,
          mobilitas: 0,
          psikologis: 0,
          neuro: 0,
          imt: 0
        },
        assessment: {
          hidup: 0,
          minum: 0,
          ulkus: 0,
          makan: 0,
          konsumsi: [],
          penukar: 0,
          cairan: 0,
          caramakan: 0,
          pandangan: 0,
          banding: 0,
          lengan: 0,
          betis: 0
        }
      },
      options: {
        screening: {
          makanan: [
            { value: 1, text: 'Nafsu makan yang sangat berkurang' },
            { value: 2, text: 'Nafsu makan sedikit berkurang (sedang)' },
            { value: 3, text: 'Nafsu makan biasa' }
          ],
          beratbadan: [
            { value: 1, text: 'Penurunan berat badan lebih dari 3 kg' },
            { value: 2, text: 'Tidak tahu' },
            { value: 3, text: 'Penurunan berat badan 1-3 kg' },
            { value: 4, text: 'Tidak ada penurunan' }
          ],
          mobilitas: [
            { value: 1, text: 'Harus berbaring ditempat tidur / menggunakan kursi roda' },
            { value: 2, text: 'Bisa keluar dari tempat tidur / kursi roda, tetapi tidak bisa keluar rumah' },
            { value: 3, text: 'Bisa keluar rumah' }
          ],
          psikologis: [
            { value: 0, text: 'Tidak' },
            { value: 1, text: 'Ya' }
          ],
          neuro: [
            { value: 1, text: 'Dimensia berat atau depresi berat' },
            { value: 2, text: 'Dimensia ringan' },
            { value: 3, text: 'Tidak ada masalah psikologis' }
          ],
          imt: [
            { value: 1, text: 'IMT < 19' },
            { value: 2, text: 'IMT 19 - < 21' },
            { value: 3, text: 'IMT 21 - < 23' },
            { value: 4, text: 'IMT 23 atau lebih' }
          ]
        },
        assessment: {
          pilihan: [
            { value: 0, text: 'Tidak' },
            { value: 1, text: 'Ya' }
          ],
          makan: [
            { value: 1, text: '1 kali' },
            { value: 2, text: '2 kali' },
            { value: 3, text: '3 kali' }
          ],
          konsumsi: [
            { value: 1, text: 'Setidaknya 1 penukaran dari produk susu (susu, keju) per hari' },
            { value: 2, text: 'Dua penukar atau lebih dari kacang-kacangan atau telur perminggu' },
            { value: 3, text: 'Daging, ikan, atau unggas tiap hari' }
          ],
          cairan: [
            { value: 1, text: 'Kurang dari 3 gelas' },
            { value: 2, text: '3 - 5 gelas' },
            { value: 3, text: 'Lebih dari 5 gelas' }
          ],
          caramakan: [
            { value: 1, text: 'Tidak dapat makan tanpa bantuan' },
            { value: 2, text: 'Makan sendiri dengan sedikit kesulitas' },
            { value: 3, text: 'Dapat makan sendiri tanpa masalah' }
          ],
          pandangan: [
            { value: 1, text: 'Merasa dirinya kekurangan makan/kurang gizi' },
            { value: 2, text: 'Tidak dapat menilai/tidak yakin akan status gizinya' },
            { value: 3, text: 'Merasa tidak ada masalah dengan status gizinya' }
          ],
          banding: [
            { value: 1, text: 'Tidak sebaik mereka' },
            { value: 2, text: 'Tidak tahu' },
            { value: 3, text: 'Sama baik' },
            { value: 4, text: 'Lebih baik' }
          ],
          lengan: [
            { value: 1, text: 'LLA < 21' },
            { value: 2, text: 'LLA 21 - < 22' },
            { value: 3, text: 'LLA >= 22' }
          ],
          betis: [
            { value: 1, text: 'LB < 31' },
            { value: 2, text: 'LB >= 31' }
          ]
        }
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  computed: {
    totalScreening () {
      return this.defVal.screening.makanan + this.defVal.screening.beratbadan + this.defVal.screening.mobilitas + this.defVal.screening.psikologis + this.defVal.screening.neuro + this.defVal.screening.imt
    },
    totalAssessment () {
      return this.defVal.assessment.hidup + this.defVal.assessment.minum + this.defVal.assessment.ulkus + this.defVal.assessment.makan + this.defVal.konsumsiVal + this.defVal.assessment.cairan + this.defVal.assessment.caramakan + this.defVal.assessment.pandangan + this.defVal.assessment.banding + this.defVal.assessment.lengan + this.defVal.assessment.betis
    },
    totalHasil () {
      return this.defVal.totalScreening + this.defVal.totalAssessment
    }
  },
  watch: {
    totalScreening (values) {
      this.defVal.totalScreening = values
    },
    totalAssessment (values) {
      this.defVal.totalAssessment = values
    },
    totalHasil (values) {
      this.defVal.hasilVal = values
      if (values <= 6) {
        this.badge.color = 'success'
        this.badge.keterangan = 'Tidak Malnutrisi'
      } else if (values <= 18.5) {
        this.badge.color = 'warning'
        this.badge.keterangan = 'Beresiko Malnutrisi'
      } else {
        this.badge.color = 'danger'
        this.badge.keterangan = 'Malnutrisi'
      }
    }
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    },
    onChangeKonsumsi (values) {
      if (values.length === 3) {
        this.defVal.konsumsiVal = 1
      } else if (values.length === 2) {
        this.defVal.konsumsiVal = 0.5
      } else {
        this.defVal.konsumsiVal = 0
      }
    }
  }
}
</script>
<style>
.emr-nutrisi .radius-custom-emr {
  border-radius: .5rem;
}
.emr-nutrisi .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-nutrisi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-nutrisi .radius-custom-form-head-nutrisi {
  border-top-right-radius: .5rem;
}
.emr-nutrisi .radius-custom-form-border-nutrisi {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.emr-nutrisi .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-nutrisi .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
.emr-nutrisi .plus-button {
  transition: all 1s;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.emr-nutrisi .plus-button:hover .hover {
  background-color: rgba(0, 0, 0, 0.671);
  color:white;
}
.emr-nutrisi .form-control {
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background: white;
}
.emr-nutrisi .bg-white {
  background: #ffffff;
}
.emr-nutrisi .custom-control-label {
  margin-top: 0;
}
.emr-nutrisi .multiselect__single {
  font-size: 13px !important;
}
.emr-nutrisi .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-nutrisi .multiselect__option {
  font-size: 13px;
}
.emr-nutrisi .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-nutrisi .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-nutrisi .body {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
}
.emr-nutrisi .bg-custom-1 {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-nutrisi .bg-custom-2 {
  background-color: #d2ff0848;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-nutrisi .bg-custom-2 {
  background-color: #faffb8;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-nutrisi .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
}
.emr-nutrisi .nutrisi-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-nutrisi .nutrisi-fc-black {
  color: black;
  font-weight: bold;
}
.emr-nutrisi .table th {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-nutrisi .cstm-cntrl {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-nutrisi .custom-min-height {
  min-height: 100%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-nutrisi .custom-min-height1 {
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-nutrisi .laporan-donwload-pdf {
  min-height: 100%;
}
.emr-nutrisi .custom-flatpicker {
  border: 1px solid rgb(233, 233, 233);
  height: 38px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  border-radius: 4px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}
.emr-nutrisi .my-cursor {
  cursor: pointer;
}
.emr-nutrisi .rounded-custom {
  border-radius: 5px;
  border: 1px solid rgb(209, 209, 209);
}
.emr-nutrisi .pdf-color {
  color: rgb(214, 0, 0);
}
.emr-nutrisi .pdf-color-bg {
  background-color: rgb(214, 0, 0);
}
.emr-nutrisi .excel-color {
  color: green;
}
.emr-nutrisi .nutrisi-color-bg {
  /* background-color: rgb(83, 31, 76); */
  background-color: #089bab;
}
@-webkit-keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
@keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
</style>
