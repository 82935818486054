<template>
  <div>
    <b-card end>
      <b-tabs pills end>
        <b-tab title="Anak"><Anak class="mb-2" /></b-tab>
        <b-tab title="Dewasa"><Dewasa class="mb-2" /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { xray } from '../../../../../../config/pluginInit'
import Anak from './Anak/index.vue'
import Dewasa from './Dewasa/index.vue'

export default {
  name: 'Tab',
  components: {
    Anak,
    Dewasa
  },
  mounted () {
    xray.index()
  }
}
</script>
