<template>
    <div class="emr-diagnosa-icd10">
      <b-row class="justify-content-between m-2">
        <b-col cols="12" :lg="formLeft" class="pl-1 pr-1">
          <div class="border radius-custom-emr w-100 h-100">
            <div class="arrow bg-primary p-2 radius-custom-form-border-diagnosa-icd10">
              <span class="h6"><i class="fas fa-file-medical-alt"></i>&nbsp; Daftar Input Diagnosa (ICD 10)</span>
            </div>
            <div class="custom-scroller p-2" :style="{height: heightPercent}">
              <b-row class="mr-0 align-items-center">
                <b-col md="12">
                  <b-table class="mb-0"
                  ref="table"
                  bordered
                  hover
                  striped
                  small :items="items.table1" :fields="fields.table1">
                  </b-table>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" :lg="formMiddle" class="pl-1 pr-1">
          <div class="border radius-custom-emr w-100 h-100">
            <div class="arrow bg-primary p-2 radius-custom-form-border-diagnosa-icd10">
              <span class="h6"><i class="fas fa-pen-alt"></i>&nbsp; Form Diagnosa (ICD 10)</span>
            </div>
            <div class="custom-scroller p-2" :style="{height: heightPercent}">
              <b-row class="mr-0 align-items-center">
                <b-col md="3" class="diagnosa-icd10-fc-black">Kategori :</b-col>
                <b-col md="8">
                  <b-form-checkbox-group
                    v-model="selected.primary"
                    :options="options.kategori"
                  ></b-form-checkbox-group>
                </b-col>
                <b-col md="3" class="diagnosa-icd10-fc-black">Kode :</b-col>
                <b-col md="8">
                  <b-form-group>
                    <multiselect :show-labels="false" v-model="selected.selectkode"
                    :limit="3" placeholder="Masukkan Kode ICD 10" label="nama_pbf"
                    @input="valuekode" :options="options.kodeicd"></multiselect>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-textarea
                    rows="3"
                    no-resize
                    placeholder="Keterangan Diagnosa Dokter"
                  ></b-form-textarea>
                </b-col>
                <b-col md="12">
                  <div class="mt-2 text-right">
                    <b-button variant="success" size="sm" squared>Simpan</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" :lg="formRight" class="pl-1">
          <div class="border radius-custom-emr w-100 h-100">
            <div class="arrow bg-primary p-2 radius-custom-emr-head">
              <span @click="closeRightForm" v-if="formRight === '3'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> &nbsp; <span class="h6"><i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
              <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
            </div>
            <div class="custom-scroller p-2" :style="{height: heightPercent}">
              <div class="d-flex flex-row" v-if="formRight === '3'">
                <div class="h-100">
                  <Riwayat/>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
                <div class="h-100">
                  <p class="h6 text-secondary">Riwayat</p>
                  <i class="fas fa-history text-secondary fa-2x"></i>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </template>
<script>
import { xray } from '../../../../../../config/pluginInit'
import Multiselect from 'vue-multiselect'
import Services from '../../../../../../services/fatmahost/katalog/index'

export default {
  name: 'DiagnosaICD',
  components: {
    Multiselect
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '7',
      formMiddle: '4',
      formRight: '1',
      config: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        time_24hr: true
      },
      options: {
        kategori: [
          { text: 'Primary', value: 1 }
        ],
        kodeicd: []
      },
      selected: {
        primary: [],
        selectkode: []
      },
      fields: {
        table1: [
          { key: 'no', label: 'No.' },
          { key: 'kode', label: 'Kode (ICD 10)' },
          { key: 'deskripsi', label: 'Deskripsi' },
          { key: 'diagnosa', label: 'Diagnosa' },
          { key: 'kategori', label: 'Kategori' },
          { key: 'status', label: 'Status' }
        ]
      },
      items: {
        table1: [
          { no: '1', kode: '', deskripsi: '', diagnosa: '', kategori: '', status: '' },
          { no: '2', kode: '', deskripsi: '', diagnosa: '', kategori: '', status: '' },
          { no: '3', kode: '', deskripsi: '', diagnosa: '', kategori: '', status: '' },
          { no: '4', kode: '', deskripsi: '', diagnosa: '', kategori: '', status: '' },
          { no: '5', kode: '', deskripsi: '', diagnosa: '', kategori: '', status: '' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
    this.kodeicd()
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    },
    async kodeicd () {
      const response = await Services.getPbf()
      this.options.kodeicd = response.data.data
    },
    valuekode (param) {
      this.id_pbf = param.id.toString()
    }
  }
}
</script>
<style>
.emr-diagnosa-icd10 .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-diagnosa-icd10 .diagnosa-icd10-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-diagnosa-icd10 .diagnosa-icd10-fc-black {
  color: black;
  font-weight: bold;
}

.emr-diagnosa-icd10 .radius-custom-emr {
  border-radius: .5rem;
}
.emr-diagnosa-icd10 .custom-scroller {
  position:relative;
  overflow-y:auto;
  height:300px;
}
.emr-diagnosa-icd10 .radius-custom-form-border-cppt {
  border-top-right-radius: .5rem;
}
.emr-diagnosa-icd10 .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-diagnosa-icd10 .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-diagnosa-icd10 .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
.emr-diagnosa-icd10 .table td {
  font-size: 9px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.emr-diagnosa-icd10 .table th {
  font-size: 10px;
  font-weight: bold;
}
.emr-diagnosa-icd10 .multiselect__option {
  border-radius: 2px!important;
  font-size: 12px;
}
</style>
