<template>
  <div class="depresi-riwayat">
    <div>
      <b-row cols="12 mr-0">
        <b-col md="3"><i class="fas fa-map-marker-alt"></i> Ruang : </b-col>
        <b-col md="8">{{ text.ruangan }}</b-col>
      </b-row>
      <b-row cols="12 mr-0">
        <b-col md="3"><i class="fas fa-user-md"></i> DPJP : </b-col>
        <b-col md="8">{{ text.namadokter }}</b-col>
      </b-row>
      <b-row cols="12 mr-0">
        <b-col md="3"><i class="fas fa-caret-right"></i> Irama Dasar : </b-col>
        <b-col md="8">{{ text.isi }}</b-col>
      </b-row>
      <b-row cols="12 mr-0">
        <b-col md="3"><i class="fas fa-caret-right"></i> Irama Dasar : </b-col>
        <b-col md="8">{{ text.isi }}</b-col>
      </b-row>
      <b-row cols="12 mr-0">
        <b-col md="3"><i class="fas fa-caret-right"></i> Irama Dasar : </b-col>
        <b-col md="8">{{ text.isi }}</b-col>
      </b-row>
      <b-row cols="12 mr-0">
        <b-col md="3"><i class="fas fa-caret-right"></i> Irama Dasar : </b-col>
        <b-col md="8">{{ text.isi }}</b-col>
      </b-row>
      <b-row cols="12 mr-0">
        <b-col md="3"><i class="fas fa-caret-right"></i> Irama Dasar : </b-col>
        <b-col md="8">{{ text.isi }}</b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../../../../../../config/pluginInit'

export default {
  name: 'CatatanMedis',
  mounted () {
    xray.index()
  },
  data () {
    return {
      text: {
        ruangan: 'Forensik Pemulasaran Jenazah',
        namadokter: 'dr. Ida Ayu Made Kshanti Dharma Paramita, Sp.PD, KEMD, FINASIM',
        isi: `Quis magna Lorem anim amet ipsum do mollit sit cillum voluptate ex nulla
          tempor. Laborum consequat non elit enim exercitation cillum aliqua
          consequat id aliqua. Esse ex consectetur mollit voluptate est in duis
          laboris ad sit ipsum anim Lorem. Incididunt veniam velit elit elit veniam
          Lorem aliqua quis ullamco deserunt sit enim elit aliqua esse irure. Laborum
          nisi sit est tempor laborum mollit labore officia laborum excepteur
          commodo non commodo dolor excepteur commodo. Ipsum fugiat ex est consectetur
          ipsum commodo tempor sunt in proident`
      }
    }
  }
}
</script>
