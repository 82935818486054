<template>
  <div class="emr-pemberian-obat">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-pemberian-obat">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Pemberian Obat</span>
          </div>
          <div class="bg-custom p-2">
            <b-row class="align-items-center">
              <b-col md="4">
                <multiselect :show-labels="false" label="uraian" :options="options.kfa92"
                  v-model="selected.kfa92" placeholder="Nama Obat"
                  @search-change="getKFA92" track-by="id">
                </multiselect>
              </b-col>
              <b-col md="3">
                <b-form-input class="pemberian-obat-form-input bg-white" placeholder="Dosis ..."></b-form-input>
              </b-col>
              <b-col md="3">
                <b-form-input class="pemberian-obat-form-input bg-white" placeholder="Lama Penggunaan ..."></b-form-input>
              </b-col>
              <b-col md="2">
                <b-button variant="success">Simpan</b-button>
              </b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <b-row class="align-items-center mr-0">
              <b-col>
                <div class="table mb-0">
                  <b-table class="mb-0"
                  ref="table"
                  bordered
                  hover
                  striped
                  small
                  :items="items.table1"
                  :fields="fields.table1">
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i>
              &nbsp; Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span> &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{ height: heightPercent }">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat />
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'
import Multiselect from 'vue-multiselect'
import ServiceKFA92 from '../../../../../../../services/fatmahost/katalog/index'

export default {
  name: 'PemberianObat',
  components: {
    Riwayat,
    Multiselect
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      selected: {
        kfa92: []
      },
      options: {
        alergi: [
          { text: 'Marah', value: 1 },
          { text: 'Cemas', value: 2 },
          { text: 'Takut', value: 3 },
          { text: 'Kecenderungan Bunuh Diri', value: 4 }
        ],
        kfa92: []
      },
      fields: {
        table1: [
          { key: 'no', label: 'No.' },
          { key: 'namaobat', label: 'Nama Obat' },
          { key: 'dosis', label: 'Dosis' },
          { key: 'lamapenggunaan', label: 'Lama Penggunaan' },
          { key: 'sts', label: 'Status' }
        ]
      },
      items: {
        table1: [
          { no: 40, namaobat: 'Dickerson', dosis: 'Macdonald', lamapenggunaan: '5 hari', sts: 1 },
          { no: 21, namaobat: 'Larsen', dosis: 'Shaw', lamapenggunaan: '5 hari', sts: 1 },
          { no: 89, namaobat: 'Geneva', dosis: 'Wilson', lamapenggunaan: '5 hari', sts: 1 },
          { no: 89, namaobat: 'Geneva', dosis: 'Wilson', lamapenggunaan: '5 hari', sts: 1 },
          { no: 89, namaobat: 'Geneva', dosis: 'Wilson', lamapenggunaan: '5 hari', sts: 1 },
          { no: 89, namaobat: 'Geneva', dosis: 'Wilson', lamapenggunaan: '5 hari', sts: 1 },
          { no: 89, namaobat: 'Geneva', dosis: 'Wilson', lamapenggunaan: '5 hari', sts: 1 },
          { no: 38, namaobat: 'Jami', dosis: 'Carney', lamapenggunaan: '5 hari', sts: 1 }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    },
    async getKFA92 (input) {
      if (input.length > 2) {
        await ServiceKFA92.kfa92(input).then(response => {
          if (response.status === 200) {
            this.options.kfa92 = response.data.data
          }
        }).catch(error => {
          this.error = error.response.data.errors.message
        })
      } else {
        this.options.kfa92 = []
      }
    }
  }
}
</script>
<style>
.emr-pemberian-obat .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-pemberian-obat .radius-custom-emr {
  border-radius: .5rem;
}
.emr-pemberian-obat .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-pemberian-obat .bg-custom {
  background-color: #b8f4ff;
}
.emr-pemberian-obat .radius-custom-form-head-pemberian-obat {
  border-top-right-radius: .5rem;
}
.emr-pemberian-obat .pemberian-obat-form-input {
  font-size: 10px;
  height: 25px!important;
  border-radius: 5px;
}
.emr-pemberian-obat .pemberian-obat-fc-white {
  color: rgb(255, 255, 255);
  font-weight: bold;
}
.emr-pemberian-obat .pemberian-obat-fc-black {
  color: black;
  font-weight: bold;
}
.emr-pemberian-obat .multiselect__single {
  font-size: 13px !important;
}
.emr-pemberian-obat .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-pemberian-obat .multiselect__option {
  font-size: 13px;
}
.emr-pemberian-obat .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-pemberian-obat .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-pemberian-obat .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-pemberian-obat .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
