<template>
  <div>
    <b-card>
      <b-tabs pills vertical>
        <b-tab title="Verifikator Keluhan Utama">
          <VerifKeluhanUtama />
        </b-tab>
        <b-tab title="Verifikator Riwayat Penyakit Sekarang">
          <VerifRPS />
        </b-tab>
        <b-tab title="Verifikator Riwayat Penyakit Dahulu">
          <VerifRPD />
        </b-tab>
        <b-tab title="Verifikator Pemeriksaan Fisik">
          <VerifFisik />
        </b-tab>
        <b-tab title="Verifikator Pemeriksaan Laboratorium">
          <VerifLab />
        </b-tab>
        <b-tab title="Verifikator Pemeriksaan Radiologi">
          <VerifRad />
        </b-tab>
        <b-tab title="Verifikator Diagnosa Utama">
          <VerifDiagnosaUtama />
        </b-tab>
        <b-tab title="Verifikator Diagnosa Sekunder">
          <VerifDiagnosaSekunder />
        </b-tab>
        <b-tab title="Verifikator Obat Selama di RS">
          <VerifObat />
        </b-tab>
        <b-tab title="Verifikator Tindakan Selama di RS">
          <VerifTindakan />
        </b-tab>
        <b-tab title="Verifikator Dokter yang Melakukan Operasi">
          <VerifOperasi />
        </b-tab>
        <b-tab title="Verifikator Penyebab Kematian">
          <VerifKematian />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { xray } from '../../../../../../../config/pluginInit'
import VerifKeluhanUtama from '../VerifKeluhanUtama/VerifKeluhanUtamaForm.vue'
import VerifRPS from '../VerifRPS/VerifRPSForm.vue'
import VerifRPD from '../VerifRPD/VerifRPDForm.vue'
import VerifFisik from '../VerifFisik/VerifFisikForm.vue'
import VerifLab from '../VerifLab/VerifLabForm.vue'
import VerifRad from '../VerifRad/VerifRadForm.vue'
import VerifDiagnosaUtama from '../VerifDiagnosaUtama/VerifDiagnosaUtamaForm.vue'
import VerifDiagnosaSekunder from '../VerifDiagnosaSekunder/VerifDiagnosaSekunderForm.vue'
import VerifObat from '../VerifObat/VerifObatForm.vue'
import VerifTindakan from '../VerifTindakan/VerifTindakanForm.vue'
import VerifOperasi from '../VerifOperasi/VerifOperasiForm.vue'
import VerifKematian from '../VerifKematian/VerifKematianForm.vue'

export default {
  name: 'Tab',
  components: {
    VerifKeluhanUtama,
    VerifRPS,
    VerifRPD,
    VerifFisik,
    VerifLab,
    VerifRad,
    VerifDiagnosaUtama,
    VerifDiagnosaSekunder,
    VerifObat,
    VerifTindakan,
    VerifOperasi,
    VerifKematian
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    }
  }
}
</script>

<style>
.custom-scroller {
  position:relative;
  overflow-y:auto;
}
</style>
