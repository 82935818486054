<template>
  <div>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab active>
          <template #title>
            <span>Fisik</span>
          </template>
          <div class="p-2">
            <Fisik />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Nyeri
          </template>
          <div class="p-2">
            <Nyeri />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Status Pediatric
          </template>
          <div class="p-2">
            <TabStatusPediatric />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Skrining Paliatif
          </template>
          <div class="p-2">
            <SkriningPaliatif />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Resiko Jatuh
          </template>
          <div class="p-2">
            <ResikoJatuh />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Status Obstetri
          </template>
          <div class="p-2">
            <Maintenance />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Status Mental
          </template>
          <div class="p-2">
            <Maintenance />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Diagnosis
          </template>
          <div class="p-2">
            <Maintenance />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Diagnosis Keperawatan
          </template>
          <div class="p-2">
            <Maintenance />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Maintenance from '../../../Pages/Maintenance.vue'
import Fisik from '../../ComponentSub/RekamMedis/Penilaian/Fisik/FisikForm.vue'
import Nyeri from '../../ComponentSub/RekamMedis/Penilaian/Nyeri/NyeriForm.vue'
import SkriningPaliatif from '../../ComponentSub/RekamMedis/Penilaian/SkriningPaliatif/SkriningPaliatifForm.vue'
import TabStatusPediatric from '../../ComponentSub/RekamMedis/Penilaian/StatusPediatric/Tab/Tab.vue'
import ResikoJatuh from '../../ComponentSub/RekamMedis/Penilaian/ResikoJatuh/index.vue'

export default {
  name: 'Anamnesis',
  components: {
    Fisik,
    Nyeri,
    SkriningPaliatif,
    TabStatusPediatric,
    ResikoJatuh,
    Maintenance
  },
  mounted () {
    xray.index()
  }
}
</script>
