<template>
  <div id="emr-vertical-menu">
    <div>
      <b-card no-body>
        <b-tabs pills card vertical>
          <b-tab active>
            <template #title>
              <i class="fas fa-notes-medical py-2"></i> &nbsp; Anamnesis
            </template>
            <div class="p-0">
              <Anamnesis/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-stethoscope py-2"></i> &nbsp; Pemeriksaan
            </template>
            <div class="p-0">
              <Pemeriksaan/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-check-circle py-2"></i> &nbsp; Penilaian
            </template>
            <div class="p-0">
              <Penilaian/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-book-open py-2"></i> &nbsp; ICD
            </template>
            <div class="p-0">
              <ICD/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-edit py-2"></i> &nbsp; Penandaan
            </template>
            <div class="p-0">
              <Penandaan/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-user-tag py-2"></i> &nbsp; Perencanaan
            </template>
            <div class="p-0">
              <Perencanaan/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-heartbeat py-2"></i> &nbsp; EKG
            </template>
            <div class="p-0">
              <EKG/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-file-invoice py-2"></i> &nbsp; CPPT
            </template>
            <div class="p-0">
              <CPPT/>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Anamnesis from '../Components/RekamMedis/Anamnesis.vue'
import Pemeriksaan from '../Components/RekamMedis/Pemeriksaan.vue'
import Penilaian from '../Components/RekamMedis/Penilaian.vue'
import ICD from '../Components/RekamMedis/Icd.vue'
import Penandaan from '../ComponentSub/RekamMedis/Penandaan/PenandaanForm.vue'
import Perencanaan from '../Components/RekamMedis/Perencanaan.vue'
import EKG from '../ComponentSub/RekamMedis/EKG/EKGForm.vue'
import CPPT from '../ComponentSub/RekamMedis/CPPT/CPPTForm.vue'

export default {
  name: 'VerticalMenu',
  components: {
    Anamnesis,
    Pemeriksaan,
    Penilaian,
    ICD,
    Penandaan,
    Perencanaan,
    EKG,
    CPPT
  },
  mounted () {
    xray.index()
  }
}
</script>
