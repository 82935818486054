<template>
  <div class="emr-kognitif">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-kognitif">
            <b-row>
              <b-col md="11"><span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Kognitif Mini Cog</span></b-col>
              <b-col md="1" class="text-end"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buat Kognitif Baru'"><i class="fas fa-plus"></i></span></b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <div class="bg-custom-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Dapatkan perhatian pasien</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-row class="align-items-center">
                    <b-col md="2"><label class="kognitif-fc-black m-0">Pilih versi :</label></b-col>
                    <b-col md="10"><b-form-select :options="options.versi" size="sm"></b-form-select></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Menggambar jam</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.jam"
                    :options="options.jam"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12">
                  <label class="h6">Katakan : Apakah ketiga kata yang tadi saya minta Bapak/Ibu untuk mengingatnya ?</label>
                </b-col>
                <b-col md="12"><label class="label-input">Keadaan Umum :</label></b-col>
                <b-col md="12">
                  <b-form-textarea
                    class="bg-white"
                    rows="2"
                    no-resize
                  ></b-form-textarea>
                </b-col>
                <b-col md="12"><label class="label-input">Keterangan  :</label></b-col>
                <b-col md="12">
                  <b-form-textarea
                    class="bg-white"
                    rows="2"
                    no-resize
                  ></b-form-textarea>
                </b-col>
              </b-row>
            </div>
            <div class="mt-1">
              <b-row class="mr-0">
                <b-col>
                  <div class="text-right">
                    <b-button class="m-1" @click="modalShow = !modalShow" variant="warning" size="sm" squared><i class="fas fa-info"></i>Petunjuk</b-button>
                    <b-button variant="success" size="sm" squared><i class="fas fa-save"></i>Simpan</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formMiddle" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span></span> &nbsp; <span class="h6"><i class="far fa-edit"></i> &nbsp;  Catatan Medis Kunjungan Terkini</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '3'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> &nbsp; <span class="h6"><i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '3'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal v-model="modalShow"
      id="modalPetunjuk"
      size="lg"
      title="Petunjuk Kognitif Mini Cog"
      header-bg-variant="warning"
      hide-footer
      >
      <b-row>
          <b-col md="12"><label class="h5"><b-badge variant="primary">Dapatkan perhatian pasien, kemudian katakan :</b-badge></label></b-col>
          <b-col md="12" class="ml-2">
            <div class="bg-custom-1">
              <label class="label-input">"Saya akan menyebutkan tiga kata dan saya meminta Bapak/Ibu untuk mengingatnya sekarang dan nanti
                Ketiga huruf tersebut adalah ..., ..., ... (pilih ketiga huruf dari pilihan versi di bawah ini) Tolong sebutkan ketiganya sekarang."
              </label>
            </div>
          </b-col>
        <b-col md="12" class="mt-3"><label class="h5"><b-badge variant="primary">Katakan seluruh frase berikut sesuai urutannya :</b-badge></label></b-col>
        <b-col md="12" class="ml-2">
          <div class="bg-custom-1">
            <label class="label-input">"Tolong gambar sebuah jam pada lembar ini. Mulailah dengan menggambar sebuah lingkaran besar.
              Kemudian, tuliskan angka-angka pada lingkaran dan atur jarum jam mengarah pukul 11:10 (11 lewat 10 menit)."
            </label>
          </div>
        </b-col>
        <b-col md="12" class="mt-3"><label class="h5"><b-badge variant="primary">Katakan :</b-badge></label></b-col>
        <b-col md="12" class="ml-2">
          <div class="bg-custom-1">
            <label class="label-input">"Apakah ketiga kata yang tadi saya minta Bapak/Ibu untuk mengingatnya?"
            </label>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'Kognitif',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '7',
      formMiddle: '4',
      formRight: '1',
      modalShow: false,
      defVal: {
        versi: 0,
        jam: 1
      },
      options: {
        versi: [
          { value: 1, text: 'Versi 1' },
          { value: 2, text: 'Versi 2' },
          { value: 3, text: 'Versi 3' },
          { value: 4, text: 'Versi 4' },
          { value: 5, text: 'Versi 5' },
          { value: 6, text: 'Versi 6' }
        ],
        jam: [
          { value: 1, text: 'NORMAL' },
          { value: 0, text: 'ABNORMAL' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    }
  }
}
</script>
<style>
.emr-kognitif .radius-custom-emr {
  border-radius: .5rem;
}
.emr-kognitif .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-kognitif .label-input {
  font-weight: bold;
}
.emr-kognitif .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-kognitif .radius-custom-form-head-kognitif {
  border-top-right-radius: .5rem;
}
.emr-kognitif .radius-custom-form-border-kognitif {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.emr-kognitif .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-kognitif .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
#modalPetunjuk .bg-custom-1 {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-kognitif .plus-button {
  transition: all 1s;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.emr-kognitif .plus-button:hover .hover {
  background-color: rgba(0, 0, 0, 0.671);
  color:white;
}
.emr-kognitif .form-control {
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background: white;
}
.emr-kognitif .bg-white {
  background: #ffffff;
}
.emr-kognitif .custom-control-label {
  margin-top: 0;
}
.emr-kognitif .multiselect__single {
  font-size: 13px !important;
}
.emr-kognitif .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-kognitif .multiselect__option {
  font-size: 13px;
}
.emr-kognitif .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-kognitif .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-kognitif .body {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
}
.emr-kognitif .bg-custom-1 {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-kognitif .bg-custom-2 {
  background-color: #faffb8;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-kognitif .bg-custom-3 {
  background-color: #f4ff5a;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-kognitif .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
}
.emr-kognitif .kognitif-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-kognitif .kognitif-fc-black {
  color: black;
  font-weight: bold;
}
.emr-kognitif .table th {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-kognitif .cstm-cntrl {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-kognitif .custom-min-height {
  min-height: 100%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-kognitif .custom-min-height1 {
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-kognitif .custom-flatpicker {
  border: 1px solid rgb(233, 233, 233);
  height: 38px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  border-radius: 4px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}
.emr-kognitif .my-cursor {
  cursor: pointer;
}
.emr-kognitif .rounded-custom {
  border-radius: 5px;
  border: 1px solid rgb(209, 209, 209);
}
@-webkit-keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
@keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
</style>
