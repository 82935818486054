<template>
  <div>
    <b-card end>
      <b-tabs class="p-2" end pills>
        <!-- <b-tab title="Order"><Maintenance class="mb-2" /></b-tab> -->
        <b-tab title="Riwayat"><EresepDaftarOrder class="mb-2" /></b-tab>
        <b-tab title="Daftar Order"><Maintenance class="mb-2" /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { xray } from '../../../../../config/pluginInit'
import EresepDaftarOrder from '../DaftarOrder/DaftarOrderForm.vue'
import Maintenance from '../../../../Pages/Maintenance.vue'

export default {
  name: 'Tab',
  components: {
    Maintenance,
    EresepDaftarOrder
  },
  mounted () {
    xray.index()
  }
}
</script>
