<template>
  <div>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab active>
          <template #title>
            <span>Keluhan Utama</span>
          </template>
          <div class="p-2">
            <KuluhanUtama />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Riwayat Penyakit Sekarang
          </template>
          <div class="p-2">
            <RiwayatPenyakitSekarang />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Riwayat Penyakit Dahulu
          </template>
          <div class="p-2">
            <RiwayatPenyakitDahulu />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Status Fungsional
          </template>
          <div class="p-2">
            <StatusFungsional />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Kondisi Sosial & Psikologi
          </template>
          <div class="p-2">
            <KondisiSosial />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Edukasi Pasien & Keluarga
          </template>
          <div class="p-2">
            <EdukasiPasienTab />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Permasalahan Gizi
          </template>
          <div class="p-2">
            <PermasalahanGizi />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import KuluhanUtama from '../../ComponentSub/RekamMedis/Anamnesis/KeluhanUtama/KeluhanUtamaForm.vue'
import RiwayatPenyakitSekarang from '../../ComponentSub/RekamMedis/Anamnesis/RPS/RiwayatPenyakitSekarangForm.vue'
import RiwayatPenyakitDahulu from '../../ComponentSub/RekamMedis/Anamnesis/RPD/Tab/Tab.vue'
import KondisiSosial from '../../ComponentSub/RekamMedis/Anamnesis/KondisiSosial/KondisiSosialForm.vue'
import EdukasiPasienTab from '../../ComponentSub/RekamMedis/Anamnesis/EdukasiPasien/Tab/Tab.vue'
import StatusFungsional from '../../ComponentSub/RekamMedis/Anamnesis/StatusFungsional/StatusFungsionalForm.vue'
import PermasalahanGizi from '../../ComponentSub/RekamMedis/Anamnesis/PermasalahanGizi/PermasalahanGiziForm.vue'

export default {
  name: 'Anamnesis',
  components: {
    KuluhanUtama,
    RiwayatPenyakitSekarang,
    RiwayatPenyakitDahulu,
    KondisiSosial,
    EdukasiPasienTab,
    StatusFungsional,
    PermasalahanGizi
  },
  mounted () {
    xray.index()
  }
}
</script>
