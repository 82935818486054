<template>
  <div id="bpjs-integrasi" class="mb-4">
    <b-container fluid>
      <div>
        <b-card class="radius-custom-emr shadow-sm" no-body>
          <b-tabs pills card>
            <b-tab active>
              <template #title>
                <i class="far fa-list-alt"></i> ANTREAN
              </template>
              <div class="emr-tab-content">
                1
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <i class="far fa-list-alt"></i> VCLAIM
              </template>
              <div class="emr-tab-content">
                <Vclaim/>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Vclaim from './components/Vclaim.vue'

export default {
  name: 'BPJS',
  components: {
    Vclaim
  },
  mounted () {
    xray.index()
  }
}
</script>
