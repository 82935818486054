<template>
  <div>
    <b-card end>
      <b-tabs pills end>
        <b-tab title="Fungsional"><Fungsional class="mb-2" /></b-tab>
        <b-tab title="Instrumental ADL"><InstrumentalADL class="mb-2" /></b-tab>
        <b-tab title="Nutrisi"><Nutrisi class="mb-2" /></b-tab>
        <b-tab title="Kognitif Mini Cog"><Kognitif class="mb-2" /></b-tab>
        <b-tab title="Depresi"><Depresi class="mb-2" /></b-tab>
        <b-tab title="Frality"><Frality class="mb-2" /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { xray } from '../../../../../../../config/pluginInit'
import Fungsional from '../../../../RekamMedis/Pemeriksaan/KajianGeriatri/Fungsional/KajianGeriatriForm.vue'
import InstrumentalADL from '../../../../RekamMedis/Pemeriksaan/KajianGeriatri/InstrumentalADL/InstrumentalADLForm.vue'
import Nutrisi from '../../../../RekamMedis/Pemeriksaan/KajianGeriatri/Nutrisi/NutrisiForm.vue'
import Kognitif from '../Kognitif/KognitifForm.vue'
import Depresi from '../Depresi/DepresiForm.vue'
import Frality from '../Frality/FralityForm.vue'

export default {
  name: 'Tab',
  components: {
    InstrumentalADL,
    Fungsional,
    Nutrisi,
    Kognitif,
    Depresi,
    Frality
  },
  mounted () {
    xray.index()
  }
}
</script>
