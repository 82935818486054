<template>
  <div class="emr-layanan">
    <b-row class="justify-content-between m-2">
      <b-col cols="12" :md="formMain" class="pl-1 pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-border-cppt">
            <b-row>
              <b-col md="11"><span class="h6"><i class="fas fa-flask"></i> &nbsp; Riwayat Daftar Tindakan</span></b-col>
              <!-- <b-col md="1" class="text-right"><span class="px-2 py-1 rounded shadow-sm hover" @click="_onClickInput" v-b-tooltip.hover.topright="'Input Tindakan'"><i class="fas fa-plus"></i></span></b-col> -->
            </b-row>
          </div>
          <div class="custom-scroller p-0" :style="{height: heightPercent}">
            <b-row class="mr-0 align-items-center">
              <b-col md="12">
                <div class="table mb-0">
                  <b-table class="mb-0"
                  ref="table"
                  selectable
                  select-mode="single"
                  hover
                  :items="items.table"
                  :fields="fields.table">
                  <template #cell(pelaksana)="data">
                    <div v-for="(datas, index) in _splitDokter(data.item.pelaksana)" :key="index">{{ datas }}</div>
                  </template>
                  <template #cell(aksi)="data">
                    <b-button class="w-100" variant="primary" size="sm" v-if="data.item.no_urut === '1'" @click="_openModal(data.item.no_urut)"><i class="fas fa-skull"></i> Hasil Radiologi</b-button>
                    <b-button class="w-100" variant="primary" size="sm" v-else-if="data.item.no_urut === '2'" @click="_openModal(data.item.no_urut)"><i class="fas fa-flask"></i> Hasil Laboratorium</b-button>
                    <b-button class="w-100" variant="primary" size="sm" v-else-if="data.item.no_urut === '3'" @click="_openModal(data.item.no_urut)"><i class="fas fa-flask"></i> Hasil Patologi</b-button>
                  </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <!-- <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <b-row>
              <b-col md="1" class="text-end" v-if="formMain === '7'"><span class="px-2 py-1 rounded shadow-sm hover" @click="_closeRightForm" v-b-tooltip.hover.topright="'Tutup Form Tindakan'"><i class="fas fa-arrow-right"></i></span></b-col>
              <b-col md="1" class="text-end" v-else><span class="px-2 py-1 rounded shadow-sm hover" @click="_openRightForm" v-b-tooltip.hover.topright="'Buka Form Tindakan'"><i class="fas fa-arrow-left"></i></span></b-col>
              <b-col md="11" v-if="formRight === '5'"><span @click="_openRightForm" class="h6"><i class="fas fa-microscope"></i>&nbsp; Form Tindakan</span></b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="arrow d-flex flex-row justify-content-center" v-if="formRight === '5'">
              <b-row class="w-100">
                <b-col md="12"><b-form-input class="cppt-form-input" v-model="dummy.dm1" readonly></b-form-input></b-col>
                <b-col md="12" class="mt-2"><b-form-input class="cppt-form-input" v-model="dummy.dm2" readonly></b-form-input></b-col>
                <b-col md="12" class="mt-2">
                  <div class="bg-primary p-2 radius-custom-emr-head-2 border">
                    <b-row class="w-100">
                      <b-col md="11"><span class="h6"><i class="fas fa-user-md"></i>&nbsp; Tenaga Medis</span></b-col>
                      <b-col md="1" class=""><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tambah Tenaga Medis'"
                        @click="_addTenagaMedis"><i class="fas fa-plus"></i></span></b-col>
                    </b-row>
                  </div>
                  <div class="table mt-1 mb-0">
                    <b-table class="mb-0"
                    ref="table"
                    bordered
                    hover
                    small
                    :items="items.table2"
                    :fields="fields.table2">
                    <template #cell(index)="data">
                      {{ data.index + 1}}
                    </template>
                    <template #cell(jenis)="data">
                      <multiselect :options="options.tenagamedis" v-model="selected.tenagamedis[data.index].detail"
                      label="deskripsi" @input="_onCariTenagaMedis($event, data.index)" placeholder="Pilih Jenis Tenaga Medis">
                      </multiselect>
                    </template>
                    <template #cell(nama)="data">
                      <multiselect :show-labels="false" :options="options.namatenagamedis"
                        label="nama" :disabled="isDisabled"
                        v-model="selected.namatenagamedis[data.index].detail" placeholder="Nama Tenaga Medis"
                        @search-change="_onCariNamaPetugas">
                      </multiselect>
                    </template>
                    <template #cell(aksi)="data">
                      <span class="text-center">
                        <b-button :hidden="data.index === 0"
                        variant="white" v-b-tooltip.hover title="Hapus Tenaga Medis"
                        @click="_onClickHapus(data.index)"><i class="text-danger fas fa-user-minus"
                        ></i>
                        </b-button>
                      </span>
                    </template>
                    </b-table>
                  </div>
                </b-col>
                <b-col class="mt-2" md="12">
                  <div class="text-right">
                    <b-button variant="success" size="sm">Simpan</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col> -->
    </b-row>
    <b-modal title="MASUKAN EXPERTISE" id="modalRadiologi" ref="modalRadiologi" size="xl" scrollable centered>
      <div>
        <ModalLab />
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="success"
            size="sm"
            class="float-right mx-1"
          >
            Final
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-1"
          >
            Simpan
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="float-right mx-1"
          >
            Batal
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal title="MASUKAN EXPERTISE" id="modalLaboratorium" ref="modalLaboratorium" size="xl" scrollable centered>
      <div>
        <ModalRad />
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="success"
            size="sm"
            class="float-right mx-1"
          >
            Final
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-1"
          >
            Simpan
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="float-right mx-1"
          >
            Batal
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal title="MASUKAN EXPERTISE" id="modalPatologi" ref="modalPatologi" size="xl" scrollable centered>
      <div>
        <ModalPat />
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="success"
            size="sm"
            class="float-right mx-1"
          >
            Final
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-1"
          >
            Simpan
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="float-right mx-1"
          >
            Batal
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
// import Multiselect from 'vue-multiselect'
import Service from '../../../../services/fatmahost/master/index'
import Services from '../../../../services/fatmahost/index.js'
import ModalLab from './Modal/Lab.vue'
import ModalRad from './Modal/Radiologi.vue'
import ModalPat from './Modal/Patologi.vue'

export default {
  name: 'Workspace',
  components: {
    ModalLab,
    ModalRad,
    ModalPat
    // Multiselect
  },
  data () {
    return {
      message: '',
      windowHeight: 0,
      heightPercent: 0,
      formMain: '12',
      formRight: '5',
      modalShow: false,
      isChecked: true,
      isDisabled: true,
      simpan: '',
      dummy: {
        dm1: '08-05-2024 08:05:23 - Imunisasi BCG',
        dm2: 'ISIMRS'
      },
      selected: {
        tenagamedis: [
          { detail: [] }
        ],
        namatenagamedis: [
          { detail: [] }
        ]
      },
      options: {
        tenagamedis: [],
        namatenagamedis: []
      },
      fields: {
        table: [
          { label: 'NO.', key: 'no_urut', class: 'text-center' },
          { key: 'tanggal', label: 'TANGGAL', class: 'col-2' },
          { key: 'desc_tindakan', label: 'TINDAKAN', class: 'col-2' },
          { key: 'pelaksana', label: 'PELAKSANA', class: 'col-6' },
          { key: 'aksi', label: 'AKSI' }
        ],
        table2: [
          { label: 'No.', key: 'index', class: 'text-center' },
          { key: 'jenis', label: 'Jenis', class: 'text-center' },
          { key: 'nama', label: 'Nama', class: 'text-center' },
          // { key: 'sts', label: 'Status', class: 'text-center' },
          { key: 'aksi', label: 'Aksi', class: 'text-center' }
        ],
        table3: [
          { key: 'aksi', label: 'Aksi', class: 'text-center' },
          { key: 'tdk', label: 'Tindakan' }
        ]
      },
      items: {
        table: this._getLayanan,
        table2: [
          { jenis: '', nama: '' }
        ],
        table3: [
          { tdk: '1001 - Imunisasi BCG' },
          { tdk: '1058 - Imunisasi Campak' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this._getWindowHeight()
    window.addEventListener('resize', this._getWindowHeight)
    this._onChangeTenagaMedis()
    // this._getLayanan()
  },
  destroyed () {
    window.removeEventListener('resize', this._getWindowHeight)
  },
  methods: {
    _getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    _closeRightForm () {
      this.formMain = '11'
      this.formRight = '1'
    },
    _openRightForm () {
      this.formMain = '7'
      this.formRight = '5'
    },
    _onClickInput () {
      this.modalShow = true
    },
    _openModal (value) {
      if (value === '1') this.$refs['modalRadiologi'].show()
      if (value === '2') this.$refs['modalLaboratorium'].show()
      if (value === '3') this.$refs['modalPatologi'].show()
    },
    async _onCariTenagaMedis ($event, index) {
      if ($event === null) {
        this.isDisabled = true
        this.simpan = null
        this.selected.namatenagamedis[index].detail = []
      } else {
        this.isDisabled = false
        this.selected.namatenagamedis[index].detail = []
        this.simpan = $event.id
      }
    },
    async _onCariNamaPetugas (input) {
      if (input.length > 2) {
        await Service.dokterIrjByName(input).then(response => {
          if (response.status === 200) {
            this.options.namatenagamedis = response.data.data
          }
        }).catch(error => {
          this.error = error.response.data.errors.message
        })
      } else {
        this.options.namatenagamedis = []
      }
    },
    async _onChangeTenagaMedis () {
      const response = await Service.referensi(32)
      this.options.tenagamedis = response.data.data
    },
    _addTenagaMedis () {
      this.items.table2.push({
        jenis: '',
        nama: ''
      })
      this.selected.namatenagamedis.push({
        detail: []
      })
      this.selected.tenagamedis.push({
        detail: []
      })
    },
    _onClickHapus (index) {
      this.items.table2.splice(index, 1)
      this.selected.namatenagamedis.splice(index, 1)
      this.selected.tenagamedis.splice(index, 1)
    },
    _onTindakanSelected (index) {
      // console.log(index)
    },
    async _getLayanan (ctx) {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RLAYANAN","xparam0":"${this.$route.params.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        return response.data.data
      } catch (error) {
        this.message = error
      }
    },
    _splitDokter (value) {
      const create = value.split('\n')
      return create
    }
  }
}
</script>

<style>
.emr-layanan .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-layanan .cppt-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-layanan .cppt-fc-black {
  color: black;
  font-weight: bold;
}
.emr-layanan .custom-scroller {
  position:relative;
  overflow-y:auto;
  height:300px;
}
.emr-layanan .radius-custom-emr {
  border-radius: .5rem;
}
.emr-layanan .radius-custom-form-border-cppt {
  border-top-right-radius: .5rem;
}
.emr-layanan .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-layanan .radius-custom-emr-head-2 {
  border-radius: .5rem;
}
.emr-layanan .table td {
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.emr-layanan .table th {
    font-size: 13px;
    font-weight: bold;
}
.emr-layanan .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-layanan .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}

#modalLaboratorium .cppt-form-input, #modalRadiologi .cppt-form-input, #modalPatologi .cppt-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
#modalLaboratorium .cppt-fc-black, #modalRadiologi .cppt-fc-black, #modalPatologi .cppt-fc-black {
  color: black;
  font-weight: bold;
}
#modalLaboratorium .radius-custom-emr, #modalRadiologi .radius-custom-emr, #modalPatologi .radius-custom-emr {
  border-radius: .5rem;
}
#modalLaboratorium .radius-custom-emr-head, #modalRadiologi .radius-custom-emr-head, #modalPatologi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
#modalLaboratorium .table td, #modalRadiologi .table td, #modalPatologi .table td {
    font-size: 9px;
    padding-top: 0px;
    padding-bottom: 0px;
}
#modalLaboratorium .table th , #modalRadiologi .table th, #modalPatologi .table th{
    font-size: 10px;
    font-weight: bold;
}
#modalLaboratorium .form-control, #modalRadiologi .form-control, #modalPatologi .form-control {
    height: 40px;
    border-radius: 5px;
}
.emr-layanan .table td, .emr-layanan .table th {
    padding: 0.20rem!important;
}
.emr-layanan .multiselect__single {
  font-size: 13px !important;
}
.emr-layanan .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-layanan .multiselect__option {
  font-size: 13px;
}
.emr-layanan .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-layanan .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-layanan .form-control {
    height: 20px!important;
}
</style>
