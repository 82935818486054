<template>
  <div class="emr-depresi">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-head-depresi">
            <b-row>
              <b-col md="11"><span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Depresi</span></b-col>
              <b-col md="1" class="text-end"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buat Depresi Baru'"><i class="fas fa-plus"></i></span></b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-3" :style="{height: heightPercent}">
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda sebenernya puas dengan kehidupan anda ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.puas"
                    :options="options.pilihanYa"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda telah meninggalkan banyak kegiatan dan minat atau kesenangan anda ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.kegiatan"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda merasa kehidupan anda kosong ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.kehidupan"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda sering merasa bosan ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.bosan"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda mempunyai semangat yang baik setiap saat ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.punyasemangat"
                    :options="options.pilihanYa"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda takut bahwa sesuatu yang buruk akan terjadi pada anda ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.takut"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda merasa bahagia untuk sebagian besar dari kehidupan anda ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.bahagia"
                    :options="options.pilihanYa"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda sering merasa tidak berdaya ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.tidakberdaya"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda lebih senang tinggal di rumah daripada pergi keluar dan mengerjakan sesuatu hal yang baru ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.rumah"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda merasa mempunyai banyak masalah dengan daya ingat anda dibanding kebanyakan orang ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.masalah"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda pikir bahwa hidup anda sekarang ini menyenangkan ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.menyenangkan"
                    :options="options.pilihanYa"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda merasa tidak berharga seperti perasaan anda saat ini ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.tidakberharga"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda merasa penuh semangat ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.penuhsemangat"
                    :options="options.pilihanYa"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda merasa bahwa keadaan anda tidak ada harapan ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.harapan"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row>
                <b-col md="12"><h4><b-badge variant="dark">Apakah anda pikir bahwa orang lain lebih baik keadaannya dari anda ?</b-badge></h4></b-col>
              </b-row>
              <b-row class="mt-2 mr-0">
                <b-col md="12">
                  <b-form-radio-group
                    v-model="defVal.oranglain"
                    :options="options.pilihanTidak"
                  ></b-form-radio-group>
                </b-col>
              </b-row>
            </div>
            <div class="bg-custom-1 mt-1">
              <b-row class="align-items-center">
                <b-col md="12"><h4><label class="depresi-fc-black">HASIL</label></h4></b-col>
                <b-col md="3"><b-card class="border">
                    <b-row no-gutters>
                      <b-col md="2" class="m-3">
                        <h2><i class="fas fa-poll-h"></i></h2>
                      </b-col>
                      <b-col md="4" class="text-center">
                        <b-card-body title="Total">
                          <b-card-text>
                            <h3> {{ defVal.jumlahDepresi }} </h3>
                          </b-card-text>
                        </b-card-body>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col md="8"><h3><b-badge :variant="badge.color">{{ badge.keterangan }}</b-badge></h3></b-col>
              </b-row>
            </div>
            <div class="mt-1">
              <b-row class="mr-0">
                <b-col>
                  <div class="text-right">
                    <b-button variant="success" size="sm" squared><i class="fas fa-save"></i>Simpan</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formMiddle" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span></span> &nbsp; <span class="h6"><i class="far fa-edit"></i> &nbsp;  Catatan Medis Kunjungan Terkini</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '3'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> &nbsp; <span class="h6"><i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '3'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'Depresi',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '7',
      formMiddle: '4',
      formRight: '1',
      badge: {
        keterangan: 'Normal',
        color: 'success'
      },
      defVal: {
        puas: 0,
        kegiatan: 0,
        kehidupan: 0,
        bosan: 0,
        punyasemangat: 0,
        takut: 0,
        bahagia: 0,
        tidakberdaya: 0,
        rumah: 0,
        masalah: 0,
        menyenangkan: 0,
        tidakberharga: 0,
        penuhsemangat: 0,
        harapan: 0,
        oranglain: 0,
        jumlahDepresi: 0
      },
      options: {
        pilihanYa: [
          { value: 1, text: 'Tidak' },
          { value: 0, text: 'Ya' }
        ],
        pilihanTidak: [
          { value: 0, text: 'Tidak' },
          { value: 1, text: 'Ya' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    }
  },
  computed: {
    jumlahDepresi () {
      return this.defVal.puas + this.defVal.kegiatan + this.defVal.kehidupan + this.defVal.bosan + this.defVal.punyasemangat + this.defVal.takut + this.defVal.bahagia + this.defVal.tidakberdaya + this.defVal.rumah + this.defVal.masalah + this.defVal.menyenangkan + this.defVal.tidakberdaya + this.defVal.penuhsemangat + this.defVal.harapan + this.defVal.oranglain
    }
  },
  watch: {
    jumlahDepresi (values) {
      this.defVal.jumlahDepresi = values
      if (values <= 3) {
        this.badge.keterangan = 'Normal'
        this.badge.color = 'success'
      } else if (values <= 8) {
        this.badge.keterangan = 'Kemungkinan Besar Depresi'
        this.badge.color = 'warning'
      } else {
        this.badge.keterangan = 'Depresi'
        this.badge.color = 'danger'
      }
    }
  }
}
</script>
<style>
.emr-depresi .radius-custom-emr {
  border-radius: .5rem;
}
.emr-depresi .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-depresi .label-input {
  font-weight: bold;
}
.emr-depresi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-depresi .radius-custom-form-head-depresi {
  border-top-right-radius: .5rem;
}
.emr-depresi .radius-custom-form-border-depresi {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.emr-depresi .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-depresi .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
.emr-depresi .plus-button {
  transition: all 1s;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
.emr-depresi .plus-button:hover .hover {
  background-color: rgba(0, 0, 0, 0.671);
  color:white;
}
.emr-depresi .form-control {
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background: white;
}
.emr-depresi .bg-white {
  background: #ffffff;
}
.emr-depresi .custom-control-label {
  margin-top: 0;
}
.emr-depresi .multiselect__single {
  font-size: 13px !important;
}
.emr-depresi .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-depresi .multiselect__option {
  font-size: 13px;
}
.emr-depresi .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-depresi .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-depresi .body {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
}
.emr-depresi .bg-custom-1 {
  background-color: #b8f4ff;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-depresi .bg-custom-2 {
  background-color: #faffb8;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-depresi .bg-custom-3 {
  background-color: #f4ff5a;
  padding: 5px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.329);
}
.emr-depresi .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
}
.emr-depresi .depresi-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.emr-depresi .depresi-fc-black {
  color: black;
  font-weight: bold;
}
.emr-depresi .table th {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-depresi .cstm-cntrl {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}
.emr-depresi .custom-min-height {
  min-height: 100%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-depresi .custom-min-height1 {
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}
.emr-depresi .custom-flatpicker {
  border: 1px solid rgb(233, 233, 233);
  height: 38px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  border-radius: 4px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}
.emr-depresi .my-cursor {
  cursor: pointer;
}
.emr-depresi .rounded-custom {
  border-radius: 5px;
  border: 1px solid rgb(209, 209, 209);
}
@-webkit-keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
@keyframes cylon-animation {
  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
</style>
