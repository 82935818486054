<template>
  <div class="emr-skrining-paliatif">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formLeft" class="pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="bg-primary p-2 radius-custom-form-head-skrining-paliatif">
            <span class="h6"><i class="fas fa-pen-alt"></i> &nbsp; Form Skrining Palatif</span>
          </div>
          <div class="custom-scroller p-3" :style="{ height: heightPercent }">
            <b-row class="mr-0">
              <b-col md="12">
                <b-row class="ml-1 my-bg">
                  <b-col md="12">
                    <label class="skrining-paliatif-fc-black">Penyakit Dasar :</label>
                  </b-col>
                  <b-col md="12">
                    <b-form-radio-group
                      @change="onChangePenyakitDasar"
                      v-model="defVal.penyakitdasar"
                      :options="options.penyakitdasar" stacked
                    ></b-form-radio-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0">
              <b-col md="12">
                <b-row class="ml-1 my-bg">
                  <b-col md="12">
                    <label class="skrining-paliatif-fc-black">Penyakit Komorbiditas :</label>
                  </b-col>
                  <b-col md="12">
                    <b-form-radio-group
                      @change="onChangeKomorbiditas"
                      v-model="defVal.komorbiditas"
                      :options="options.komorbiditas" stacked
                    ></b-form-radio-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0">
              <b-col md="12">
                <b-row class="ml-1 my-bg">
                  <b-col md="12">
                    <label class="skrining-paliatif-fc-black">Status Fungsional Pasien Menggunakan Performa ECOG (Eastern Cooperative Oncology Group) :</label>
                  </b-col>
                  <b-col md="12">
                    <b-form-radio-group
                      @change="onChangeECOG"
                      v-model="defVal.ecog"
                      :options="options.ecog" stacked
                    ></b-form-radio-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0">
              <b-col md="12">
                <b-row class="ml-1 my-bg">
                  <b-col md="12">
                    <label class="skrining-paliatif-fc-black">Kriteria Lain Tang Perlu Dipertimbangkan :</label>
                  </b-col>
                  <b-col md="12">
                    <b-form-radio-group
                      @change="onChangeKriteria"
                      v-model="defVal.kriterialain"
                      :options="options.kriterialain" stacked
                    ></b-form-radio-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-3 mr-0 align-items-center">
              <b-col offset-md="1">
                <b-card class="border">
                  <b-row no-gutters>
                    <b-col md="1" class="m-3">
                      <h2><i class="fas fa-calculator"></i></h2>
                    </b-col>
                    <b-col md="8" class="text-center">
                      <b-card-body title="Total" class="p-1">
                        <b-card-text>
                          <b-col md="12"><h3>{{ showTotal }}</h3></b-col>
                        </b-card-text>
                      </b-card-body>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col offset-md="1"><h4><b-badge :variant="badge.bg">{{ badge.msg }}</b-badge></h4></b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '4'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> <span class="h6"> &nbsp; <i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '4'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'

export default {
  name: 'SkriningPaliatif',
  components: {
    Riwayat
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '8',
      formRight: '4',
      showTotal: 0,
      badge: {
        bg: 'success',
        msg: 'Tidak Perlu Intervensi Paliatif'
      },
      defVal: {
        penyakitdasar: 0,
        komorbiditas: 0,
        ecog: 0,
        kriterialain: 0
      },
      sum: {
        penyakitdasar: 0,
        komorbiditas: 0,
        ecog: 0,
        kriterialain: 0
      },
      options: {
        penyakitdasar: [
          { value: 1, text: 'Kanker Stadium Lanjut (Metasis atau Rekuren)' },
          { value: 2, text: 'PPOK Lanjut' },
          { value: 3, text: 'Stroke (dengan Penurunan Fungsional Lebih dari 50%)' },
          { value: 4, text: 'Penyakit Ginjal Kronis' },
          { value: 5, text: 'Penyakit Jantung Berat, seperti CHF, Severe CAD, CM (LVEF lebih dari 25%)' }
        ],
        komorbiditas: [
          { value: 1, text: 'Penyakit Hati Kronis' },
          { value: 2, text: 'Penyakit Ginjal Moderate' },
          { value: 3, text: 'PPOK Moderate' },
          { value: 4, text: 'Gagal Jantung Kongestif' },
          { value: 5, text: 'Kondisi / Komplikasi lain' }
        ],
        ecog: [
          { value: 1, text: 'Aktif Penuh, dapat Mekaukan Kegiatan Tanpa Hambatan Seperti Sebelum Ada Penyakit' },
          { value: 2, text: 'Rawat Jalan, Terdapat Hambatan Dalam Aktifitas Berat, Tetapi Dapat Melakukan Pekerjaan Ringan Seperti Pekerjaan Rumah dan Kantor yang Ringan' },
          { value: 3, text: 'Rawat Jalan, Dapat Mengurus Diri Sendiri, Tetapi Tidak Dapat Melakukan Semua Aktifitas, Lebih Dari 50% Jam Bangun' },
          { value: 4, text: 'Dapat Mengurus Diri Sendiri Secara Terbatas, Lebih Banyak Waktunya Ditempat Tidur Atau Di Kursi Roda Dengan Waktu Kurang Dari 50% Jam Bangun' },
          { value: 5, text: 'Tidak Dapat Mengurus Diri Sendiri, Sebagian Besar Waktu Ditempat Tidur Lebih Dari 50% Waktu Tidur, Kondisi Berat / Cacat' }
        ],
        kriterialain: [
          { value: 1, text: 'Tidak Akan Menjalani Pengobatan Kuratif' },
          { value: 2, text: 'Kondisi Penyakit Berat Dan Memilih Untuk Tidak Melanjutkan Terapi' },
          { value: 3, text: 'Nyeri Tidak Teratasi Lebih Dari 24 Jam' },
          { value: 4, text: 'Memiliki Keluhan Psikososial Lain Dan Spiritual Yang Perlu Perhatian' },
          { value: 5, text: 'Sering Berkunjung Ke IGD/ Dirawat Di RS (Lebih Dari 1 Kali/Bulan Untuk Diagnosis Yang Sama)' },
          { value: 6, text: 'Lebih Dari Satu Kali Untuk Diagnosis Yang Sama Dalam 30 hari' },
          { value: 7, text: 'Memiliki Lama Perawatan Tanpa Tanpa Kemajuan Yang Bermakna' },
          { value: 8, text: 'Lama Rawat Yang Panjang Di ICU Tanpa Kemajuan' },
          { value: 9, text: 'Memiliki Prognosis Yang Jelek' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  computed: {
    total () {
      return this.sum.penyakitdasar + this.sum.komorbiditas + this.sum.ecog + this.sum.kriterialain
    }
  },
  watch: {
    total (values) {
      this.showTotal = values
      if (values <= 1) {
        this.badge.bg = 'success'
        this.badge.msg = 'Tidak Perlu Intervensi Paliatif'
      } else if (values <= 4) {
        this.badge.bg = 'warning'
        this.badge.msg = 'Observasi'
      } else {
        this.badge.bg = 'danger'
        this.badge.msg = 'Perlu Konsultasi Tim Paliatif'
      }
    }
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (35 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '11'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '8'
      this.formRight = '4'
    },
    onChangePenyakitDasar () {
      this.sum.penyakitdasar = 1
    },
    onChangeKomorbiditas () {
      this.sum.komorbiditas = 1
    },
    onChangeECOG (values) {
      if (values === 1 || values === 2) {
        this.sum.ecog = 1
      } else if (values === 3) {
        this.sum.ecog = 2
      } else if (values === 4) {
        this.sum.ecog = 3
      } else if (values === 5) {
        this.sum.ecog = 4
      }
    },
    onChangeKriteria () {
      this.sum.kriterialain = 1
    }
  }
}
</script>

<style>
.emr-skrining-paliatif .custom-scroller {
  position: relative;
  overflow-y: auto;
}
.emr-skrining-paliatif .radius-custom-emr {
  border-radius: .5rem;
}
.emr-skrining-paliatif .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-skrining-paliatif .my-bg {
  background-color: #b8f4ff;
  padding: 3px;
  border-radius: 5px;
}
.emr-skrining-paliatif .skrining-paliatif-fc-black {
  color: black;
  font-weight: bold;
}
.emr-skrining-paliatif .skrining-paliatif-form-input {
  font-size: 9;
  height: 25px!important;
  border-radius: 5px;
}
.emr-skrining-paliatif .radius-custom-form-head-skrining-paliatif {
  border-top-right-radius: .5rem;
}
.emr-skrining-paliatif .table td {
  font-size: 9px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.emr-skrining-paliatif .table th {
  font-size: 10px;
  font-weight: bold;
}
.emr-skrining-paliatif .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-skrining-paliatif .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
