<template>
  <div>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab>
          <template #title>
            Rencana dan Terapi terukur
          </template>
          <div class="p-2">
            <RencanaTerapiTerukur />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Kontrol
          </template>
          <div class="p-2">
            <Kontrol />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Jadwal Operasi
          </template>
          <div class="p-2">
            <JadwalOperasi />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Rawat Inap
          </template>
          <div class="p-2">
            <RawatInap />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import RencanaTerapiTerukur from '../../ComponentSub/RekamMedis/Perencanaan/RencanaTerapiTerukur/RencanaTerapiForm.vue'
import Kontrol from '../../ComponentSub/RekamMedis/Perencanaan/Kontrol/KontrolForm.vue'
import JadwalOperasi from '../../ComponentSub/RekamMedis/Perencanaan/JadwalOperasi/JadwalOperasiForm.vue'
import RawatInap from '../../ComponentSub/RekamMedis/Perencanaan/RawatInap/RawatInapForm.vue'

export default {
  name: 'Perencanaan',
  components: {
    RencanaTerapiTerukur,
    Kontrol,
    RawatInap,
    JadwalOperasi
  },
  mounted () {
    xray.index()
  }
}
</script>
