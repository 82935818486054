<template>
  <div>
    <b-card end>
      <b-tabs pills end>
        <b-tab title="Perjalanan Penyakit"><PerjalananPenyakit class="mb-2" /></b-tab>
        <b-tab title="Alergi"><Alergi class="mb-2" /></b-tab>
        <b-tab title="Pemberian Obat"><PemberianObat class="mb-2" /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { xray } from '../../../../../../../config/pluginInit'
import PerjalananPenyakit from '../../../../RekamMedis/Anamnesis/RPD/PerjalananPenyakit/PerjalananPenyakitForm.vue'
import Alergi from '../../../../RekamMedis/Anamnesis/RPD/Alergi/AlergiForm.vue'
import PemberianObat from '../../../Anamnesis/RPD/PemberianObat/PemberianObatForm.vue'

export default {
  name: 'Tab',
  components: {
    PerjalananPenyakit,
    Alergi,
    PemberianObat
  },
  mounted () {
    xray.index()
  }
}
</script>
