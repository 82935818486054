<template>
  <div>
    <b-card end>
      <b-tabs class="p-2" end pills>
        <!-- <b-tab title="Order"><Maintenance class="mb-2" /></b-tab> -->
        <b-tab title="Riwayat"><Riwayat class="mb-2" /></b-tab>
        <b-tab title="Konsul"><Maintenance class="mb-2" /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { xray } from '../../../../config/pluginInit'
import Riwayat from './Riwayat.vue'
import Maintenance from '../../../Pages/Maintenance.vue'

export default {
  name: 'Tab',
  components: {
    Maintenance,
    Riwayat
  },
  mounted () {
    xray.index()
  }
}
</script>
