<template>
  <div>
    <b-card end>
      <b-tabs pills end>
        <b-tab title="Status Pediatric"><StatusPediatric class="mb-2" /></b-tab>
        <b-tab title="Riwayat Kelahiran"><RiwayatKelahiranForm class="mb-2" /></b-tab>
        <b-tab title="Riwayat Imunisasi"><RiwayatImunisasiForm class="mb-2" /></b-tab>
        <b-tab title="Riwayat Psikososial Anak"><RPAForm class="mb-2" /></b-tab>
        <b-tab title="Riwayat Tumbuh Kembang Anak"><RTKAForm class="mb-2" /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { xray } from '../../../../../../../config/pluginInit'
import StatusPediatric from '../Pediatric/StatusPediatricForm.vue'
import RiwayatKelahiranForm from '../RiwayatKelahiran/RiwayatKelahiranForm.vue'
import RiwayatImunisasiForm from '../RiwayatImunisasi/RiwayatImunisasiForm.vue'
import RPAForm from '../RPA/RPAForm.vue'
import RTKAForm from '../RTKA/RTKAForm.vue'

export default {
  name: 'Tab',
  components: {
    StatusPediatric,
    RiwayatKelahiranForm,
    RiwayatImunisasiForm,
    RPAForm,
    RTKAForm
  },
  mounted () {
    xray.index()
  }
}
</script>
