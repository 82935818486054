<template>
  <div class="emr-cppt">
    <b-row class="justify-content-between m-2">
      <b-col cols="12" :lg="formLeft" class="pl-1 pr-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-form-border-cppt">
            <b-row>
              <b-col md="11"><span class="h6"><i class="fas fa-notes-medical"></i> &nbsp; Catatan Perkembangan Pasien Terintegrasi</span></b-col>
              <b-col md="1" class="text-end"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buat CPPT Baru'"><i class="fas fa-plus"></i></span></b-col>
            </b-row>
          </div>
          <div class="custom-scroller p-3 my-bg-white" :style="{height: heightPercent}">
            <b-row class="mr-0 align-items-center">
              <b-col md="4">
                <div class="bg-input-white">
                  <b-form-group class="cppt-form-input">
                    <multiselect :options="options.profesi" v-model="selected.profesi" label="deskripsi" placeholder="Tenaga Medis">
                    </multiselect>
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="8">
                <div class="bg-input-white">
                  <b-form-group class="cppt-form-input">
                    <multiselect :options="options.verifProfesi" v-model="selected.verifProfesi" label="deskripsi"></multiselect>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row class="mr-0 align-items-center">
              <b-col md="2" class="cppt-fc-black">Tgl & Waktu : </b-col>
              <b-col md="7"><div class="bg-input-white"><b-form-input class="w-15 cppt-form-input"></b-form-input></div></b-col>
              <b-col md="3"><div class="bg-input-white"><b-form-input class="w-15 cppt-form-input"></b-form-input></div></b-col>
            </b-row>
            <b-row class="mt-2 mr-0 align-items-center">
              <b-col md="3">
                <div class="bg-input-white">
                  <b-form-group class="cppt-form-input">
                    <multiselect :options="options.verifProfesi" v-model="selected.verifProfesi" label="deskripsi" placeholder="Tenaga Medis">
                    </multiselect>
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="4">
                <div class="bg-input-white">
                  <b-form-group class="cppt-form-input">
                    <multiselect :options="options.verifProfesi" v-model="selected.verifProfesi" label="deskripsi"></multiselect>
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="2"><div><b-form-checkbox><span class="cppt-fc-black">Status Verifikasi</span></b-form-checkbox></div></b-col>
              <b-col md="3"><div class="bg-input-white"><b-form-input class="w-15 cppt-form-input"></b-form-input></div></b-col>
            </b-row>
            <b-row class="mt-2 mr-0 align-items-center">
              <b-col cols="12" class="cppt-fc-black">Subyektif : </b-col>
              <b-col>
                <div class="bg-input-white">
                  <b-form-textarea
                    cols="2"
                    no-resize
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0 align-items-center">
              <b-col cols="12" class="cppt-fc-black">Obyektif : </b-col>
              <b-col>
                <div class="bg-input-white">
                  <b-form-textarea
                    cols="2"
                    no-resize
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0 align-items-center">
              <b-col cols="12" class="cppt-fc-black">Assesment : </b-col>
              <b-col>
                <div class="bg-input-white">
                  <b-form-textarea
                    cols="2"
                    no-resize
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0 align-items-center">
              <b-col cols="12" class="cppt-fc-black">Planning : </b-col>
              <b-col>
                <div class="bg-input-white">
                  <b-form-textarea
                    cols="2"
                    no-resize
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2 mr-0 align-items-center">
              <b-col cols="12" class="cppt-fc-black">Instruksi : </b-col>
              <b-col>
                <div class="bg-input-white">
                  <b-form-textarea
                    cols="2"
                    no-resize
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :lg="formMiddle" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span></span> &nbsp; <span class="h6"><i class="far fa-edit"></i> &nbsp;  Catatan Medis Kunjungan Terkini</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :lg="formRight" class="pl-1">
        <div class="border radius-custom-emr w-100 h-100">
          <div class="arrow bg-primary p-2 radius-custom-emr-head">
            <span @click="closeRightForm" v-if="formRight === '3'"><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Tutup Riwayat'"><i class="fas fa-arrow-right"></i></span> &nbsp; <span class="h6"><i class="far fa-lg fa-sticky-note"></i> &nbsp;  Catatan Medis</span></span>
            <span @click="openRightForm" v-else><span class="px-2 py-1 rounded shadow-sm hover" v-b-tooltip.hover.topright="'Buka Riwayat'"><i class="fas fa-arrow-left"></i></span>  &nbsp; Lihat</span>
          </div>
          <div class="custom-scroller p-2" :style="{height: heightPercent}">
            <div class="d-flex flex-row" v-if="formRight === '3'">
              <div class="h-100">
                <Riwayat/>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center" v-else>
              <div class="h-100">
                <p class="h6 text-secondary">Riwayat</p>
                <i class="fas fa-history text-secondary fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../../config/pluginInit'
import Riwayat from './Riwayat/CatatanMedis.vue'
import Multiselect from 'vue-multiselect'
import Services from '../../../../../services/fatmahost/master/index'

export default {
  name: 'CPPT',
  components: {
    Riwayat,
    Multiselect
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formLeft: '7',
      formMiddle: '4',
      formRight: '1',
      selected: {
        profesi: [],
        verifProfesi: []
      },
      options: {
        profesi: [],
        verifProfesi: []
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
    this.getJenisPetugasMedis()
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (40 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formLeft = '7'
      this.formMiddle = '4'
      this.formRight = '1'
    },
    openRightForm () {
      this.formLeft = '6'
      this.formMiddle = '3'
      this.formRight = '3'
    },
    async getJenisPetugasMedis () {
      const response = await Services.referensi(32)
      this.options.profesi = response.data.data
      this.options.verifProfesi = response.data.data
    }
  }
}
</script>
<style>
.emr-cppt {
  background-color: rgb(255, 255, 255);
}
.emr-cppt .my-bg-white {
  background-color: #eff7f9;
}
.emr-cppt .custom-scroller {
  position:relative;
  overflow-y:auto;
}
.emr-cppt .cppt-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
.bg-input-white {
  background-color: white;
}
.emr-cppt .cppt-fc-black {
  color: black;
  font-weight: bold;
}
.emr-cppt .radius-custom-emr {
  border-radius: .5rem;
}
.emr-cppt .custom-scroller {
  position:relative;
  overflow-y:auto;
  height:300px;
}
.emr-cppt .radius-custom-form-border-cppt {
  border-top-right-radius: .5rem;
}
.emr-cppt .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.emr-cppt .multiselect__single {
  font-size: 13px !important;
}
.emr-cppt .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}
.emr-cppt .multiselect__option {
  font-size: 13px;
}
.emr-cppt .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-cppt .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
.emr-cppt .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}
.emr-cppt .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
