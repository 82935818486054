<template>
  <div id="stock-opname-view" class="stock-opname-view">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid class="mb-4">
      <iq-card class="mb-2 py-2">
        <div  class="iq-email-to-list pt-3 pl-3 pr-3" id="header-table-filter">
          <div class="px-2 py-2 border d-flex align-items-center justify-content-between">
            <div class="py-1 mr-auto">
              <p class="text-dark font-weight-bold text-uppercase h6 mb-0" v-if="title !== ''">{{ title }}</p>
              <p class="text-secondary font-weight-bold text-uppercase h6 mb-0" v-else>............</p>
            </div>
            <div class="mr-auto">
              <div :class="['w-100 d-flex flex-row justify-content-between shadow-sm px-3 py-1 mx-1', messageVal === '1' ? 'bg-success text-white' : 'bg-danger text-white']" v-if="message">
                <p class="text-center font-weight-bold small text-uppercase mb-0">{{ message }}</p>
                <p class="text-center font-weight-bold small text-uppercase mb-0" @click="clearMessage"><i class="fas fa-times-circle fa-lg"></i></p>
              </div>
            </div>
            <div @click="backList">
              <b-button variant="warning" class="py-1" style="border-radius: 5px;"><i class="fas fa-undo"></i> Kembali</b-button>
            </div>
          </div>
        </div>
        <!-- <div class="pl-3 pr-3 py-2">
          <b-row class="align-items-center">
            <b-col cols="3" class="pr-1">
              <b-form-group class="mb-0">
                <multiselect  v-model="selected.ruangan" :show-labels="false" :allow-empty="false" :disabled="stockopname.header.ver_status === '1' ? true : false"
                  @input="valRuang($event, 't')" label="deskripsi" placeholder="[Ruangan]" :options="combo.ruangan"
                  :searchable="true">
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col cols="1" class="pr-1 pl-1">
              <b-form-group class="mb-0">
                <b-form-input :disabled="stockopname.header.ver_status === '1' ? true : false" class="cstm-cntrl-stockopname" v-model="stockopname.header.berkas_id" type="text" placeholder="[ID Berkas]"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="pr-1 pl-1">
              <b-form-group class="mb-0">
                <flat-pickr :disabled="stockopname.header.ver_status === '1' ? true : false" :config="configTgl" @on-change="valueTgl" v-model="display.tanggal" value="" placeholder="[Tanggal]" class="w-100 custom-flatpicker calender-small" />
              </b-form-group>
            </b-col>
            <b-col cols="4" class="pr-1 pl-1">
              <b-form-group class="mb-0">
                <b-form-input :disabled="stockopname.header.ver_status === '1' ? true : false" class="cstm-cntrl-stockopname" v-model="stockopname.header.keterangan" type="text" placeholder="[Keterangan]"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="text-right pl-4 pr-4">
              <div>
                <b-form-group class="mb-0">
                  <b-form-checkbox id="checkbox-200" v-model="stockopname.header.ver_status" @change="validasi"
                    name="checkbox-1" value="1" unchecked-value="0">Kunci Form
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <hr class="mb-1 mt-2 mx-0">
          </div> -->
          <div class="iq-email-listbox pl-3 pr-3 pb-3">
            <div class="table mb-0">
              <b-table
                class="mb-0 pointer-table"
                ref="table"
                :busy="table.isBusy"
                :items="table.items"
                :fields="table.fields"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                :filter="table.filter"
                @filtered="onFiltered"
                :sort-by.sync="table.sortBy"
                :sort-desc.sync="table.sortDesc"
                stacked="md"
                bordered hover show-empty small striped>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark">Memuat Data...</strong>
                  </div>
                </template>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(tgl_expired)="data">
                  <span>{{ new Date(tglWithDots(data.item)) | formatDate }}</span>
                </template>
                <template #cell(jumlah_adm)="data">
                  <span>{{ formatRibuan(data.item.jumlah_adm) }}</span>
                </template>
                <template #cell(jumlah_fisik)="data">
                  <span>{{ formatRibuan(data.item.jumlah_fisik) }}</span>
                </template>
                <template #thead-top="data">
                  <!-- <b-tr>
                    <b-th variant="white" class="py-2">Nama Barang</b-th>
                    <b-th variant="white" class="py-2">Pabrik</b-th>
                    <b-th variant="white" class="py-2">Jumlah Adm</b-th>
                    <b-th variant="white" class="py-2">Jumlah Fisik</b-th>
                    <b-th variant="white" class="py-2">Tanggal Expired</b-th>
                    <b-th variant="white" class="py-2 text-center">Aksi</b-th>
                  </b-tr> -->
                  <b-tr v-for="(k, index) in stockopname.detail" v-bind:key="k.codex">
                    <b-td colspan="3" class="col-3 py-2 px-2">
                      <div class="w-100">
                        <multiselect ref="vms" v-if="k.id === '0'" v-model="selected.pabrik[index].detail" :show-labels="false" :allow-empty="false" :disabled="k.id != '0' || stockopname.header.ver_status === '1' || bitcontrol2_group.read === '1' || bitcontrol2_group.verif === '1' ? true : false"
                          label="namaBarang" track-by="id_katalog" placeholder="[Barang]" :options="combo.pabrik" :custom-label="nameWithKode" @input="valuepabrik($event, index, '2')" @search-change="getPabrik"
                          :searchable="true">
                        </multiselect>
                        <div class="bg-abu label-brg py-1" v-else>
                          <span>{{ display.kodeName[index].kode }} - {{ display.kodeName[index].nama }}</span>
                        </div>
                      </div>
                    </b-td>
                    <b-td class="col-3">
                      <div class="d-flex flex-row">
                        <div class="w-75 disable-bg border border-dark alert-radius-stockopname py-1 px-2 mr-1">
                        <p class="mb-0" v-if="display.namabarang[index].nama">{{ display.namabarang[index].nama }}</p>
                        <p class="mb-0" v-else>...</p>
                      </div>
                      <div class="w-25 disable-bg border border-dark alert-radius-stockopname py-1 px-2">
                        <p class="text-center mb-0" v-if="display.hargabarang[index].value">{{ display.hargabarang[index].value }}</p>
                        <p class="mb-0" v-else>...</p>
                      </div>
                      </div>
                    </b-td>
                    <b-td>
                      <div class="w-100">
                        <b-form-input disabled class="cstm-cntrl-stockopname text-center" v-model="stockopname.detail[index].jumlah_adm" @keypress="isNumberKey" type="text" placeholder="Jumlah Adm"></b-form-input>
                      </div>
                    </b-td>
                    <b-td colspan="3">
                      <div class="w-100">
                        <b-form-input ref="vmz" :disabled="k.id != '0' || stockopname.header.ver_status === '1' || bitcontrol2_group.read === '1' || bitcontrol2_group.verif === '1' ? true : false" class="cstm-cntrl-stockopname text-center" v-model="stockopname.detail[index].jumlah_fisik" @keypress="isNumberKey" @keyup.enter="moveTovmx(index)" @click="enterfisik(index)" type="text" placeholder="Jumlah Fisik"></b-form-input>
                      </div>
                    </b-td>
                    <b-td class="w-100 col-2">
                      <div class="d-flex flex-row">
                        <div class="mr-1">
                          <b-form-input ref="vmx" :disabled="k.id != '0' || stockopname.header.ver_status === '1' || bitcontrol2_group.read === '1' || bitcontrol2_group.verif === '1' ? true : false" class="cstm-cntrl-stockopname" maxlength="4" v-model="display.tglInp[index].value" @keyup.enter="generatetgl(index)" @keypress="isNumberKey" @click="enterinptgl(index)" type="text" placeholder="Inp Tgl"></b-form-input>
                        </div>
                        <div >
                          <flat-pickr ref="vmc" :config="configTgl" @on-change="valueTglDetail($event, index)" value="" v-model="display.tglExp[index].date" placeholder="Tanggal" class="w-100 custom-flatpicker calender-small" />
                        </div>
                      </div>
                    </b-td>
                    <b-td colspan="2">
                      <div class="text-center w-100">
                        <b-button ref="vmy" :class="classFcs === true ? 'btn-active-focus' : ''" variant="primary" size="sm" @click="handleUpdate">
                          <p class="h6 mb-0"><i class="fas fa-save"></i></p>
                        </b-button>
                      </div>
                    </b-td>
                  </b-tr>
                </template>
                <template #cell(ver_status)="data">
                  <span class="text-secondary" v-if="data.item.ver_status === 1 || data.item.ver_status === '1'"><i class="fas fa-check-circle text-success fa-lg"></i></span>
                  <span v-else-if="data.item.ver_status === 0 || data.item.ver_status === '0'"><i class="fas fa-spinner text-secondary fa-lg"></i></span>
                </template>
                <template #cell(action)="data">
                  <b-button :disabled="stockopname.header.ver_status === '1' ? true : false" variant="mr-1 mb-1" size="lg" @click="deleteModal(data.item.id)">
                    <i :class="['fas fa-trash', stockopname.header.ver_status === '1' ? 'text-secodary' : 'text-danger']"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <!-- <pre>{{ stockopname.detail }}</pre> -->
            <div class="pagination-inventory border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                      size="md" class="my-0"></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-stock" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-stock" v-model="selected.filterType" :options="options.optionsFilter"
                        @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-right align-items-center justify-content-end">
                    <div class="mr-2">
                      <b-form-select id="per-page-select" class="custom-perpage" v-model="table.perPage" :options="table.pageOptions" size="sm"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- <pre>{{ stockopname }}</pre> -->
            <!-- <pre>{{ display }}</pre> -->
          </div>
      </iq-card>
    </b-container>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-success font-weight-bold mb-0">{{ message }}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger font-weight-bold mb-2">Data Gagal Disimpan</p>
        <p class="small text-danger font-weight-light mb-0">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-konfirm-delete-hard" size="md" title="KONFIRMASI HAPUS" ok-variant="danger" ok-title="Hapus" @ok="handleDelete" @close="clearMessage" @cancel="clearMessage" :no-close-on-backdrop="true" :static="true"
    cancel-title="Batal" centered>
      <div class="px-1 py-2">
        <div class="text-center ">
          <i class="fas fa-exclamation-circle text-danger fa-5x"></i>
          <p class="h6 text-danger mb-0 mt-3">Anda Yakin Akan Menghapus Barang ?</p>
        </div>
        <div class="bg-dark px-2 mt-2" v-if="error">
          <p class="small text-white mb-0">{{ error }}</p>
        </div>
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Loader from '../../components/xray/loader/Loader'
// import jwtdecode from 'jwt-decode'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import Service from '../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'
import ModalMsgbox from '../../components/modal/ModalMsgBox'
// import { mapGetters } from 'vuex'
export default {
  name: 'StockOpname',
  components: {
    ModalMsgbox,
    Multiselect,
    Lottie,
    flatPickr,
    Loader
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.listStockEditSO,
        fields: [
          { label: 'NO', key: 'index', class: 'text-center' },
          { label: 'KODE', key: 'kode' },
          { label: 'NAMA BARANG', key: 'nama_barang' },
          { label: 'PABRIK', key: 'deskripsi' },
          // { label: 'LABEL', key: 'label' },
          { label: 'JUMLAH ADM', key: 'jumlah_adm', class: 'text-center' },
          { label: 'JUMLAH FISIK', key: 'jumlah_fisik', class: 'text-center' },
          { label: 'HARGA BELI', key: 'harga_beli', class: 'text-center' },
          { label: 'NILAI', key: 'nilai', class: 'text-center' },
          { label: 'TANGGAL EXPIRED', key: 'tgl_expired' },
          { label: 'USER', key: 'name' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        pageOptions: [5, 10, 25, 50, 100, 500],
        perPage: 5,
        totalRows: null
      },
      combo: {
        instalasi: [],
        unit: [],
        ruangan: [],
        jenis: [
          { value: 1, text: 'Reservasi Online' },
          { value: 2, text: 'Reservasi Offline' }
        ],
        filterType: [
          { value: '1', text: 'Filter Dokter' },
          { value: '2', text: 'Filter Ruangan' }
        ],
        filterRuang: [
          { value: '', text: 'Rawat Jalan' }
        ],
        pabrik: [],
        status: [
          { value: '1', text: 'Aktif' },
          { value: '0', text: 'tidak Aktif' }
        ]
      },
      options: {
        optionsFilter: [
          { text: 'Kode Barang', value: '1' },
          { text: 'Nama Barang', value: '2' }
        ]
      },
      selected: {
        instalasi: [],
        unit: [],
        ruangan: [],
        kode: [{
          value: ''
        }],
        pabrik: [{
          detail: []
        }],
        filterType: '1'
      },
      id: null,
      ruangan: '',
      stockopname: {
        object: 'stok-opname',
        header: {
          ruangan_id: '',
          berkas_id: '',
          tanggal: '',
          keterangan: '',
          sts_batal: '0',
          inp_tanggal: this.getDateAuto(),
          inp_user: xray.currentUser().id.toString(),
          ver_tanggal: '',
          ver_user: '',
          ver_status: '',
          close_status: '0'
        },
        detail: [
          {
            id: '0',
            id_katalog: '',
            id_pabrik: '',
            jumlah_adm: '0',
            jumlah_fisik: '',
            tgl_expired: '',
            sts_batal: '0',
            userid_in: xray.currentUser().id.toString()
          }
        ]
      },
      bitcontrol1_access: {
        show: '0',
        add: '0',
        edit: '0',
        delete: '0'
      },
      bitcontrol2_group: {
        read: '0',
        entry: '0',
        verif: '0',
        admin: '0'
      },
      display: {
        displayruangan: '',
        stockopnamekeluaron: '',
        stockopnamekeluaroff: '',
        viewTgltmbh: '',
        viewTglEdit: '',
        viewTglAwal: '',
        viewTglAkhir: '',
        kodeName: [
          {
            kode: '',
            nama: ''
          }
        ],
        tglInp: [
          {
            value: ''
          }
        ],
        tglExp: [
          {
            date: ''
          }
        ],
        namabarang: [
          {
            nama: ''
          }
        ],
        hargabarang: [
          {
            value: ''
          }
        ],
        kodedetail: [
          {
            kode: ''
          }
        ],
        tanggal: ''
      },
      filtering: {
        valTglAwal: '',
        valTglAkhir: ''
      },
      preview_id: '',
      status: 'not_accepted',
      statusEdit: 'not_accepted',
      valuedisable: false,
      valuedisable2: false,
      error: '',
      message: '',
      isLoading: false,
      messageVal: '',
      messageTgl: '',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      configTgl: {
        locale: IndonesianLocale,
        disableMobile: true,
        dateFormat: 'd-m-Y'
      },
      title: '',
      classFcs: false
    }
  },
  mounted () {
    xray.index()
    this.preview_id = this.$route.params.id
    this.stockopname.header.ruangan_id = this.$route.query.rgnId
    this.stockopname.header.berkas_id = this.$route.query.berkasId.toString()
    this.stockopname.header.tanggal = this.$route.query.tanggal
    this.stockopname.header.keterangan = this.$route.query.keterangan
    this.getruangan()
    this.getbitcontrol()
    // this.handleShowDtl()
  },
  methods: {
    backList () {
      this.$router.push({ name: 'farmasi.stockopname' })
    },
    moveTovms () {
      this.$refs.vms[0].activate()
    },
    moveTovmz () {
      this.$refs.vmz[0].focus()
    },
    moveTovmx (index) {
      if (this.stockopname.detail[index].jumlah_fisik !== '') {
        this.$refs.vmx[0].focus()
      }
    },
    enterfisik (index) {
      if (this.stockopname.detail[index].id_pabrik === '') {
        this.messageVal = '0'
        this.message = 'Barang Harus Dipilih'
      }
    },
    enterinptgl (index) {
      if (this.stockopname.detail[index].jumlah_fisik === '') {
        this.messageVal = '0'
        this.message = 'Jumlah Fisik Harus Diisi'
      }
    },
    moveTovmc () {
      // this.$refs.vmc[0].$el._flatpickr.open()
      this.$refs.vmc[0].focus()
    },
    moveTovmy () {
      this.$refs.vmy[0].focus()
    },
    getbitcontrol () {
      // let access = {
      //   bitcontrol1: '0011110000000000',
      //   bitcontrol2: '3000000000000000'
      // }
      let access = xray.bitcontrol(this.$route.name)
      // bit control 1
      if (access.bitcontrol1.charAt(2) === '1') {
        this.bitcontrol1_access.add = '1'
      }
      if (access.bitcontrol1.charAt(3) === '1') {
        this.bitcontrol1_access.show = '1'
      }
      if (access.bitcontrol1.charAt(4) === '1') {
        this.bitcontrol1_access.edit = '1'
      }
      // bit control 2
      if (access.bitcontrol2.charAt(0) === '0') {
        this.bitcontrol2_group.read = '1'
      } else if (access.bitcontrol2.charAt(0) === '1') {
        this.bitcontrol2_group.entry = '1'
      } else if (access.bitcontrol2.charAt(0) === '2') {
        this.bitcontrol2_group.verif = '1'
      } else if (access.bitcontrol2.charAt(0) === '3') {
        this.bitcontrol2_group.admin = '1'
      }
    },
    formatRibuan (value) {
      if (value !== null || value !== '' || value) {
        const amount = value
        return amount.toLocaleString()
      } else {
        return '0'
      }
    },
    nameWithKode ({ kode, namaBarang }) {
      return `${kode} - ${namaBarang}`
    },
    filterchange () {
      this.table.filter = ''
    },
    // async handleShowDtl () {
    //   this.id = this.preview_id
    //   try {
    //     const response = await Service.katalog.showStockOpname(this.preview_id)
    //     if (response.data.statcode === 0) {
    //       this.stockopname.header.ruangan_id = response.data.data[0].ruangan_id !== null ? response.data.data[0].ruangan_id.toString() : ''
    //       this.stockopname.header.berkas_id = response.data.data[0].berkas_id !== null ? response.data.data[0].berkas_id.toString() : ''
    //       this.stockopname.header.keterangan = response.data.data[0].keterangan !== null ? response.data.data[0].keterangan : ''
    //       this.stockopname.header.ver_status = response.data.data[0].ver_status !== null ? response.data.data[0].ver_status.toString() : ''
    //       this.stockopname.header.ver_user = response.data.data[0].ver_user !== null ? response.data.data[0].ver_user.toString() : ''
    //       this.stockopname.header.close_status = response.data.data[0].close_status !== null ? response.data.data[0].close_status.toString() : ''
    //       this.stockopname.header.sts_batal = response.data.data[0].sts_batal !== null ? response.data.data[0].sts_batal.toString() : ''
    //       this.stockopname.header.tanggal = this.valueTgl(response.data.data[0].tanggal)
    //       this.stockopname.header.inp_tanggal = this.valueTgl(response.data.data[0].inp_tanggal)
    //       this.title = response.data.data[0].deskripsi
    //       if (response.data.data[0].tanggal !== null) {
    //         this.display.tanggal = this.getDateAuto(response.data.data[0].tanggal)
    //       }
    //       this.selected.ruangan = { id: response.data.data[0].ruangan_id, deskripsi: response.data.data[0].deskripsi }
    //       this.listStockEditSO()
    //     } else {
    //       this.error = response.data.message
    //       this.$refs.table.refresh()
    //     }
    //   } catch (error) {
    //     this.error = error.message
    //     this.$refs.table.refresh()
    //   }
    //   this.$refs.table.refresh()
    //   // this.$refs['modal-stokopname-edit'].show()
    // },
    async listStockEditSO (ctx) {
      this.table.isBusy = true
      let id = this.preview_id.toString()
      let userid = xray.currentUser().id.toString()
      let filtertype = this.selected.filterType
      let obj = 'x'
      if (ctx.filter === null) {
        ctx.filter = ''
      }
      try {
        this.table.isBusy = false
        let response = await Service.katalog.showStockOpname(id, ctx.currentPage - 1, ctx.perPage, userid, filtertype, ctx.filter, obj)
        this.selected.ruangan = { id: response.data.data[0].ruangan_id, deskripsi: response.data.data[0].deskripsi }
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        this.title = response.data.data[0].label
        return response.data.data
      } catch (e) {
        this.table.isBusy = false
        return []
      }
    },
    generatetgl (index) {
      var a = this.display.tglInp[index].value
      if (a.substring(0, 2) <= 12 && a !== '') {
        if (a === '0000') {
          var _a = new Date()
          var _b = _a.getFullYear() + 1
          var _c = '31' + '-' + '12' + '-' + _b
          this.display.tglExp[index].date = _c
          this.moveTovmy()
        } else {
          var b = a.replace(/(..)(..)/, '$1-$2')
          var c = b.replace(/-/g, '-20')
          var d = '01' + '-' + c
          var e = d.split('-')
          var e1 = e[0]
          var e2 = e[1]
          var e3 = e[2]
          var f = new Date(e2 + '-' + e1 + '-' + e3)
          f.setDate(1)
          f.setMonth(f.getMonth() + 1)
          f.setDate(f.getDate() - 1)
          var lastDayOfMonth = f.getDate()
          var match = lastDayOfMonth + '-' + e2 + '-' + e3
          this.display.tglExp[index].date = match
          this.moveTovmy()
          this.classFcs = true
        }
      } else {
        this.messageVal = '0'
        this.message = 'Format Tanggal Salah Atau Belum Diisi'
      }
    },
    async getharga (ids) {
      let id = ids
      await Service.katalog.getHargaStokOP(id).then(response => {
        if (response.data.statcode === 0) {
          this.display.hargabarang[0].value = this.formatRibuan(response.data.kode)
        } else {
          this.messageVal = '0'
          this.message = 'Harga' + ' ' + response.data.message
          this.display.hargabarang[0].value = '0'
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    generatetglResponse (value) {
      var a = value.replace(/^.{2}/, '')
      var b = a.split('-').reverse().join('-')
      var c = b.replace(/-/g, '')
      var d = c.replace(/^.{2}/, '')
      return d
    },
    getDateAuto (value) {
      const date = value === undefined ? new Date() : new Date(value)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      if (month < 10) {
        month = '0' + month
      }
      if (value === undefined) {
        return year + '-' + month + '-' + day
      } else {
        return day + '-' + month + '-' + year
      }
    },
    valueTgl (value) {
      if (value !== null) {
        const date = new Date(value)
        let day = date.getDate()
        let month = (date.getMonth() + 1).toString().padStart(2, 0)
        let year = date.getFullYear()
        if (day < 10) {
          day = '0' + day
        }
        let currentDate = year + '-' + month + '-' + day
        return currentDate
      } else {
        return ''
      }
    },
    valueTglDetail ($event, index) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      let currentDate = year + '-' + month + '-' + day
      this.stockopname.detail[index].tgl_expired = currentDate
      // this.display.tglInp[index].value = month.toString() + (year.toString()).replace(/^.{2}/, '')
    },
    validasi (param) {
      if (param === '1') {
        this.stockopname.header.ver_user = xray.currentUser().id.toString()
        this.stockopname.header.ver_status = '1'
        this.stockopname.header.ver_tanggal = this.getDateAuto()
      } else if (param === '0') {
        this.stockopname.header.ver_user = ''
        this.stockopname.header.ver_status = ''
        this.stockopname.header.ver_tanggal = ''
      }
    },
    valuepabrik ($event, index, param) {
      this.clearNotBrg()
      this.classFcs = false
      this.message = ''
      this.stockopname.detail[index].id_katalog = $event.id_katalog.toString()
      this.stockopname.detail[index].id_pabrik = $event.id_pabrik.toString()
      this.display.namabarang[index].nama = $event.nama_pabrik
      this.display.kodedetail[index].kode = $event.kode
      this.getharga($event.id_katalog.toString())
      if (param === '1') {
        this.selected.pabrik[index].detail = { id: $event.id_pabrik, namaBarang: $event.namaBarang }
      } else if (param === '2') {
        this.selected.kode[index].value = { id: $event.id_pabrik, kode: $event.kode }
      }
      this.moveTovmz()
    },
    async handleUpdate () {
      this.isLoading = true
      if (this.stockopname.detail[0].id_pabrik === '' || this.stockopname.detail[0].jumlah_fisik === '' || this.stockopname.detail[0].tgl_expired === '' || this.stockopname.detail[0].tgl_expired === 'NaN-NaN-NaN') {
        this.messageVal = '0'
        this.message = 'Form Input Belum Lengkap'
      } else {
        try {
          const id = this.preview_id
          const data = this.stockopname
          const response = await Service.katalog.updateStockOpname(id, data)
          if (response.data.statcode === 0) {
            this.isLoading = false
            this.messageVal = '1'
            this.message = response.data.message
            this.$refs.table.refresh()
            this.clearDetail()
            this.moveTovms()
            this.classFcs = false
          } else {
            this.isLoading = false
            this.messageVal = '0'
            this.message = response.data.message
          }
          this.$refs.table.refresh()
        } catch (error) {
          this.isLoading = false
          this.messageVal = '0'
          this.message = error.message
          this.$refs.table.refresh()
        }
      }
    },
    deleteModal (id) {
      this.id = id
      this.$refs['modal-konfirm-delete-hard'].show()
    },
    async handleDelete (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const id = this.id
        const response = await Service.katalog.deleteStockOpname(id)
        if (response.data.statcode === 0) {
          this.$refs['modal-konfirm-delete-hard'].hide()
          this.error = response.data.message
          this.$refs.table.refresh()
          this.clearMessage()
        } else {
          this.error = response.data.message
        }
      } catch (error) {
        this.error = error.message
      }
    },
    async getruangan () {
      await Service.katalog.getRuangStokOP().then(response => {
        if (response.status === 200) {
          this.combo.ruangan = response.data.data
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    async getPabrik ($event) {
      if ($event.length >= 2) {
        await Service.katalog.getPabrikStokOP_v2($event).then(response => {
          if (response.status === 200) {
            this.combo.pabrik = response.data.data
          }
        }).catch(error => {
          this.error = error.response.data.errors.message
        })
      } else {
        this.combo.pabrik = []
      }
    },
    async valRuang ($event, param) {
      if (param === 't') {
        this.stockopname.header.ruangan_id = $event.id
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    valFilterRuang () {
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    filterBtn () {
      this.selected.filterType = '1'
      this.selected.filterRuang = '0'
      this.filtering.valTglAwal = ''
      this.filtering.valTglAkhir = ''
      this.display.viewTglAwal = ''
      this.display.viewTglAkhir = ''
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    arrContent () {
      this.stockopname.detail.push(
        {
          id: '0',
          id_katalog: '',
          id_pabrik: '',
          jumlah_adm: '0',
          jumlah_fisik: '',
          tgl_expired: '',
          sts_batal: '0',
          userid_in: xray.currentUser().id.toString()
        }
      )
      this.selected.kode.push(
        {
          value: []
        }
      )
      this.selected.pabrik.push(
        {
          detail: []
        }
      )
      this.display.tglInp.push(
        {
          value: ''
        }
      )
      this.display.tglExp.push(
        {
          date: ''
        }
      )
      this.display.namabarang.push(
        {
          nama: ''
        }
      )
      this.display.hargabarang.push(
        {
          value: ''
        }
      )
      this.display.kodedetail.push(
        {
          kode: ''
        }
      )
      this.display.kodeName.push(
        {
          kode: '',
          nama: ''
        }
      )
    },
    addRow () {
      this.arrContent()
    },
    deleteRow (index, param) {
      if (param === 't') {
        this.stockopname.detail.splice(index, 1)
        this.display.tglInp.splice(index, 1)
        this.display.tglExp.splice(index, 1)
        this.selected.kode.splice(index, 1)
        this.selected.pabrik.splice(index, 1)
        this.display.namabarang.splice(index, 1)
        this.display.hargabarang.splice(index, 1)
        this.display.kodedetail.splice(index, 1)
        this.display.kodeName.splice(index, 1)
      }
    },
    clearDetail () {
      this.selected.pabrik[0].detail = []
      this.display.namabarang[0].nama = ''
      this.display.hargabarang[0].value = ''
      this.display.tglInp[0].value = ''
      this.display.tglExp[0].date = ''
      this.stockopname.detail = []
      this.stockopname.detail.push(
        {
          id: '0',
          id_katalog: '',
          id_pabrik: '',
          jumlah_adm: '0',
          jumlah_fisik: '',
          tgl_expired: '',
          sts_batal: '0',
          userid_in: xray.currentUser().id.toString().toString()
        }
      )
    },
    clearNotBrg () {
      this.stockopname.detail[0].jumlah_fisik = ''
      this.stockopname.detail[0].tgl_expired = ''
      this.display.tglInp[0].value = ''
      this.display.tglExp[0].date = ''
      this.display.hargabarang[0].value = ''
    },
    clearMessage () {
      this.message = ''
      this.error = ''
    },
    tglWithDots (item) {
      return item['tgl_expired']
    }
  }
}
</script>

<style>
#stock-opname-view .btn-active-focus {
  transition: none;
  border: 2px solid rgb(0, 0, 0)!important;
}
/* #modal-stokopname-add .modal-body, #modal-stokopname-edit .modal-body, #modal-filter .modal-body {
  background: #3399331b;
} */
#stock-opname-view input[type="text"][disabled],
#modal-stokopname-add input[type="text"][disabled],
#modal-stokopname-edit input[type="text"][disabled] {
   background: #e9ecef;
}

#stock-opname-view .table-stock-opname-view, #modal-stokopname-add .table-stock-opname-view, #modal-stokopname-edit .table-stock-opname-view {
  height: auto;
}

#stock-opname-view .multiselect, #modal-stokopname-add .multiselect, #modal-stokopname-edit .multiselect {
  min-height: 33px;
}

#stock-opname-view .multiselect__single, #modal-stokopname-add .multiselect__single, #modal-stokopname-edit .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#stock-opname-view .multiselect__placeholder, #modal-stokopname-add .multiselect__placeholder, #modal-stokopname-edit .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#stock-opname-view .multiselect__option, #modal-stokopname-add .multiselect__option, #modal-stokopname-edit .multiselect__option {
  font-size: .8em!important;
}
#stock-opname-view .multiselect__tags, #modal-stokopname-add .multiselect__tags, #modal-stokopname-edit .multiselect__tags {
  overflow: hidden;
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  font-size: 0.9em!important;
  border: 1px solid rgb(201, 201, 201);
}

#stock-opname-view .multiselect--disabled .multiselect__tags, #modal-stokopname-edit .multiselect--disabled .multiselect__tags, #modal-stokopname-add .multiselect--disabled .multiselect__tags {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: #E9ECEF;
}

#stock-opname-view .multiselect--disabled, #modal-stokopname-edit .multiselect--disabled, #modal-stokopname-add .multiselect--disabled {
  opacity: 1;
}

#stock-opname-view .multiselect--disabled .multiselect__tags .multiselect__single, #modal-stokopname-edit .multiselect--disabled .multiselect__tags .multiselect__single, #modal-stokopname-add .multiselect--disabled .multiselect__tags .multiselect__single {
  background: transparent;
}

#stock-opname-view .multiselect__select, #modal-stokopname-edit .multiselect__select, #modal-stokopname-add .multiselect__select {
  height: 30px;
}

#stock-opname-view .disable-bg, #modal-stokopname-edit .disable-bg, #modal-stokopname-add .disable-bg {
  background: #e9ecef;
  padding-top: 5px;
  padding-bottom: 5px;
}

#stock-opname-view .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}
#modal-stokopname-add .flatpickr-input[readonly], #modal-stokopname-edit .flatpickr-input[readonly], #modal-filter .flatpickr-input[readonly] {
  height: 33px;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.292);
  padding-left: 10px;
  color: black;
}

/* #modal-stokopname-add .modal-dialog, #modal-stokopname-edit .modal-dialog {
  min-width: 85%!important;
} */

#header-table-filter .flatpickr-input[readonly] {
  height: 38px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(17, 17, 17, 0.292);
  padding-left: 10px;
}
#stock-opname-view .cstm-cntrl-stockopname, #modal-stokopname-add .cstm-cntrl-stockopname, #modal-stokopname-edit .cstm-cntrl-stockopname, #modal-filter .cstm-cntrl-stockopname {
  height: 38px!important;
  font-size: 13px!important;
  color: black!important;
  border-radius: 3px;
}
#stock-opname-view legend {
    color: darkslategrey;
}
#stock-opname-view .form-control:disabled, #stock-opname-view .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 0.5;
}
#stock-opname-view .form-control {
  color: black!important;
}
#stock-opname-view .fc-unthemed .fc-toolbar .fc-button {
    margin: 3px;
}
#stock-opname-view tr:first-child > td > .fc-day-grid-event {
    cursor: pointer;
}
/* #modal-stokopname-edit .flatpickr-input[readonly] {
  background-color: rgb(245, 245, 245);
} */
#stock-opname-view .flex-half-width-left {
  width: 76%;
}
#stock-opname-view .flex-half-width-right {
  width: 23%;
}
#stock-opname-view .flex-full-width-left {
  width: 96%;
}
#stock-opname-view .flex-full-width-right {
  width: 3%;
}
#stock-opname-view .txt-farmasi-base {
  font-size: 12px;
}
#stock-opname-view .txt-farmasi-base-head {
  font-size: 14px;
}
#stock-opname-view .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#stock-opname-view .table th {
    font-size: 13px;
    font-weight: 400;
}
#stock-opname-view .form-control {
    border: 1px solid rgb(194, 194, 194);
    background: white;
    height: 40px;
}

#stock-opname-view .custom-flatpicker {
  border: 1px solid rgb(233, 233, 233);
  height: 34px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208)!important;
  border-radius: 5px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}

#stock-opname-view .btn-height-custom {
    height: 39px;
}
#stock-opname-view .cursor-point {
    cursor: pointer;
}
#stock-opname-view .bg-abu {
  background: #e9ecef;
}
#stock-opname-view .label-brg  {
  font-size: 13px;
  padding-left: 14px;
  padding-right: 14px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 3px;
}
#stock-opname-view .form-control {
    font-size: 11px;
}
#stock-opname-view .b-table-sticky-header, #stock-opname-view .table-responsive {
    margin-bottom: 0px;
}
#stock-opname-view .table-barang .btn {
    font-size: 11px;
}
#stock-opname-view .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#stock-opname-view .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#stock-opname-view .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#stock-opname-view .transition-cstm {
  transition: .3s linear;
}

#modal-stokopname-add .modal-body, #modal-stokopname-edit .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-stokopname-add .modal-header, #modal-stokopname-edit .modal-header {
  background: #3399333a;
}

#modal-stokopname-add .modal-footer, #modal-stokopname-edit .modal-footer {
  background: #3399333a;
}
#stock-opname-view .cstm-cntrl-stockopname, #modal-stokopname-add .cstm-cntrl-stockopname, #modal-stokopname-edit .cstm-cntrl-stockopname {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 34px!important;
}
#stock-opname-view .cstm-slct-stockopname, #modal-stokopname-add .cstm-slct-stockopname, #modal-stokopname-edit .cstm-slct-stockopname  {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
#stock-opname-view .form-control:disabled, #stock-opname-view .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

#stock-opname-view .cstm-srch-stock {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#stock-opname-view .cstm-slct-stock {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}
#stock-opname-view .disable-bg, #modal-stokopname-edit .disable-bg, #modal-stokopname-add .disable-bg {
    height: 34px;
}
.alert-radius-stockopname {
  border-radius: 5px;
}

.font-size-responsive {
  font-size: 7px;
}

@media (min-width: 576px) {
  .font-size-responsive {
    font-size: 8px;
  }
 }

@media (min-width: 768px) {
  .font-size-responsive {
    font-size: 9px;
  }
 }

@media (min-width: 992px) {
  .font-size-responsive {
    font-size: 10px;
  }
 }

@media (min-width: 1200px) {
  .font-size-responsive {
    font-size: 11px;
  }
 }
 .pointer-table tbody tr {
  cursor: pointer;
}
</style>
