<template>
  <div id="emr-horizontal-menu">
    <div>
      <b-card class="radius-custom-emr shadow-sm bg-white" no-body>
        <b-tabs class="bg-white" pills card>
          <b-tab active>
            <template #title>
              <i class="fas fa-file-alt"></i> Rekam Medis
            </template>
            <div class="emr-tab-content">
              <RekamMedis/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-user-md"></i> Layanan
            </template>
            <div class="emr-tab-content">
              <WorkspaceLayanan />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-flask"></i> Laboratorium
            </template>
            <div class="emr-tab-content">
              <WorkspaceLab/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-plus"></i> UTD
            </template>
            <div class="emr-tab-content">
              <Maintenance/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-x-ray"></i> Radiologi
            </template>
            <div class="emr-tab-content">
              <WorkspaceRad/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-receipt"></i> E-Resep
            </template>
            <div class="emr-tab-content">
              <TabEResep/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-wheelchair"></i> Konsul
            </template>
            <div class="emr-tab-content">
              <TabEKonsul/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="far fa-newspaper"></i> Laporan Operasi / Tindakan
            </template>
            <div class="emr-tab-content">
              <Maintenance/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="far fa-envelope"></i> Rujukan Keluar
            </template>
            <div class="emr-tab-content">
              <Maintenance/>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-history"></i> Riwayat
            </template>
            <div class="emr-tab-content">
              <RiwayatAll/>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Maintenance from '../../Pages/Maintenance.vue'
import RekamMedis from '../VerticalMenu/RekamMedis.vue'
import WorkspaceLayanan from '../Components/Layanan/Workspace.vue'
import WorkspaceLab from '../Components/Laboratorium/index.vue'
import WorkspaceRad from '../Components/Radiologi/index.vue'
import TabEResep from '../Components/EResep/Tab/Tab.vue'
import TabEKonsul from '../Components/Konsul/Tab.vue'
import RiwayatAll from '../Components/RiwayatAll/index.vue'

export default {
  name: 'MedicalRecordHorizontal',
  components: {
    RekamMedis,
    TabEResep,
    WorkspaceLayanan,
    WorkspaceLab,
    WorkspaceRad,
    Maintenance,
    TabEKonsul,
    RiwayatAll
  },
  mounted () {
    xray.index()
  }
}
</script>

<style>
#emr-horizontal-menu .card-body {
  padding: 0px;
}
#emr-horizontal-menu .radius-custom-emr {
  border-radius: .5rem;
}
#emr-horizontal-menu .card-header {
  background-color: white;
}
</style>
