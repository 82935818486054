<template>
  <div id=penerimaan-rumah-tangga-detail>
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <b-row class="mb-1">
        <b-col>
          <div id="penerimaan-detail" class="shadow-hover bg-white border mb-2">
            <div class="d-flex flex-row justify-content-between align-items-center bg-primary text-white py-2 px-3">
              <div>
                <p class="h6 font-weight-bold text-uppercase mb-0">Penerimaan Donasi/COD/Konsinyasi</p>
              </div>
            </div>
            <hr class="m-0">
            <!-- <pre>{{ form.header }}</pre> -->
            <div class="py-2 px-3">
              <b-row>
                <b-col cols="12" lg="4" xl="4">
                  <div>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">No. Transaksi</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center">
                          <div class="w-50 mr-1">
                            <b-form-input disabled v-model="form.header.kode" type="text" class="bg-fatmahost-disabled text-white custom-form"
                              placeholder="..."></b-form-input>
                          </div>
                          <div class="w-15-penerimaan text-center">
                            <p class="small text-dark m-0 p-0">KE -</p>
                          </div>
                          <div class="w-35-penerimaan">
                            <b-form-input disabled  v-model="form.header.terimake" type="text" class="bg-secondary text-white custom-form"
                              placeholder="..."></b-form-input>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">No. Penerimaan</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center bg-primary-custom border pr-1">
                          <div class="w-95-penerimaan mr-1">
                            <b-form-input v-model="form.header.no_doc" type="text" class="bg-kuning-input custom-form"
                              placeholder="..."></b-form-input>
                          </div>
                          <div class="w-5-penerimaan">
                            <b-form-checkbox
                              v-model="form.header.ppn"
                              value="1"
                              unchecked-value="0"
                              @change="countPpn"
                            >
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">No. Faktur/Surat jln</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center">
                          <div class="w-45-penerimaan mr-1">
                            <b-form-input v-model="form.header.no_faktur" type="text" class="bg-kuning-input custom-form"
                            placeholder="No. Faktur..."></b-form-input>
                          </div>
                          <div class="w-10-penerimaan text-center">
                            <p class="small text-dark m-0 p-0">/</p>
                          </div>
                          <div class="w-45-penerimaan">
                            <b-form-input v-model="form.header.no_suratjalan" type="text" class="bg-kuning-input custom-form"
                            placeholder="No. Surat Jalan..."></b-form-input>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <!-- <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">No. SP/SPK/Kontrak</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center bg-primary-custom border pl-1">
                          <div class="w-5-penerimaan mr-1">
                            <b-form-checkbox
                              v-model="form.header.ppn"
                              value="1"
                              unchecked-value="0"
                              @change="countPpn"
                            >
                            </b-form-checkbox>
                          </div>
                          <div class="w-95-penerimaan">
                            <b-form-input  v-model="form.header.kode_reffpl" type="text" class="bg-white text-dark custom-form"
                            placeholder="No. SP/SPK/Kontrak"></b-form-input>
                          </div>
                        </div>
                      </b-col>
                    </b-row> -->
                    <!-- <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Reff Delivery Order</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center bg-primary-custom border pl-1">
                          <div class="w-5-penerimaan mr-1">
                            <b-form-checkbox
                              v-model="form.header.ppn"
                              value="1"
                              unchecked-value="0"
                              @change="countPpn"
                            >
                            </b-form-checkbox>
                          </div>
                          <div class="w-95-penerimaan">
                            <b-form-input  v-model="form.header.kode_reffro" type="text" class="bg-white text-dark custom-form"
                            placeholder="Reff Delivery Order"></b-form-input>
                          </div>
                        </div>
                      </b-col>
                    </b-row> -->
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Distributor</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center bg-primary-custom border pl-1">
                          <div class="w-5-penerimaan mr-1">
                            <b-form-checkbox
                              v-model="form.header.ppn"
                              value="1"
                              unchecked-value="0"
                              @change="countPpn"
                            >
                            </b-form-checkbox>
                          </div>
                          <div class="w-95-penerimaan">
                            <b-form-input  v-model="form.header.no_faktur" type="text" class="bg-kuning-input custom-form"
                            placeholder="Distributor"></b-form-input>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Penyimpanan</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <!-- <div class="d-flex align-items-center bg-primary-custom border pl-1">
                          <div class="w-5-penerimaan mr-1">
                            <b-form-checkbox
                              v-model="form.header.ppn"
                              value="1"
                              unchecked-value="0"
                              @change="countPpn"
                            >
                            </b-form-checkbox>
                          </div>
                          <div class="w-95-penerimaan">
                            <multiselect :show-labels="false" :allow-empty="false" label="deskripsi" placeholder="Doc"
                              :options="options.tipe_doc" v-model="selected.tipe_doc" :searchable="true"
                              @input="valueSelectize($event, 'tipe_doc', '')">
                            </multiselect>
                          </div>
                        </div> -->
                        <div class="d-flex align-items-center">
                          <div class="w-50 mr-1">
                            <b-form-input  v-model="form.header.id_gudangpenyimpanan" type="text" class="bg-kuning-input custom-form"
                            placeholder="..."></b-form-input>
                          </div>
                          <div class="w-50 bg-primary-custom pl-1">
                            <div class="d-flex align-items-center flex-row w-100">
                              <div class="w-10-penerimaan mr-1">
                                <b-form-checkbox
                                  v-model="form.header.ppn"
                                  value="1"
                                  unchecked-value="0"
                                  @change="countPpn"
                                >
                                </b-form-checkbox>
                              </div>
                              <div class="w-90-penerimaan">
                                <b-form-input disabled v-model="form.header.keterangan" type="text" class="bg-secondary text-white custom-form"
                                placeholder="..."></b-form-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">PPN / GANTI PPN</p>
                        </div>
                      </b-col>
                      <b-col class="pr-1" cols="12" xl="5">
                        <div class="d-flex align-items-center bg-primary-custom border">
                          <div class="w-15-penerimaan pl-2">
                            <b-form-checkbox
                              v-model="form.header.ppn"
                              value="1"
                              unchecked-value="0"
                              @change="countPpn"
                            >
                            </b-form-checkbox>
                          </div>
                          <div class="w-85-penerimaan">
                            <b-form-input disabled v-model="form.header.ppn" type="text" class="bg-secondary text-white custom-form"
                              placeholder="..."></b-form-input>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="1">
                        <div>
                          /
                        </div>
                      </b-col>
                      <b-col class="pl-1" cols="12" xl="2">
                        <div>
                          <b-form-input disabled v-model="nilai.nilai_ppn" @input="convertRibuan($event, 'nilai_ppn', '')"
                            @keypress="isNumberKey" type="text" class="bg-secondary text-white custom-form text-right custom-spacing"
                            placeholder="Nilai PPN..."></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-0">
                      <b-col cols="12" xl="4">
                        <div>
                          <b-button class="text-dark" variant="light" size="sm" @click.prevent="openModal">LIHAT NILAI
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                    <!-- <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Tanggal Faktur</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div>
                          <flat-pickr  :config="configTgl" v-model="form.header.tgl_faktur" value=""
                            @on-change="valueTgl($event, 'tgl_faktur')" placeholder="Tanggal"
                            class="w-100 custom-flatpicker calender-small" />
                        </div>
                      </b-col>
                    </b-row> -->
                    <!-- <b-row class="align-items-center mb-0">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">PPN / GANTI PPN</p>
                        </div>
                      </b-col>
                      <b-col class="pr-1" cols="12" xl="5">
                        <div class="d-flex align-items-center bg-primary-custom border">
                          <div class="w-20-penerimaan pl-2">
                            <b-form-checkbox
                              v-model="form.header.ppn"
                              value="1"
                              unchecked-value="0"
                              @change="countPpn"
                            >
                            </b-form-checkbox>
                          </div>
                          <div class="w-80-penerimaan">
                            <b-form-input v-model="form.header.ppn" type="text" class="bg-white text-dark custom-form"
                              placeholder="..."></b-form-input>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="1">
                        <div>
                          /
                        </div>
                      </b-col>
                      <b-col class="pl-1" cols="12" xl="2">
                        <div>
                          <b-form-input v-model="nilai.nilai_ppn" @input="convertRibuan($event, 'nilai_ppn', '')"
                            @keypress="isNumberKey" type="text" class="bg-white text-dark custom-form text-right custom-spacing"
                            placeholder="Nilai PPN..."></b-form-input>
                        </div>
                      </b-col>
                    </b-row> -->
                  </div>
                </b-col>
                <b-col cols="12" lg="4" xl="4">
                  <div>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">TGL. Penerimaan</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center w-100">
                          <div class="w-100">
                            <flat-pickr :config="configTgl" v-model="form.header.tgl_doc" value=""
                              @on-change="valueTgl($event, 'tgl_faktur')" placeholder="Tanggal"
                              class="w-100 custom-flatpicker calender-small bg-kuning-input" />
                          </div>
                          <!-- <div class="w-15-penerimaan text-center">
                            <p class="small text-dark m-0 p-0">TEMPO</p>
                          </div>
                          <div class="w-35-penerimaan">
                            <b-form-input  v-model="form.header.keterangan" type="text" class="bg-kuning-input custom-form"
                              placeholder="..."></b-form-input>
                          </div> -->
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Mata Anggaran</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center bg-primary-custom border">
                          <div class="w-100">
                            <b-form-input  v-model="form.header.id_jenisanggaran" type="text" class="bg-kuning-input custom-form"
                              placeholder="..."></b-form-input>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Anggaran</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center">
                          <div class="w-30-penerimaan mr-1">
                            <b-form-input v-model="form.header.blnawal_anggaran" type="text" class="bg-kuning-input custom-form"
                              placeholder="..."></b-form-input>
                          </div>
                          <div class="w-10-penerimaan text-center">
                            <p class="small text-dark m-0 p-0">S.D.</p>
                          </div>
                          <div class="w-30-penerimaan mr-1">
                            <b-form-input v-model="form.header.blnakhir_anggaran" type="text" class="bg-kuning-input custom-form"
                            placeholder="..."></b-form-input>
                          </div>
                          <div class="w-30-penerimaan">
                            <b-form-input v-model="form.header.thn_anggaran" type="text" class="bg-kuning-input custom-form"
                            placeholder="..."></b-form-input>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Sumber Dana</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center bg-primary-custom border">
                          <div class="w-100">
                            <b-form-input  v-model="form.header.id_sumberdana" type="text" class="bg-kuning-input custom-form"
                            placeholder="..."></b-form-input>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Jenis Harga</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div class="d-flex align-items-center">
                          <div class="w-40-penerimaan mr-1">
                            <b-form-input  v-model="form.header.id_jenisharga" type="text" class="bg-kuning-input custom-form"
                            placeholder="..."></b-form-input>
                          </div>
                          <div class="w-20-penerimaan text-center">
                            <p class="small text-dark m-0 p-0">CARA BYR</p>
                          </div>
                          <div class="w-40-penerimaan">
                            <b-form-input  v-model="form.header.id_carabayar" type="text" class="bg-kuning-input custom-form"
                            placeholder="..."></b-form-input>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col cols="12" xl="4">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Keterangan</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="8">
                        <div>
                          <b-form-textarea
                            id="textarea"
                            class="bg-kuning-input"
                            v-model="form.header.id_carabayar"
                            placeholder="Enter something..."
                            rows="1"
                          ></b-form-textarea>
                          <!-- <flat-pickr  :config="configTgl" v-model="tanggal.tgl_faktur" value=""
                            @on-change="valueTgl($event, 'tgl_faktur')" placeholder="Tanggal"
                            class="w-100 custom-flatpicker calender-small" /> -->
                        </div>
                      </b-col>
                    </b-row>
                    <!-- <b-row class="align-items-center mb-0">
                      <b-col cols="12" xl="4">
                        <div>
                          <b-button variant="primary" size="sm" @click.prevent="openModal"><i class="fas fa-money-bill-alt"></i> LIHAT NILAI
                          </b-button>
                        </div>
                      </b-col>
                    </b-row> -->
                  </div>
                </b-col>
                <!-- <b-col cols="12" lg="3" xl="3">
                  <div>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="5">
                        <div>
                          <p class="text-dark custom-font m-0 p-0">Nilai Total</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="7">
                        <div>
                          <b-form-input disabled v-model="nilai.nilai_total" @input="convertRibuan($event, 'nilai_total', '')"
                            @keypress="isNumberKey" type="text" class="bg-white text-dark custom-form text-right custom-spacing"
                            placeholder="Nilai Total..."></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="5">
                        <div>
                          <p class="text-dark custom-font m-0 p-0">Nilai Diskon</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="7">
                        <div>
                          <b-form-input disabled v-model="nilai.nilai_diskon" @input="convertRibuan($event, 'nilai_diskon', '')"
                            @keypress="isNumberKey" type="text" class="bg-white text-dark custom-form text-right custom-spacing"
                            placeholder="Nilai Diskon..."></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="5">
                        <div>
                          <p class="text-dark custom-font m-0 p-0">PPN</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="7">
                        <div>
                            <b-form-checkbox
                              v-model="form.header.ppn"
                              value="1"
                              unchecked-value="0"
                              @change="countPpn"
                            >
                              PPN 11%
                            </b-form-checkbox>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="5">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Nilai PPN</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="7">
                        <div>
                          <b-form-input disabled v-model="nilai.nilai_ppn" @input="convertRibuan($event, 'nilai_ppn', '')"
                            @keypress="isNumberKey" type="text" class="bg-white text-dark custom-form text-right custom-spacing"
                            placeholder="Nilai PPN..."></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="5">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Nilai Pembulatan</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="7">
                        <div>
                          <b-form-input disabled v-model="nilai.nilai_pembulatan"
                            @input="convertRibuan($event, 'nilai_pembulatan', '')" @keypress="isNumberKey" type="text"
                            class="bg-white text-dark custom-form text-right custom-spacing" placeholder="Nilai Pembuatan..."></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-1">
                      <b-col cols="12" xl="5">
                        <div>
                          <p class="text-dark custom-font text-uppercase m-0 p-0">Nilai Akhir</p>
                        </div>
                      </b-col>
                      <b-col cols="12" xl="7">
                        <div>
                          <b-form-input disabled v-model="nilai.nilai_akhir" @input="convertRibuan($event, 'nilai_akhir', '')"
                            @keypress="isNumberKey" type="text" class="bg-white text-dark custom-form text-right custom-spacing"
                            placeholder="Nilai Akhir..."></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col> -->
                <b-col cols="12" lg="4">
                  <table class="bg-white table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th class="text-center col-1">
                          NO
                        </th>
                        <th class="text-center col-1">
                          VER
                        </th>
                        <th class="text-center col-3">
                          OTORISASI
                        </th>
                        <th class="text-center col-4">
                          USER
                        </th>
                        <th class="text-center col-3">
                          TANGGAL
                        </th>
                        <th class="text-center col-3">
                          UPDT STOCK
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center p-1">1</td>
                        <td class="text-center">
                          <b-form-checkbox id="ver_terima" name="ver_terima" value="1" v-model="verifikasi.ver_terima"
                            @change="verifikasiType($event, 'ver_terima')" unchecked-value="0">
                          </b-form-checkbox>
                        </td>
                        <td class="text-center">
                          <span v-if="verifikasi.ver_terima === '1'">Tim Terima</span>
                        </td>
                        <td class="text-center">
                          <span
                          v-if="verifikasi.ver_terima === '1'"> {{ verifikasi_name.ver_terima }}
                          </span>
                        </td>
                        <td class="text-center">
                          <span
                              v-if="verifikasi.ver_terima === '1'">{{
                              form.header.ver_tglterima.split('-').reverse().join('-') }}
                            </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="text-center p-1">2</td>
                        <td class="text-center">
                          <b-form-checkbox id="ver_gudang" name="ver_gudang" value="1" v-model="verifikasi.ver_gudang"
                            @change="verifikasiType($event, 'ver_gudang')" unchecked-value="0">
                          </b-form-checkbox>
                        </td>
                        <td class="text-center">
                          <span v-if="verifikasi.ver_gudang === '1'">Gudang</span>
                        </td>
                        <td class="text-center">
                          <span
                          v-if="verifikasi.ver_gudang === '1'"> {{ verifikasi_name.ver_gudang }}
                          </span>
                        </td>
                        <td class="text-center">
                          <span
                              v-if="verifikasi.ver_gudang === '1'">{{
                              form.header.ver_tglgudang.split('-').reverse().join('-') }}
                            </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="text-center p-1">3</td>
                        <td class="text-center">
                          <b-form-checkbox id="ver_akuntansi" name="ver_akuntansi" value="1"
                            v-model="verifikasi.ver_akuntansi" @change="verifikasiType($event, 'ver_akuntansi')"
                            unchecked-value="0">
                          </b-form-checkbox>
                        </td>
                        <td class="text-center">
                          <span v-if="verifikasi.ver_akuntansi === '1'">Akutansi</span>
                        </td>
                        <td class="text-center">
                          <span
                          v-if="verifikasi.ver_akuntansi === '1'"> {{ verifikasi_name.ver_akuntansi }}
                          </span>
                        </td>
                        <td class="text-center">
                          <span
                              v-if="verifikasi.ver_akuntansi === '1'">{{
                              form.header.ver_tglakuntansi.split('-').reverse().join('-') }}
                            </span>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <div id="penerimaan-detail" class="shadow-hover bg-white border mb-2">
            <div class="py-0 px-0">
              <div class="table-responsive-xl mb-0" style="overflow-x: scroll;">
                <table class="table table-bordered table-sm bg-white mb-0" style="width: 150rem;">
                  <thead>
                      <tr>
                        <th class="text-center" rowspan="2">
                          <b-form-checkbox
                            v-model="form.header.ppn"
                            value="1"
                            unchecked-value="0"
                            @change="countPpn"
                          >NO
                          </b-form-checkbox>
                        </th>
                        <th class="text-center" rowspan="2">NAMA BARANG</th>
                        <th class="text-center" rowspan="2">PABRIK</th>
                        <th class="text-center" rowspan="2">KEMASAN</th>
                        <th class="text-center" rowspan="2">ISI</th>
                        <th class="text-center" colspan="5">PENGADAAN</th>
                        <th class="text-center" colspan="2">HARGA</th>
                        <th class="text-center" rowspan="2">DISKON %</th>
                        <th class="text-center" colspan="3">TOTAL</th>
                        <!-- <th class="text-center" colspan="8">REALISASI</th> -->
                      </tr>
                      <tr>
                        <th class="text-center">NO</th>
                        <th class="text-center">BATCH</th>
                        <th class="text-center">EXPIRED</th>
                        <th class="text-center">KEMASAN</th>
                        <th class="text-center">SATUAN</th>
                        <th class="text-center">KEMASAN</th>
                        <th class="text-center">SATUAN</th>
                        <th class="text-center">SBLM DISC</th>
                        <th class="text-center">DISC</th>
                        <th class="text-center">RP</th>
                        <!-- <th class="text-center">RENC</th>
                        <th class="text-center">HPS</th>
                        <th class="text-center">SPK</th>
                        <th class="text-center">DO</th>
                        <th class="text-center">BNS</th>
                        <th class="text-center">TRM</th>
                        <th class="text-center">RET</th> -->
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(data, index) in form.details" :key="index">
                        <td class="text-center">
                          <b-form-checkbox
                            v-model="form.header.ppn"
                            value="1"
                            unchecked-value="0"
                            @change="countPpn"
                          >{{ index + 1 }}
                          </b-form-checkbox>
                        </td>
                        <td class="text-right col-2 p-0">
                          <b-form-input v-model="data.id_katalog" type="text" class="bg-white text-dark custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right col-1 p-0">
                          <b-form-input v-model="data.id_katalog" type="text" class="bg-white text-dark custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input v-model="data.jumlah_kemasan" type="text" class="bg-kuning-input text-dark text-right custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input disabled v-model="data.jumlah_item" type="text" class="bg-secondary text-white text-right custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-center p-0">
                          <b-form-input v-model="data.no_urut" type="text" class="bg-white text-dark text-center custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input type="text" class="bg-kuning-input text-dark custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input type="text" class="bg-kuning-input text-dark  custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input type="text" class="bg-kuning-input text-dark  custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input disabled type="text" class="bg-secondary text-white custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input type="text" class="bg-kuning-input text-dark custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input disabled type="text" class="bg-secondary text-white custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input type="text" class="bg-kuning-input text-dark custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input disabled type="text" class="bg-secondary text-white custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input type="text" class="bg-white text-dark custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                        <td class="text-right p-0">
                          <b-form-input type="text" class="bg-white text-dark custom-form rounded-0 border-0"
                          placeholder="..."></b-form-input>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="11"></td>
                        <td class="text-right font-weight-bold">TOTAL</td>
                        <td class="text-right font-weight-bold">...</td>
                        <td class="text-right font-weight-bold">...</td>
                        <td class="text-right font-weight-bold">...</td>
                        <td class="text-right font-weight-bold">...</td>
                      </tr>
                      <tr>
                        <td colspan="13"></td>
                        <td class="text-right font-weight-bold">PPN</td>
                        <td class="text-right font-weight-bold">...</td>
                        <td class="text-right font-weight-bold">...</td>
                      </tr>
                      <tr>
                        <td colspan="13"></td>
                        <td class="text-right font-weight-bold">SETELAH PPN</td>
                        <td class="text-right font-weight-bold">...</td>
                        <td class="text-right font-weight-bold">...</td>
                      </tr>
                    </tbody>
                  <!-- <tbody>
                    <tr v-for="(d, index) in form.detail" :key="d.index">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>
                        <multiselect :show-labels="false" :allow-empty="false" label="deskripsi" placeholder="Barang"
                          :options="options.barang_rt" :searchable="true" v-model="selected.id_katalog[index].detail"
                          @input="valueSelectize($event, 'id_katalog', index)">
                        </multiselect>
                      </td>
                      <td class="py-1">
                        <span>
                          <b-form-input v-model="d.keterangan" type="text" class="bg-white text-dark custom-form"
                            placeholder="Keterangan..."></b-form-input>
                        </span>
                      </td>
                      <td>
                        <span>
                          <b-form-input v-model="nilai.jumlah_item[index].value"
                            @input="count($event, 'jumlah_item', index)" @keypress="isNumberKey" type="text"
                            class="bg-white text-dark custom-form custom-spacing" placeholder="Item..."></b-form-input>
                        </span>
                      </td>
                      <td>
                        <span>
                          <b-form-input v-model="nilai.jumlah_kemasan[index].value"
                            @input="count($event, 'jumlah_kemasan', index)" @keypress="isNumberKey" type="text"
                            class="bg-white text-dark custom-form custom-spacing" placeholder="Kemasan..."></b-form-input>
                        </span>
                      </td>
                      <td>
                        <span>
                          <b-form-input v-model="nilai.harga_item[index].value"
                            @input="count($event, 'harga_item', index)" @keypress="isNumberKey" type="text"
                            class="bg-white text-dark custom-form custom-spacing" placeholder="Item..."></b-form-input>
                        </span>
                      </td>
                      <td>
                        <span>
                          <b-form-input v-model="nilai.harga_kemasan[index].value"
                            @input="count($event, 'harga_kemasan', index)" @keypress="isNumberKey" type="text"
                            class="bg-white text-dark custom-form custom-spacing" placeholder="Kemasan..."></b-form-input>
                        </span>
                      </td>
                      <td>
                        <span>
                          <b-form-input v-model="nilai.diskon_item[index].value"
                            @input="count($event, 'diskon_item', index)" @keypress="isNumberKey" type="text"
                            class="bg-white text-dark custom-form custom-spacing" placeholder="Item..."></b-form-input>
                        </span>
                      </td>
                      <td>
                        <span>
                          <b-form-input v-model="nilai.diskon_harga[index].value"
                            @input="count($event, 'diskon_harga', index)" @keypress="isNumberKey" type="text"
                            class="bg-white text-dark custom-form custom-spacing" placeholder="Harga..."></b-form-input>
                        </span>
                      </td>
                      <td class="text-center">
                        <span>
                          <b-button :disabled="index !== 0 ? false : true" variant="white" size="sm"
                            @click="deleteRow(index)"><i
                              :class="['fas fa-trash-alt', index !== 0 ? 'text-danger' : 'text-secondary']"></i></b-button>
                        </span>
                      </td>
                    </tr>
                  </tbody> -->
                </table>
              </div>
            </div>
            <div v-if="message.code === 'modal'"
              :class="['shadow-sm border px-2 py-1 d-flex flex-row justify-content-between', 'bg-' + message.variant]">
              <div>
                <p class="small m-0 p-0">{{ message.desc }}</p>
              </div>
              <div>
                <p class="small m-0 p-0 my-cursor" @click="clearMessage"><i class="fas fa-times fa-lg"></i></p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-nilai-penerimaan" ref="modal-nilai-penerimaan" size="md" title="PREVIEW"
      ok-title="Simpan" cancel-title="Tutup" :hide-footer="true" :no-close-on-backdrop="true" :static="true"
      scrollable centered>
      <div>
        <b-row class="align-items-center mb-1">
          <b-col cols="12" xl="5">
            <div>
              <p class="text-dark text-uppercase custom-font m-0 p-0">Nilai Total</p>
            </div>
          </b-col>
          <b-col cols="12" xl="7">
            <div>
              <b-form-input disabled v-model="nilai.nilai_total" @input="convertRibuan($event, 'nilai_total', '')"
                @keypress="isNumberKey" type="text" class="bg-white text-white custom-form text-right custom-spacing"
                placeholder="Nilai Total..."></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-1">
          <b-col cols="12" xl="5">
            <div>
              <p class="text-dark text-uppercase custom-font m-0 p-0">Nilai Diskon</p>
            </div>
          </b-col>
          <b-col cols="12" xl="7">
            <div>
              <b-form-input disabled v-model="nilai.nilai_diskon" @input="convertRibuan($event, 'nilai_diskon', '')"
                @keypress="isNumberKey" type="text" class="bg-white text-white custom-form text-right custom-spacing"
                placeholder="Nilai Diskon..."></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-1">
          <b-col cols="12" xl="5">
            <div>
              <p class="text-dark custom-font text-uppercase m-0 p-0">Nilai Pembulatan</p>
            </div>
          </b-col>
          <b-col cols="12" xl="7">
            <div>
              <b-form-input disabled v-model="nilai.nilai_pembulatan"
                @input="convertRibuan($event, 'nilai_pembulatan', '')" @keypress="isNumberKey" type="text"
                class="bg-white text-white custom-form text-right custom-spacing" placeholder="Nilai Pembuatan..."></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-1">
          <b-col cols="12" xl="5">
            <div>
              <p class="text-dark text-uppercase custom-font text-uppercase m-0 p-0">Nilai Akhir</p>
            </div>
          </b-col>
          <b-col cols="12" xl="7">
            <div>
              <b-form-input disabled v-model="nilai.nilai_akhir" @input="convertRibuan($event, 'nilai_akhir', '')"
                @keypress="isNumberKey" type="text" class="bg-white text-white custom-form text-right custom-spacing"
                placeholder="Nilai Akhir..."></b-form-input>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Loader from '../../../components/xray/loader/Loader'
import Service from '../../../services/fatmahost/index'
// import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'PenerimaanDetail',
  components: {
    // Multiselect,
    flatPickr,
    Loader
  },
  data () {
    return {
      isLoading: false,
      modal_status: '',
      modal_title: '',
      id_show: '',
      id_terima: '',
      message: {
        desc: '',
        code: '',
        variant: ''
      },
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center text-dark' },
          { label: 'NAMA REKANAN', key: 'nama_rekanan', class: 'text-dark' },
          { label: 'KODE', key: 'kode', class: 'text-dark' },
          { label: 'KETERANGAN', key: 'keterangan', class: 'text-dark' },
          { label: 'NO. DOC', key: 'no_doc', class: 'text-dark' },
          { label: 'JNS. DOK. TERIMA', key: 'jenis_dokumen_terima', class: 'text-dark' },
          { label: 'NO. FAKTUR', key: 'no_faktur', class: 'text-dark' },
          { label: 'NO. SURAT JALAN', key: 'no_suratjalan', class: 'text-dark' },
          { label: 'TGL . DOC', key: 'tgl_doc', class: 'text-dark' },
          { label: 'TGL. FAKTUR', key: 'tgl_faktur', class: 'text-dark' },
          { label: 'TGL. TUKAR FAKTUR', key: 'tgl_tukar_faktur', class: 'text-dark' },
          { label: 'AKSI', key: 'action', class: 'text-dark text-center' }
        ],
        filter: null,
        filterOn: [],
        pageOptions: [10, 50, 100, 500],
        currentPage: null,
        perPage: null,
        totalRows: null,
        sortBy: '',
        sortDesc: false,
        selectMode: 'single'
      },
      form: {
        object: 'penerimaan-rumah-tangga',
        header: {
          // id_rekanan: '',
          // id_jenisanggaran: '',
          // keterangan: '',
          // tgl_doc: '',
          // tgl_faktur: '',
          // tipe_doc: '',
          // no_faktur: '',
          // no_suratjalan: '',
          // ppn: '0',
          // nilai_total: '',
          // nilai_diskon: '',
          // nilai_ppn: '',
          // nilai_pembulatan: '',
          // nilai_akhir: '',
          // ver_terima: '0',
          // ver_tglterima: '',
          // ver_usrterima: '0',
          // ver_gudang: '',
          // ver_tglgudang: '',
          // ver_usrgudang: '',
          // ver_akuntansi: '',
          // ver_tglakuntansi: '',
          // ver_usrakuntansi: '',
          // userid_in: xray.currentUser().id.toString(),
          // userid_updt: xray.currentUser().id.toString()
          kode: '',
          revisike: null,
          keterangan: null,
          no_doc: '',
          tgl_doc: '',
          tgl_faktur: null,
          tgl_tukar_faktur: null,
          tipe_doc: null,
          kode_refftrm: null,
          kode_reffpl: '',
          kode_reffpo: '',
          kode_reffro: '',
          kode_reffrenc: '',
          kode_reffkons: null,
          no_faktur: '',
          no_suratjalan: '',
          terimake: null,
          id_pbf: null,
          id_gudangpenyimpanan: null,
          id_jenisanggaran: null,
          id_sumberdana: null,
          id_subsumberdana: null,
          id_carabayar: null,
          id_jenisharga: null,
          thn_anggaran: null,
          blnawal_anggaran: null,
          blnakhir_anggaran: null,
          ppn: null,
          nilai_total: '',
          nilai_diskon: '',
          nilai_ppn: '',
          nilai_pembulatan: '',
          nilai_akhir: '',
          sts_tabunggm: null,
          sts_linked: null,
          sts_revisi: null,
          sysdate_rev: null,
          keterangan_rev: null,
          sts_deleted: null,
          sysdate_del: null,
          sts_updatekartu: null,
          sts_izinrevisi: null,
          ver_tglizinrevisi: null,
          ver_usrizinrevisi: null,
          ver_revterima: null,
          ver_revtglterima: null,
          ver_revusrterima: null,
          ver_revisi: null,
          ver_tglrevisi: null,
          ver_usrrevisi: null,
          ver_terima: null,
          ver_tglterima: '',
          ver_usrterima: null,
          ver_gudang: null,
          ver_tglgudang: null,
          ver_usrgudang: null,
          ver_akuntansi: null,
          ver_tglakuntansi: null,
          ver_usrakuntansi: null,
          ver_pembayaran: null,
          ver_tglpembayaran: null,
          ver_usrpembayaran: null,
          sts_testing: null,
          userid_in: null,
          sysdate_in: '',
          userid_updt: null,
          sysdate_updt: ''
        },
        detail: [
          {
            id: 0,
            id_katalog: '',
            id_kemasan: '',
            isi_kemasan: '',
            jumlah_item: '',
            jumlah_kemasan: '',
            jumlah_itembonus: '0',
            harga_item: '',
            harga_kemasan: '',
            diskon_item: '',
            diskon_harga: '',
            keterangan: '',
            userid_updt: xray.currentUser().id.toString()
          }
        ]
      },
      formCetak: {
        header: {
          no_terima: '',
          no_btb: '',
          no_faktur: '',
          no_suratjalan: '',
          no_sp_spk: '',
          no_spb: '',
          pabrik: '',
          jumlah: '',
          ppn: '0',
          nilai_pembulatan: '0',
          nilai_akhir: '',
          ver_terima: '0',
          ver_tglterima: '',
          desc_ver_usrterima: '',
          ver_gudang: '0',
          ver_tglgudang: '',
          desc_ver_usrgudang: '',
          ver_akuntansi: '0',
          ver_tglakuntansi: '',
          desc_ver_usrakuntansi: ''
        },
        details: [
          {
            nama_barang: '',
            kuantitas: '',
            satuan: '',
            harga: '',
            diskon: '',
            jumlah: ''
          }
        ]
      },
      filtering: {
        kode: '',
        nama: ''
      },
      paramSelectize: [
        { param0: 'rekanan-rt' },
        { param0: 'kemasan-rt' },
        { param0: 'kelompok-rt' },
        { param0: 'jenis-anggaran-rt' },
        { param0: 'tipe-doc' }
      ],
      options: {
        rekanan_rt: [],
        kemasan_rt: [],
        kelompok_rt: [],
        jenis_anggaran_rt: [],
        tipe_doc: [],
        barang_rt: []
      },
      selected: {
        rekanan_rt: [],
        kemasan_rt: [],
        kelompok_rt: [],
        jenis_anggaran_rt: [],
        tipe_doc: [],
        barang_rt: [],
        id_katalog: [{ detail: [] }],
        id_kemasan: [{ detail: [] }]
      },
      configTgl: {
        locale: IndonesianLocale,
        disableMobile: true,
        dateFormat: 'd-m-Y'
      },
      tanggal: {
        tgl_doc: '',
        tgl_faktur: ''
      },
      verifikasi: {
        ver_terima: '',
        ver_gudang: '',
        ver_akuntansi: ''
      },
      verifikasi_name: {
        ver_terima: xray.currentUser().name,
        ver_gudang: xray.currentUser().name,
        ver_akuntansi: xray.currentUser().name
      },
      nilai: {
        nilai_total: '',
        nilai_diskon: '',
        nilai_ppn: '',
        nilai_pembulatan: '0',
        nilai_akhir: '',
        // detail
        isi_kemasan: [{ value: '' }],
        jumlah_item: [{ value: '' }],
        jumlah_kemasan: [{ value: '' }],
        jumlah_itembonus: [{ value: '0' }],
        harga_item: [{ value: '' }],
        harga_kemasan: [{ value: '' }],
        diskon_item: [{ value: '' }],
        diskon_harga: [{ value: '' }]
      }
    }
  },
  mounted () {
    xray.index()
    this.getSelectize()
    if (this.$route.query.idterima) {
      this.show(this.$route.query.idterima, 'penerimaan-rumah-tangga')
      this.id_terima = this.$route.query.idterima ? this.$route.query.idterima : ''
    }
  },
  methods: {
    toPenerimaanDetail (idterima) {
      this.$router.push({
        name: 'rumahtangga.penerimaan-detail', query: { idterima }
      })
    },
    convertDate (value, param) {
      const date = param === 'funct' ? new Date() : new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')
      if (param === 'use') return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds
      if (param === 'func' || param === 'funct') return year + '-' + month + '-' + day
    },
    count ($event, param, index) {
      if (param === 'jumlah_item') {
        const param2 = 'jumlah_kemasan'
        const a = parseFloat($event)
        const x = parseFloat(this.form.detail[index].isi_kemasan)
        const result = String(a * x)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      if (param === 'jumlah_kemasan') {
        const param2 = 'jumlah_item'
        const b = parseFloat($event)
        const x = parseFloat(this.form.detail[index].isi_kemasan)
        const result = String(b / x)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      //
      if (param === 'harga_item') {
        const param2 = 'harga_kemasan'
        const c = parseFloat($event)
        const x = parseFloat(this.form.detail[index].isi_kemasan)
        const result = String(c * x)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      if (param === 'harga_kemasan') {
        const param2 = 'harga_item'
        const d = parseFloat($event)
        const x = parseFloat(this.form.detail[index].isi_kemasan)
        const result = String(d / x)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      //
      if (param === 'diskon_item') {
        const param2 = 'diskon_harga'
        const e = parseFloat($event)
        const c = parseFloat(this.form.detail[index].harga_item)
        const result = String((e * c) / 100)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      if (param === 'diskon_harga') {
        const param2 = 'diskon_item'
        const f = parseFloat($event)
        const c = parseFloat(this.form.detail[index].harga_item)
        const result = String((f * 100) / c)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      this.countNilaiTotal()
      this.countDiskon()
      this.countPpn()
      this.countPembulatan()
      this.countAkhir()
    },
    countNilaiTotal () {
      let jumlahItem = null
      let hargaItem = null
      let result = null
      for (let i = 0; i < this.form.detail.length; i++) {
        jumlahItem += parseFloat(this.form.detail[i].jumlah_item)
        hargaItem += parseInt(this.form.detail[i].harga_item)
      }
      result = String(jumlahItem * hargaItem)
      let verifResult = isNaN(result) === true ? '' : result
      this.nilai.nilai_total = verifResult
      this.form.header.nilai_total = verifResult
    },
    countDiskon () {
      let jumlahItem = null
      let diskonHarga = null
      let result = null
      for (let i = 0; i < this.form.detail.length; i++) {
        jumlahItem += parseFloat(this.form.detail[i].jumlah_item)
        diskonHarga += parseInt(this.form.detail[i].diskon_harga)
      }
      result = String(jumlahItem * diskonHarga)
      let verifResult = isNaN(result) === true ? '' : result
      this.nilai.nilai_diskon = verifResult
      this.form.header.nilai_diskon = verifResult
    },
    countPpn () {
      let ppn = this.form.header.ppn === '1' ? 111 : 100
      let nilaiTotal = parseFloat(this.form.header.nilai_total)
      let diskon = parseFloat(this.form.header.nilai_diskon)
      let result = String(ppn * (nilaiTotal - diskon) / 100)
      let verifResult = isNaN(result) === true ? '' : result
      this.nilai.nilai_ppn = verifResult
      this.form.header.nilai_ppn = verifResult
    },
    countPembulatan () {
      let nilaiTotal = parseFloat(this.form.header.nilai_total)
      let diskon = parseFloat(this.form.header.nilai_diskon)
      let ppn = parseFloat(this.form.header.nilai_ppn)
      let result = nilaiTotal - diskon + ppn
      let resultB = parseFloat(result).toFixed(2)
      let a = parseInt(resultB) + 1
      let b = parseFloat(a - resultB).toFixed(2)
      let c = b === '1.00' || b === '1' ? '0' : b
      this.nilai.nilai_pembulatan = c
      this.form.header.nilai_pembulatan = c
    },
    countAkhir () {
      let nilaiTotal = parseFloat(this.form.header.nilai_total)
      let diskon = parseFloat(this.form.header.nilai_diskon)
      let ppn = parseFloat(this.form.header.nilai_ppn)
      let result = String(nilaiTotal - diskon + ppn)
      let verifResult = isNaN(result) === true ? '' : result
      this.nilai.nilai_akhir = verifResult
      this.form.header.nilai_akhir = verifResult
    },
    convertRibuan ($event, param, index) {
      let value = isNaN($event) === true ? '' : $event
      if (param === 'jumlah_item') {
        this.nilai.jumlah_item[index].value = value
        this.form.detail[index].jumlah_item = value
      }
      if (param === 'jumlah_kemasan') {
        this.nilai.jumlah_kemasan[index].value = value
        this.form.detail[index].jumlah_kemasan = value
      }
      if (param === 'harga_item') {
        this.nilai.harga_item[index].value = value
        this.form.detail[index].harga_item = value
      }
      if (param === 'harga_kemasan') {
        this.nilai.harga_kemasan[index].value = value
        this.form.detail[index].harga_kemasan = value
      }
      if (param === 'diskon_item') {
        this.nilai.diskon_item[index].value = value
        this.form.detail[index].diskon_item = value
      }
      if (param === 'diskon_harga') {
        this.nilai.diskon_harga[index].value = value
        this.form.detail[index].diskon_harga = value
      }
    },
    formatRibuan (value) {
      if (value !== '') {
        let amount = parseInt(value)
        return amount.toLocaleString()
      } else {
        return '0'
      }
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async getSelectize () {
      try {
        for (let i = 0; i < this.paramSelectize.length; i++) {
          const response = await Service.katalog.selectizePenerimaan(this.paramSelectize[i].param0)
          this.options[`${this.paramSelectize[i].param0.replace(/-/g, '_')}`] = response.data.data
        }
      } catch (error) {
        this.message.code = error.status
        this.message.desc = error.message
        this.message.variant = 'danger'
      }
    },
    async getSelectizeTable (data) {
      try {
        const response = await Service.katalog.selectizePenerimaanTable(data)
        this.options.barang_rt = response.data.data
      } catch (error) {
        this.message.code = error.status
        this.message.desc = error.message
        this.message.variant = 'danger'
      }
    },
    async list (ctx) {
      try {
        this.table.isBusy = true
        const param3 = `{"xparam0":"${this.filtering.kode}","xparam1":"${this.filtering.nama}","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const param4 = `{"xparam0":"","xparam1":""}`
        const response = await Service.katalog.listPenerimaan(ctx.currentPage - 1, ctx.perPage, param3, param4)
        if (response.status === 200) {
          this.table.isBusy = false
          this.table.totalRows = response.data.pagination.total
          this.table.perPage = response.data.pagination.perPage
          this.table.currentPage = response.data.pagination.current
          return response.data.data
        } else {
          this.table.isBusy = false
          return []
        }
      } catch (error) {
        this.table.isBusy = false
        this.message.code = error.status
        this.message.desc = error.message
        this.message.variant = 'danger'
        return []
      }
    },
    async show (id, param) {
      try {
        this.isLoading = true
        this.clearMessage()
        this.clearForm()
        this.deleteRow(0)
        this.form.object = param
        this.id_show = id.toString()
        this.modal_status = 'edit'
        this.modal_title = 'Edit Penerimaan Berdasarkan SP/SPK/KONTRAK'
        const param1 = ``
        const param2 = `{"object":"TERIMA_show_header","xparam0":"${id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const param3 = `{"object":"TERIMA_show_details","xparam0":"${id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Service.middleware.find(param1, param2)
        // console.log(response)
        const responses = await Service.middleware.find(param1, param3)
        // console.log(responses)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.form.header = response.data.data[0]
          this.form.details = responses.data.data
          // this.showDetails(id)
          // this.form.header.id_rekanan = response.data.data[0].header.id_rekanan
          // this.form.header.id_jenisanggaran = response.data.data[0].header.id_jenisanggaran
          // this.form.header.keterangan = response.data.data[0].header.keterangan
          // this.form.header.tgl_doc = response.data.data[0].header.tgl_doc
          // this.form.header.tgl_faktur = response.data.data[0].header.tgl_faktur
          // this.form.header.tipe_doc = response.data.data[0].header.tipe_doc
          // this.form.header.no_faktur = response.data.data[0].header.no_faktur
          // this.form.header.no_suratjalan = response.data.data[0].header.no_suratjalan
          // this.form.header.ppn = response.data.data[0].header.ppn
          // //
          // this.form.header.nilai_akhir = response.data.data[0].header.nilai_akhir
          // this.form.header.nilai_diskon = response.data.data[0].header.nilai_diskon
          // this.form.header.nilai_pembulatan = response.data.data[0].header.nilai_pembulatan
          // this.form.header.nilai_ppn = response.data.data[0].header.nilai_ppn
          // this.form.header.nilai_total = response.data.data[0].header.nilai_total
          // this.nilai.nilai_pembulatan = response.data.data[0].header.nilai_pembulatan
          // this.nilai.nilai_akhir = response.data.data[0].header.nilai_akhir
          // this.nilai.nilai_diskon = response.data.data[0].header.nilai_diskon
          // this.nilai.nilai_pembulatan = response.data.data[0].header.nilai_pembulatan
          // this.nilai.nilai_ppn = response.data.data[0].header.nilai_ppn
          // this.nilai.nilai_total = response.data.data[0].header.nilai_total
          // this.nilai.nilai_pembulatan = response.data.data[0].header.nilai_pembulatan
          // // verifikasi
          // this.verifikasi.ver_terima = response.data.data[0].header.ver_terima
          // this.verifikasi.ver_gudang = response.data.data[0].header.ver_gudang
          // this.verifikasi.ver_akuntansi = response.data.data[0].header.ver_akuntansi
          // //
          // this.verifikasi_name.ver_terima = response.data.data[0].header.desc_ver_usrterima !== '' ? response.data.data[0].header.desc_ver_usrterima : xray.currentUser().name
          // this.verifikasi_name.ver_gudang = response.data.data[0].header.desc_ver_usrgudang !== '' ? response.data.data[0].header.desc_ver_usrgudang : xray.currentUser().name
          // this.verifikasi_name.ver_akuntansi = response.data.data[0].header.desc_ver_usrakuntansi !== '' ? response.data.data[0].header.desc_ver_usrakuntansi : xray.currentUser().name
          // //
          // this.form.header.ver_terima = response.data.data[0].header.ver_terima
          // this.form.header.ver_gudang = response.data.data[0].header.ver_gudang
          // this.form.header.ver_akuntansi = response.data.data[0].header.ver_akuntansi
          // //
          // this.form.header.ver_tglterima = response.data.data[0].header.ver_tglterima !== '' ? this.convertDate(response.data.data[0].header.ver_tglterima, 'func') : ''
          // this.form.header.ver_tglgudang = response.data.data[0].header.ver_tglgudang !== '' ? this.convertDate(response.data.data[0].header.ver_tglgudang, 'func') : ''
          // this.form.header.ver_tglakuntansi = response.data.data[0].header.ver_tglakuntansi !== '' ? this.convertDate(response.data.data[0].header.ver_tglakuntansi, 'func') : ''
          // //
          // this.form.header.ver_usrterima = response.data.data[0].header.ver_usrterima
          // this.form.header.ver_usrgudang = response.data.data[0].header.ver_usrgudang
          // this.form.header.ver_usrakuntansi = response.data.data[0].header.ver_usrakuntansi
          // // selected
          // this.selected.rekanan_rt = { id: response.data.data[0].header.id_rekanan, deskripsi: response.data.data[0].header.nama_rekanan }
          // this.selected.jenis_anggaran_rt = { id: response.data.data[0].header.id_jenisanggaran, deskripsi: response.data.data[0].header.desc_id_jenisanggaran }
          // this.selected.tipe_doc = { id: response.data.data[0].header.tipe_doc, deskripsi: response.data.data[0].header.desc_tipe_doc }
          // // date
          // this.tanggal.tgl_doc = response.data.data[0].header.tgl_doc
          // this.tanggal.tgl_faktur = response.data.data[0].header.tgl_faktur
          // // func
          // this.getSelectizeTable(response.data.data[0].header.id_jenisanggaran)
          // detail
          // response.data.data.details.map((value, index) => {
          //   this.form.detail.push(
          //     {
          //       id: value.id,
          //       id_katalog: value.id_katalog,
          //       id_kemasan: value.id_kemasan,
          //       isi_kemasan: value.isi_kemasan,
          //       jumlah_item: value.jumlah_item,
          //       jumlah_kemasan: value.jumlah_kemasan,
          //       jumlah_itembonus: value.jumlah_itembonus,
          //       harga_item: value.harga_item,
          //       harga_kemasan: value.harga_kemasan,
          //       diskon_item: value.diskon_item,
          //       diskon_harga: value.diskon_harga,
          //       keterangan: value.keterangan,
          //       userid_updt: value.userid_updt
          //       //
          //     }
          //   )
          //   this.nilai.isi_kemasan.push({ value: value.isi_kemasan })
          //   this.nilai.jumlah_item.push({ value: value.jumlah_item })
          //   this.nilai.jumlah_kemasan.push({ value: value.jumlah_kemasan })
          //   this.nilai.jumlah_itembonus.push({ value: value.jumlah_itembonus })
          //   this.nilai.harga_item.push({ value: value.harga_item })
          //   this.nilai.harga_kemasan.push({ value: value.harga_kemasan })
          //   this.nilai.diskon_item.push({ value: value.diskon_item })
          //   this.nilai.diskon_harga.push({ value: value.diskon_harga })
          //   //
          //   this.selected.id_kemasan.push({ detail: [{ id: value.id_kemasan, deskripsi: value.desc_id_kemasan }] })
          //   this.selected.id_katalog.push({ detail: [{ id: value.id_katalog, deskripsi: value.deskripsi }] })
          //   //
          // })
        } else {
          this.isLoading = false
          this.message.code = 'modal'
          this.message.desc = response.data.message
          this.message.variant = 'danger'
        }
        // this.$refs['modal-create-update-penerimaan'].show()
      } catch (error) {
        this.isLoading = false
        this.message.code = 'modal'
        this.message.desc = error.message
        this.message.variant = 'danger'
        // this.$refs['modal-create-update-penerimaan'].show()
      }
    },
    async showDetails (id) {
      try {
        const param1 = ``
        const param2 = `{"object":"TERIMA_show_details","xparam0":"${id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const responses = await Service.middleware.find(param1, param2)
        this.form.header.details = responses.data.data
      } catch (error) {
        this.isLoading = false
        this.message.code = 'modal'
        this.message.desc = error.message
        this.message.variant = 'danger'
      }
    },
    async createOrUpdate (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        this.isLoading = true
        const response = this.modal_status === 'tambah' ? await Service.katalog.createPenerimaan(this.form) : await Service.katalog.updatePenerimaan(this.id_show, this.form)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.message.code = 'list'
          this.message.desc = response.data.message
          this.message.variant = 'success'
          this.$refs['modal-create-update-penerimaan'].hide()
        } else {
          this.isLoading = false
          this.message.code = 'modal'
          this.message.desc = response.data.message
          this.message.variant = 'danger'
        }
      } catch (error) {
        this.isLoading = false
        this.message.code = 'modal'
        this.message.desc = error.message
        this.message.variant = 'danger'
      } finally {
        this.$refs.table.refresh()
      }
    },
    async softDelete (id) {
      try {
        const data = {
          object: 'penerimaan-rumah-tangga',
          header: {
            del_status: '1',
            del_user: xray.currentUser().id.toString()
          },
          detail: [
            {}
          ]
        }
        const response = await Service.katalog.softDeletePenerimaan(id, data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.message.code = 'list'
          this.message.desc = response.data.message
          this.message.variant = 'success'
          this.$refs['modal-create-update-penerimaan'].hide()
        } else {
          this.isLoading = false
          this.message.code = 'list'
          this.message.desc = response.data.message
          this.message.variant = 'danger'
        }
      } catch (error) {
        this.isLoading = false
        this.message.code = 'list'
        this.message.desc = error.message
        this.message.variant = 'danger'
      } finally {
        this.$refs.table.refresh()
      }
    },
    async cetak (id) {
      try {
        this.clearForm()
        const response = await Service.katalog.cetakPenerimaan(id)
        if (response.data.statcode === 0) {
          this.formCetak = response.data.data
        } else {
          this.message.code = 'modal'
          this.message.desc = response.data.message
          this.message.variant = 'danger'
        }
      } catch (error) {
        this.message.code = 'modal'
        this.message.desc = error.message
        this.message.variant = 'danger'
      } finally {
        this.$refs['modal-cetak-penerimaan'].show()
      }
    },
    print () {
      const prtHtml = document.getElementById('printCoba')
      const Winprint = window.open('', '', 'height=1000,width=1000')
      Winprint.document.write(`<!DOCTYPE html>
      <html lang="en">
      <head>
      </head>
      <body>
      ${prtHtml.innerHTML}
      </body>
      </html>`)
      Winprint.document.close()
      Winprint.focus()
      Winprint.print()
      Winprint.close()
    },
    refreshTable () {
      this.$refs.table.refresh()
    },
    onFiltered (filteredItems) {
      this.table.currentPage = 1
      this.table.totalRows = filteredItems.length
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.del_status === 1) return 'text-secondary'
    },
    openModal (param) {
      // this.clearMessage()
      // this.clearForm()
      // this.form.object = 'penerimaan-rumah-tangga'
      // this.modal_title = 'Tambah Penerimaan Berdasarkan SP/SPK/KONTRAK'
      // this.modal_status = param
      // this.$refs['modal-create-update-penerimaan'].show()
      this.$refs['modal-nilai-penerimaan'].show()
    },
    valueSelectize ($event, param, index) {
      if (param === 'id_rekanan') this.form.header.id_rekanan = $event.id.toString()
      if (param === 'tipe_doc') this.form.header.tipe_doc = $event.id.toString()
      if (param === 'id_kemasan') this.form.detail[index].id_kemasan = $event.id.toString()
      if (param === 'id_katalog') {
        this.form.detail[index].id_katalog = $event.id_katalog.toString()
        this.form.detail[index].id_kemasan = $event.id_kemasan.toString()
        this.form.detail[index].isi_kemasan = $event.isi_kemasan.toString()
        this.clearCountIdKatalog()
      }
      if (param === 'id_jenisanggaran') {
        this.form.header.id_jenisanggaran = $event.id.toString()
        this.getSelectizeTable(this.form.header.id_jenisanggaran)
        this.clearCountJenisAnggaran()
      }
    },
    valueTgl ($event, param) {
      if (param === 'tgl_doc') this.form.header.tgl_doc = this.convertDate($event, 'func')
      if (param === 'tgl_faktur') this.form.header.tgl_faktur = this.convertDate($event, 'func')
    },
    verifikasiType ($event, param) {
      // tim terima
      if ($event === '1' && param === 'ver_terima') {
        this.form.header.ver_terima = $event
        this.form.header.ver_tglterima = this.convertDate(new Date(), 'funct')
        this.form.header.ver_usrterima = xray.currentUser().id.toString()
      }
      if ($event === '0' && param === 'ver_terima') {
        this.form.header.ver_terima = $event
        this.form.header.ver_tglterima = ''
        this.form.header.ver_usrterima = ''
      }
      // gudang
      if ($event === '1' && param === 'ver_gudang') {
        this.form.header.ver_gudang = $event
        this.form.header.ver_tglgudang = this.convertDate(new Date(), 'funct')
        this.form.header.ver_usrgudang = xray.currentUser().id.toString()
      }
      if ($event === '0' && param === 'ver_gudang') {
        this.form.header.ver_gudang = $event
        this.form.header.ver_tglgudang = ''
        this.form.header.ver_usrgudang = ''
      }
      // akutansi
      if ($event === '1' && param === 'ver_akuntansi') {
        this.form.header.ver_akuntansi = $event
        this.form.header.ver_tglakuntansi = this.convertDate(new Date(), 'funct')
        this.form.header.ver_usrakuntansi = xray.currentUser().id.toString()
      }
      if ($event === '0' && param === 'ver_akuntansi') {
        this.form.header.ver_akuntansi = $event
        this.form.header.ver_tglakuntansi = ''
        this.form.header.ver_usrakuntansi = ''
      }
    },
    addRow () {
      this.form.detail.push(
        {
          id: 0,
          id_katalog: '',
          id_kemasan: '',
          isi_kemasan: '',
          jumlah_item: '',
          jumlah_kemasan: '',
          jumlah_itembonus: '0',
          harga_item: '',
          harga_kemasan: '',
          diskon_item: '',
          diskon_harga: '',
          keterangan: '',
          userid_updt: xray.currentUser().id.toString()
        }
      )
      this.selected.id_kemasan.push({ detail: [] })
      this.selected.id_katalog.push({ detail: [] })
      //
      this.nilai.isi_kemasan.push({ value: '' })
      this.nilai.jumlah_item.push({ value: '' })
      this.nilai.jumlah_kemasan.push({ value: '' })
      this.nilai.jumlah_itembonus.push({ value: '0' })
      this.nilai.harga_item.push({ value: '' })
      this.nilai.harga_kemasan.push({ value: '' })
      this.nilai.diskon_item.push({ value: '' })
      this.nilai.diskon_harga.push({ value: '' })
      //
      this.countNilaiTotal()
      this.countDiskon()
      this.countPpn()
      this.countPembulatan()
      this.countAkhir()
    },
    deleteRow (index) {
      this.form.detail.splice(index, 1)
      //
      this.selected.id_kemasan.splice(index, 1)
      this.selected.id_katalog.splice(index, 1)
      //
      this.nilai.isi_kemasan.splice(index, 1)
      this.nilai.jumlah_item.splice(index, 1)
      this.nilai.jumlah_kemasan.splice(index, 1)
      this.nilai.jumlah_itembonus.splice(index, 1)
      this.nilai.harga_item.splice(index, 1)
      this.nilai.harga_kemasan.splice(index, 1)
      this.nilai.diskon_item.splice(index, 1)
      this.nilai.diskon_harga.splice(index, 1)
      //
      this.countNilaiTotal()
      this.countDiskon()
      this.countPpn()
      this.countPembulatan()
      this.countAkhir()
    },
    clearMessage () {
      this.message.code = ''
      this.message.desc = ''
      this.message.variant = ''
    },
    clearForm () {
      this.form = {
        object: 'penerimaan-rumah-tangga',
        header: {
          id_rekanan: '',
          id_jenisanggaran: '',
          keterangan: '',
          tgl_doc: '',
          tgl_faktur: '',
          tipe_doc: '',
          no_faktur: '',
          no_suratjalan: '',
          ppn: '0',
          nilai_total: '',
          nilai_diskon: '',
          nilai_ppn: '',
          nilai_pembulatan: '',
          nilai_akhir: '',
          ver_terima: '0',
          ver_tglterima: '',
          ver_usrterima: '',
          // update gudang
          ver_gudang: '0',
          ver_tglgudang: '',
          ver_usrgudang: '',
          // update akutansi
          ver_akuntansi: '0',
          ver_tglakuntansi: '',
          ver_usrakuntansi: '',
          //
          userid_in: xray.currentUser().id.toString(),
          userid_updt: xray.currentUser().id.toString()
        },
        detail: [
          {
            id: 0,
            id_katalog: '',
            id_kemasan: '',
            isi_kemasan: '',
            jumlah_item: '',
            jumlah_kemasan: '',
            jumlah_itembonus: '0',
            harga_item: '',
            harga_kemasan: '',
            diskon_item: '',
            diskon_harga: '',
            keterangan: '',
            userid_updt: xray.currentUser().id.toString()
          }
        ]
      }
      this.selected = {
        rekanan_rt: [],
        kemasan_rt: [],
        kelompok_rt: [],
        jenis_anggaran_rt: [],
        tipe_doc: [],
        barang_rt: [],
        id_katalog: [{ detail: [] }],
        id_kemasan: [{ detail: [] }]
      }
      this.options.barang_rt = []
      this.tanggal = {
        tgl_doc: '',
        tgl_faktur: ''
      }
      this.nilai = {
        nilai_total: '',
        nilai_diskon: '',
        nilai_ppn: '',
        nilai_pembulatan: '0',
        nilai_akhir: '',
        isi_kemasan: [{ value: '' }],
        jumlah_item: [{ value: '' }],
        jumlah_kemasan: [{ value: '' }],
        jumlah_itembonus: [{ value: '0' }],
        harga_item: [{ value: '' }],
        harga_kemasan: [{ value: '' }],
        diskon_item: [{ value: '' }],
        diskon_harga: [{ value: '' }]
      }
      this.verifikasi = {
        ver_terima: '',
        ver_gudang: '',
        ver_akuntansi: ''
      }
      this.verifikasi_name = {
        ver_terima: xray.currentUser().name,
        ver_gudang: xray.currentUser().name,
        ver_akuntansi: xray.currentUser().name
      }
      this.formCetak = {
        header: {
          no_terima: '',
          no_btb: '',
          no_faktur: '',
          no_suratjalan: '',
          no_sp_spk: '',
          no_spb: '',
          pabrik: '',
          jumlah: '',
          ppn: '0',
          nilai_pembulatan: '0',
          nilai_akhir: '',
          ver_terima: '',
          ver_tglterima: '',
          desc_ver_usrterima: '',
          ver_gudang: '',
          ver_tglgudang: '',
          desc_ver_usrgudang: '',
          ver_akuntansi: '',
          ver_tglakuntansi: '',
          desc_ver_usrakuntansi: ''
        },
        details: [
          {
            nama_barang: '',
            kuantitas: '',
            satuan: '',
            harga: '',
            diskon: '',
            jumlah: ''
          }
        ]
      }
    },
    clearCountJenisAnggaran () {
      this.form.header.ppn = '0'
      this.form.header.nilai_total = ''
      this.form.header.nilai_diskon = ''
      this.form.header.nilai_ppn = ''
      this.form.header.nilai_pembulatan = ''
      this.form.header.nilai_akhir = ''
      //
      this.nilai.nilai_total = ''
      this.nilai.nilai_diskon = ''
      this.nilai.nilai_ppn = ''
      this.nilai.nilai_pembulatan = '0'
      this.nilai.nilai_akhir = ''
      //
      this.form.detail.forEach((element, index) => {
        this.form.detail[index].id_katalog = ''
        this.form.detail[index].id_kemasan = ''
        this.form.detail[index].isi_kemasan = ''
        this.form.detail[index].jumlah_item = ''
        this.form.detail[index].jumlah_kemasan = ''
        this.form.detail[index].jumlah_itembonus = '0'
        this.form.detail[index].harga_item = ''
        this.form.detail[index].harga_kemasan = ''
        this.form.detail[index].diskon_item = ''
        this.form.detail[index].diskon_harga = ''
        this.form.detail[index].keterangan = ''
        //
        this.nilai.isi_kemasan[index].value = ''
        this.nilai.jumlah_item[index].value = ''
        this.nilai.jumlah_kemasan[index].value = ''
        this.nilai.jumlah_itembonus[index].value = '0'
        this.nilai.harga_item[index].value = ''
        this.nilai.harga_kemasan[index].value = ''
        this.nilai.diskon_item[index].value = ''
        this.nilai.diskon_harga[index].value = ''
        //
        this.selected.id_katalog[index] = { detail: [] }
      })
    },
    clearCountIdKatalog () {
      this.form.header.ppn = '0'
      this.form.header.nilai_total = ''
      this.form.header.nilai_diskon = ''
      this.form.header.nilai_ppn = ''
      this.form.header.nilai_pembulatan = ''
      this.form.header.nilai_akhir = ''
      //
      this.nilai.nilai_total = ''
      this.nilai.nilai_diskon = ''
      this.nilai.nilai_ppn = ''
      this.nilai.nilai_pembulatan = '0'
      this.nilai.nilai_akhir = ''
      //
      this.form.detail.forEach((element, index) => {
        // this.form.detail[index].id_katalog = ''
        // this.form.detail[index].id_kemasan = ''
        // this.form.detail[index].isi_kemasan = ''
        this.form.detail[index].jumlah_item = ''
        this.form.detail[index].jumlah_kemasan = ''
        this.form.detail[index].jumlah_itembonus = '0'
        this.form.detail[index].harga_item = ''
        this.form.detail[index].harga_kemasan = ''
        this.form.detail[index].diskon_item = ''
        this.form.detail[index].diskon_harga = ''
        this.form.detail[index].keterangan = ''
        //
        this.nilai.isi_kemasan[index].value = ''
        this.nilai.jumlah_item[index].value = ''
        this.nilai.jumlah_kemasan[index].value = ''
        this.nilai.jumlah_itembonus[index].value = '0'
        this.nilai.harga_item[index].value = ''
        this.nilai.harga_kemasan[index].value = ''
        this.nilai.diskon_item[index].value = ''
        this.nilai.diskon_harga[index].value = ''
        //
        // this.selected.id_katalog[index] = { detail: [] }
      })
    }
  }
}
</script>

<style>

#penerimaan-rumah-tangga-detail .custom-font {
  font-size: 0.8rem;
}

#penerimaan-rumah-tangga-detail .bg-kuning-input {
  background: rgb(255, 255, 187)
}

.bg-fatmahost-disabled {
  /* background: #065C5C!important; */
  background: #089bab!important;
}

#penerimaan-rumah-tangga-detail .custom-form {
  font-size: 0.8rem;
  height: 33px !important;
  border-radius: 3px;
}

#penerimaan-rumah-tangga-detail .custom-form {
  font-size: 0.8rem;
  height: 33px !important;
  border-radius: 3px;
}

#penerimaan-rumah-tangga-detail .w-custom-70 {
  width: 70%;
}

#penerimaan-rumah-tangga-detail .w-custom-30 {
  width: 30%;
}

#penerimaan-rumah-tangga-detail .bg-secondary-custom {
  background-color: #455f451b;
}

#penerimaan-rumah-tangga-detail .bg-primary-custom {
  background-color: #E9ECEF;
  border-radius: 3px;
}

#penerimaan-rumah-tangga-detail .multiselect,
#modal-stokopname-edit .multiselect {
  min-height: 33px;
}

#penerimaan-rumah-tangga-detail .multiselect__single {
  font-size: 0.8rem !important;
  font-weight: normal !important;
  line-height: 26px;
}

#penerimaan-rumah-tangga-detail .multiselect__placeholder {
  font-size: 0.8rem !important;
  font-weight: normal;
}

#penerimaan-rumah-tangga-detail .multiselect__option {
  font-size: 0.8rem !important;
}

#penerimaan-rumah-tangga-detail .multiselect__tags {
  padding: 1px 40px 0 8px !important;
  height: 33px !important;
  min-height: 33px !important;
  font-size: 0.9em !important;
}

#penerimaan-rumah-tangga-detail .multiselect--disabled .multiselect__tags {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: #E9ECEF;
}

#penerimaan-rumah-tangga-detail .multiselect--disabled {
  opacity: 1;
}

#penerimaan-rumah-tangga-detail .multiselect--disabled .multiselect__tags .multiselect__single {
  background: transparent;
}

#penerimaan-rumah-tangga-detail .multiselect__select {
  height: 30px;
}

#penerimaan-rumah-tangga-detail .custom-flatpicker {
  height: 33px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 0.8rem;
  color: black;
}

#penerimaan-rumah-tangga-detail .table td {
  font-size: 0.8rem;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

#penerimaan-rumah-tangga-detail .table th {
  font-size: 0.8rem;
  font-weight: 400;
}

#penerimaan-rumah-tangga-detail .table thead th {
  vertical-align: middle;
}

#penerimaan-rumah-tangga-detail .page-link {
  background: transparent !important;
  border: none !important;
  color: gray !important;
  margin-right: 15px;
}

#penerimaan-rumah-tangga-detail .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}

#penerimaan-rumah-tangga-detail .bg-pallet {
  background-color: #089bab1f;
}

#penerimaan-rumah-tangga-detail .my-cursor,
#penerimaan-rumah-tangga-detail .my-cursor {
  cursor: pointer;
}

#penerimaan-rumah-tangga-detail  .form-control {
  line-height: 22px;
  font-size: 0.8rem;
  color: black;
  border-radius: 3px;
}

#penerimaan-rumah-tangga-detail .form-control:disabled, .form-control[readonly] {
  /* background: #065C5C!important; */
  background: #089bab!important;
}

#penerimaan-rumah-tangga-detail .custom-spacing {
  letter-spacing: .1rem;
}

#penerimaan-rumah-tangga-detail .w-35-penerimaan {
  width: 35%;
}
#penerimaan-rumah-tangga-detail .w-15-penerimaan {
  width: 15%;
}
#penerimaan-rumah-tangga-detail .w-50-penerimaan {
  width: 50%;
}
#penerimaan-rumah-tangga-detail .w-30-penerimaan {
  width: 30%;
}
#penerimaan-rumah-tangga-detail .w-40-penerimaan {
  width: 40%;
}
#penerimaan-rumah-tangga-detail .w-20-penerimaan {
  width: 20%;
}
#penerimaan-rumah-tangga-detail .w-75-penerimaan {
  width: 75%;
}
#penerimaan-rumah-tangga-detail .w-25-penerimaan {
  width: 25%;
}
#penerimaan-rumah-tangga-detail .w-5-penerimaan {
  width: 5%;
}
#penerimaan-rumah-tangga-detail .w-10-penerimaan {
  width: 10%;
}
#penerimaan-rumah-tangga-detail .w-45-penerimaan {
  width: 45%;
}
#penerimaan-rumah-tangga-detail .w-95-penerimaan {
  width: 95%;
}
#penerimaan-rumah-tangga-detail .w-90-penerimaan {
  width: 90%;
}
#penerimaan-rumah-tangga-detail .w-85-penerimaan {
  width: 85%;
}
</style>
